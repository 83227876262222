import ReviewStar from "./ReviewStar";

type RatingProps = {
  rating: number; // rating between 0 and 5
  size: "small" | "medium" | "large" | "x-large";
};

const Ratings: React.FC<RatingProps> = ({ rating, size = "medium" }) => {
  const stars = Array.from({ length: 5 }, (_, index) => {
    const fillPercentage = Math.min(100, Math.max(0, (rating - index) * 100));
    return <ReviewStar size={size} key={index} fillPercentage={fillPercentage} />;
  });

  return <div className="flex space-x-1">{stars}</div>;
};

export default Ratings;
