import InputWithOverlappingLabel from "../TailwindComponents/InputWithOverlappingLabel";

interface EINDocumentFormProps {
  formData: {
    registered_business_name: string | null;
    tin_number: string | null;
  };
  setFormData: React.Dispatch<React.SetStateAction<any>>;
  formErrors: string[];
}

const EINDocumentForm: React.FC<EINDocumentFormProps> = ({ formData, setFormData, formErrors }) => {
  return (
    <form>
      <div className="grid grid-cols-4 gap-3">
        <InputWithOverlappingLabel
          required
          fieldName="registered_business_name"
          label="Registered Business Name"
          id="registered_business_name"
          name="registered_business_name"
          onChangeHandler={(e) => setFormData({ ...formData, registered_business_name: e.target.value })}
          className="col-span-2"
          value={formData.registered_business_name || ""}
        />
        <InputWithOverlappingLabel
          fieldName="tin_number"
          label="Employer Identification Number (EIN)"
          id="tin_number"
          name="tin_number"
          className="col-span-2"
          onChangeHandler={(e) => setFormData({ ...formData, tin_number: e.target.value })}
          value={formData.tin_number || ""}
        />
      </div>
      {formErrors.length > 0 && (
        <div className="text-red-600 text-sm mt-4">
          <span className="font-semibold">Please correct the following errors:</span>
          <ul className="list-disc list-outside ml-6">
            {formErrors.map((error, index) => (
              <li className="list-item" key={index}>
                {error}
              </li>
            ))}
          </ul>
        </div>
      )}
    </form>
  );
};

export default EINDocumentForm;
