import { twJoin } from "tailwind-merge";

type StarProps = {
  fillPercentage: number; // value between 0 and 100
  size: "small" | "medium" | "large" | "x-large";
};

const sizeClasses = {
  small: "w-4 h-4",
  medium: "w-6 h-6",
  large: "w-8 h-8",
  "x-large": "w-10 h-10",
};

const ReviewStar: React.FC<StarProps> = ({ fillPercentage, size }) => (
  <div className={twJoin("relative text-yellow-400", sizeClasses[size])}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      className="absolute inset-0"
    >
      <path d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z" />
    </svg>
    <div
      className="absolute inset-0 overflow-hidden"
      style={{
        width: `${fillPercentage}%`,
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={sizeClasses[size]}>
        <path d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z" />
      </svg>
    </div>
  </div>
);

export default ReviewStar;
