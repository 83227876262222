import { ImageSearchRounded, VisibilityOutlined } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

type WebsiteViewProps = {
  websiteUrl: string;
  websiteLabel: string;
  screenshotUrl?: string | null;
  visualSummary?: string | null;
};

const WebsiteCard = ({ websiteUrl, websiteLabel, screenshotUrl, visualSummary }: WebsiteViewProps) => {
  if (
    ((!websiteUrl || !websiteUrl.length) && (!websiteLabel || !websiteLabel.length)) ||
    websiteUrl?.startsWith("https://www.google.com/search")
  ) {
    return null;
  }

  return (
    <div className="min-w-full md:max-w-[600px] flex justify-center text-xs">
      <div className="max-w-full grow flex flex-col border border-slate-200 rounded-lg p-[0.125rem] gap-[0.125rem] relative">
        {websiteUrl.startsWith("https://www.google.com/search") ? null : websiteUrl ? (
          <a href={websiteUrl} target="_blank" rel="noreferrer">
            {screenshotUrl ? (
              <img
                className="h-[13rem] md:h-[18.4rem] w-full object-top object-cover overflow-hidden rounded-md"
                src={screenshotUrl}
              />
            ) : (
              <NoImageFound />
            )}
          </a>
        ) : screenshotUrl ? (
          <img
            className="h-[13rem] md:h-[18.4rem] w-full object-top object-cover overflow-hidden rounded-md"
            src={screenshotUrl}
            onError={({ currentTarget }) => (currentTarget.style.display = "none")}
          />
        ) : (
          <div className="w-full min-w-full grow">
            <NoImageFound />
          </div>
        )}
        {websiteLabel ? (
          <div className="bg-slate-100 text-center whitespace-nowrap block text-ellipsis text-slate-700 py-2 px-5 rounded-b-md line-clamp-1 overflow-hidden">
            {websiteLabel}
          </div>
        ) : null}
        {visualSummary && (
          <Tooltip
            title={
              <div className="p-2 max-w-xs">
                <h3 className="font-bold mb-2">Visual Summary</h3>
                <p>{visualSummary}</p>
              </div>
            }
            arrow
            placement="top"
            className="ignore-in-pdf"
          >
            <div className="ignore-in-pdf absolute top-2 right-2 bg-white rounded-full p-1 shadow-md cursor-pointer transition-all hover:bg-gray-100">
              <VisibilityOutlined className="text-brand-purple" />
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

const NoImageFound = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-y-2 p-12 text-slate-500 bg-slate-200 rounded-t-md">
      <ImageSearchRounded sx={{ fontSize: "2rem" }} />
      <span className="font-semibold">No website image found...</span>
    </div>
  );
};

export default WebsiteCard;
