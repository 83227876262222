import PinDropIcon from "@mui/icons-material/PinDropRounded";

export type AddressValueProps = {
  street1: string | null | undefined;
  street2?: string | null | undefined;
  city?: string | null | undefined;
  state?: string | null | undefined;
  countryCode?: string | null | undefined;
  postalCode?: string | null | undefined;
};

const AddressValue = (props: AddressValueProps) => {
  const { street1, street2, city, state, countryCode, postalCode } = props;
  if (
    (!street1 && !street2 && !city && !state && !countryCode && !postalCode) ||
    Object.entries(props).every(
      ([k, v]) => k === "type" || v === null || v === "unverified" || v === "Unknown" || v === "",
    )
  )
    return null;

  const addressLine1 = `${street1 && street1 !== "unverified" && street1 !== "Unknown" ? street1 : ""}${
    street2 && street2 !== "unverified" && street2 !== "Unknown" ? `, ${street2}` : ""
  }`

    .replace(/^,/, "")
    .trim();
  const addressLine2 = `${city && city !== "unverified" && city !== "Unknown" ? city : ""}${
    state && state !== "unverified" && state !== "Unknown" ? `, ${state}` : ""
  }${countryCode && countryCode !== "unverified" && countryCode !== "Unknown" ? ` ${countryCode}` : ""}${
    postalCode && postalCode !== "unverified" && postalCode !== "Unknown" ? `, ${postalCode}` : ""
  }`
    .replace(/^,/, "")
    .trim();
  const gMapsAddress = encodeURIComponent(addressLine1 + " " + addressLine2).replaceAll(" ", "+");

  return (
    <a
      className="flex gap-x-1 text-xs group w-fit"
      href={`https://www.google.com/maps/search/?api=1&query=${gMapsAddress}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <PinDropIcon sx={{ fontSize: "1rem", height: "1rem", width: "1rem" }} className="text-brand-purple" />
      <p className="group-hover:underline text-brand-purple leading-4">
        {addressLine1.length ? (
          <>
            {addressLine1.trim()}
            <br />
          </>
        ) : null}
        {addressLine2.trim()}
      </p>
    </a>
  );
};

export default AddressValue;
