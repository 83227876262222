import numeral from "numeral";
import DocumentValue from "./DocumentValue";
import { tryToParseDate } from "@/utils";
import { twJoin } from "tailwind-merge";
import InlineCollapsibleText from "./InlineCollapsibleText";
import { RequestQuote } from "@mui/icons-material";

type SourceOfFundsCardProps = {
  level: "success" | "failure" | "normal" | undefined;
  amount: string;
  businessName: string;
  documents: {
    document: {
      file_name: string;
      url: string;
    };
    summary: string;
    date: string;
    source_of_funds: {
      display_type: string | null;
    };
  }[];
  checkResult?: any;
  handleDocumentValidationClick?: (checkResult: any, document: any) => void;
};

const SourceOfFundsCard = ({
  level,
  amount,
  businessName,
  documents,
  handleDocumentValidationClick,
  checkResult,
}: SourceOfFundsCardProps) => {
  if (!documents?.length) return null;

  return (
    <div
      className={twJoin(
        "relative border w-[330px] left-3.5 text-xs",
        level === "success" ? "border-green-600" : level === "failure" ? "border-red-600" : "border-slate-200",
      )}
    >
      <div
        className={twJoin(
          "absolute border-l -rotate-45 w-20 h-20 z-10 top-[-40.5px] left-[288px] bg-white",
          level === "success" ? "border-green-600" : level === "failure" ? "border-red-600" : "border-slate-200",
        )}
      ></div>
      <div className="flex flex-col gap-y-4 z-0 p-5">
        <div className="flex items-center gap-1 text-[#CECECE]">
          <RequestQuote sx={{ fontSize: "1rem" }} />
          <h2 className="uppercase">Source of Funds</h2>
        </div>
        <h3 className="uppercase font-semibold">{businessName}</h3>

        <div className="flex gap-x-1">
          <span className="font-semibold">Amount:</span>
          <span>{numeral(amount).format("$0,0.00")}</span>
        </div>

        {documents?.length ? (
          <div>
            <span className="font-semibold">Documents:</span>
            <div className="w-full flex flex-col gap-y-3 mt-1">
              {documents.map((entry) => (
                <div className="flex flex-col gap-y-2">
                  <DocumentValue
                    fileName={entry.document.file_name}
                    url={entry.document.url}
                    maxWidth={250}
                    handleDocumentValidationClick={handleDocumentValidationClick}
                    checkResult={checkResult}
                  />
                  <span>
                    <span className="font-semibold">Document Date:</span> {tryToParseDate(entry.date)}
                  </span>

                  {entry.source_of_funds.display_type && (
                    <span>
                      <span className="font-semibold">Document Type:</span> {entry.source_of_funds.display_type}
                    </span>
                  )}
                  <span>
                    <span className="font-semibold">Document Summary:</span>{" "}
                    <InlineCollapsibleText text={entry.summary} />
                  </span>
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SourceOfFundsCard;
