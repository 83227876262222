import { useContext, useEffect } from "react";
import { Link, NavLink, NavLinkProps, Outlet, useLocation, useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import FactCheckIconOutlined from "@mui/icons-material/FactCheckOutlined";
import InsertChartFilled from "@mui/icons-material/InsertChart";
import InsertChartOutlined from "@mui/icons-material/InsertChartOutlined";
import CodeIcon from "@mui/icons-material/Code";
import CodeIconOutlined from "@mui/icons-material/CodeOutlined";
import Plagiarism from "@mui/icons-material/Plagiarism";
import PlagiarismOutlined from "@mui/icons-material/PlagiarismOutlined";
import ParchaLogo from "./ParchaLogo";
import { useUser } from "@descope/react-sdk";
import { twJoin } from "tailwind-merge";
import UserContext from "@/contexts/UserContext";

const baseLinkClasses = "flex justify-center items-center w-12 h-12 rounded";

const Layout = () => {
  const { user } = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const endpoints = userContext?.endpoints;

  const isUserParcha = user?.userTenants?.some((tenant) => tenant.tenantName.includes("Parcha"));

  const isUserWithoutTenant = user && user.userTenants?.length === 0;
  const isRemediationRoute = location.pathname.includes("/remediation") || location.pathname.includes("/submit-doc");
  const isOnlyPublicEndpoint = endpoints?.every((endpoint) => endpoint.isPublic);

  useEffect(() => {
    if (location.pathname === "/") {
      isUserWithoutTenant ? navigate("/reports") : navigate("/jobs");
    }
  }, [location.pathname]);

  return (
    <div
      className={twJoin("grid h-full overflow-hidden", isRemediationRoute ? "grid-cols-1" : "grid-cols-[80px,_1fr]")}
    >
      {!isRemediationRoute && (
        <div className="hidden md:flex flex-col">
          <div className="bg-brand-purple text-white" id="logo">
            <Link to={isOnlyPublicEndpoint ? "/reports" : "/jobs"} reloadDocument>
              <ParchaLogo />
            </Link>
          </div>
          <div className="w-full h-full py-5 bg-slate-900 text-white text-[1.5rem] flex flex-col justify-between">
            <nav className="flex-grow-1 flex flex-col items-center">
              <ul role="navigation" className="flex flex-col items-center w-12 h-12 gap-4 text-slate-400">
                {isOnlyPublicEndpoint ? (
                  <>
                    <li className="w-full flex justify-center">
                      <StyledNavLink to="/reports">
                        {({ isActive }: { isActive: boolean }) => {
                          return isActive ? (
                            <FactCheckIcon fontSize="inherit" />
                          ) : (
                            <FactCheckIconOutlined fontSize="inherit" />
                          );
                        }}
                      </StyledNavLink>
                    </li>
                  </>
                ) : (
                  <>
                    <li className="w-full flex justify-center">
                      <StyledNavLink to="/jobs" reloadDocument>
                        {({ isActive }: { isActive: boolean }) => {
                          return isActive ? (
                            <FactCheckIcon fontSize="inherit" />
                          ) : (
                            <FactCheckIconOutlined fontSize="inherit" />
                          );
                        }}
                      </StyledNavLink>
                    </li>
                    {isUserParcha && (
                      <li className="w-full flex justify-center">
                        <StyledNavLink to="/document-requests" reloadDocument>
                          {({ isActive }: { isActive: boolean }) => {
                            return isActive ? (
                              <Plagiarism fontSize="inherit" />
                            ) : (
                              <PlagiarismOutlined fontSize="inherit" />
                            );
                          }}
                        </StyledNavLink>
                      </li>
                    )}
                    <li className="w-full flex justify-center">
                      <StyledNavLink to="/checks-overview">
                        {({ isActive }: { isActive: boolean }) => {
                          return isActive ? (
                            <InsertChartFilled fontSize="inherit" />
                          ) : (
                            <InsertChartOutlined fontSize="inherit" />
                          );
                        }}
                      </StyledNavLink>
                    </li>
                    <li className="w-full flex justify-center">
                      <StyledNavLink to="/api_keys">
                        {({ isActive }: { isActive: boolean }) => {
                          return isActive ? <CodeIcon fontSize="inherit" /> : <CodeIconOutlined fontSize="inherit" />;
                        }}
                      </StyledNavLink>
                    </li>
                  </>
                )}
              </ul>
            </nav>
            <div className="w-full flex justify-center">
              <Link to="/signout" className="p-3 text-slate-400 hover:bg-slate-800 hover:text-white">
                <LogoutIcon className="text-center" fontSize="inherit" />
              </Link>
            </div>
          </div>
        </div>
      )}
      <main className="overflow-hidden col-span-full md:col-span-1">
        <Outlet />
      </main>
    </div>
  );
};

const StyledNavLink = ({
  to,
  children,
  ...rest
}: {
  to: string;
  children: (props: { isActive: boolean }) => JSX.Element;
} & NavLinkProps) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        isActive
          ? `text-white ${baseLinkClasses}`
          : `text-slate-500 hover:text-white hover:bg-slate-800 ${baseLinkClasses}`
      }
      {...rest}
    >
      {children}
    </NavLink>
  );
};

export default Layout;
