import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

interface AnimatedParagraphProps {
  text: string;
  className?: string;
  disableAnimation?: boolean;
}

const AnimatedParagraph: React.FC<AnimatedParagraphProps> = ({ text, className = "", disableAnimation = false }) => {
  // const [currentText, setCurrentText] = useState(text);

  // useEffect(() => {
  //   if (text !== currentText) {
  //     setCurrentText(text);
  //   }
  // }, [text]);

  return (
    <span className={className}>
      <AnimatePresence mode="wait">
        <motion.span
          initial={{ opacity: 0, filter: "blur(4px)" }}
          animate={{ opacity: 1, filter: "blur(0px)" }}
          exit={{ opacity: 0, filter: "blur(4px)" }}
          transition={{ duration: disableAnimation ? 0 : 1 }}
          className="text-sm"
        >
          {text}
        </motion.span>
      </AnimatePresence>
    </span>
  );
};

export default AnimatedParagraph;
