import { shimmerEffectClassNames } from "@/constants/vars";
import { CancelRounded, CheckCircleRounded, PublicRounded, WarningRounded } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { twJoin } from "tailwind-merge";
import CheckSectionStatusMessage from "./CheckSectionStatusMessage";
import GenericSkeleton from "./GenericSkeleton";
import Alert from "@/components/TailwindComponents/Alert";
import SmartSkeleton from "@/components/TailwindComponents/SmartSkeleton";
import SourceTagCircle from "@/components/TailwindComponents/SourceTagCircle";
import WrappedValue from "@/components/TailwindComponents/WrappedValue";
import { Key, ReactNode } from "react";

const HighRiskCountries = ({
  checkResult,
  sourcesDictionary,
  activeSource,
  setActiveSource,
  handleSourceClick,
}: any) => {
  const Header = (
    <div className="flex items-center gap-x-2">
      <PublicRounded sx={{ fontSize: "1.25rem" }} className="text-slate-700" />
      <span
        className={twJoin(
          "text-xl font-semibold",
          checkResult?.status === "running" || !checkResult?.verified_data ? shimmerEffectClassNames : "",
        )}
      >
        Country Associations
      </span>
      {checkResult?.passed && (
        <Tooltip arrow title={checkResult.answer} className="w-4 h-4">
          <CheckCircleRounded sx={{ fontSize: "1rem" }} className="text-green-600" />
        </Tooltip>
      )}
    </div>
  );

  if (!checkResult?.verified_data) {
    const filteredStatusMessages =
      checkResult?.status_messages?.filter(
        (message: { event: string; content: { status: string } }) =>
          message.event === "status" && !message.content.status?.startsWith("Loading:"),
      ) ?? [];
    const lastStatusMessage =
      filteredStatusMessages.length > 0 ? filteredStatusMessages[filteredStatusMessages.length - 1] : null;

    return (
      <div>
        {Header}
        {lastStatusMessage && <CheckSectionStatusMessage message={lastStatusMessage.content.status} />}
        <GenericSkeleton />
      </div>
    );
  }

  const operatingCountries =
    checkResult?.check_result?.flagged_business_countries ||
    checkResult?.check_result?.business_countries ||
    checkResult?.check_result?.operating_countries_match ||
    [];
  const flaggedOperatingCountries = operatingCountries.filter((country: { flagged: boolean }) => country.flagged);
  const prohibitedOperatingCountries = operatingCountries.filter(
    (country: { prohibited: boolean }) => country.prohibited,
  );

  const customerCountries =
    checkResult?.check_result?.flagged_customer_countries ||
    checkResult?.check_result?.customer_countries ||
    checkResult?.check_result?.customer_countries_match ||
    [];
  const flaggedCustomerCountries = customerCountries.filter((country: { flagged: boolean }) => country.flagged);
  const prohibitedCustomerCountries = customerCountries.filter(
    (country: { prohibited: boolean }) => country.prohibited,
  );

  // TODO: Consider backwards compatibility for old format
  // const prohibitedOperatingCountries = checkResult?.check_result?.prohibited_business_countries || [];
  // const prohibitedCustomerCountries = checkResult?.check_result?.prohibited_customer_countries || [];
  const operatingCountriesSourceIds = checkResult?.check_result?.flagged_business_countries_source_ids || [];
  const customerCountriesSourceIds = checkResult?.check_result?.flagged_customer_countries_source_ids || [];

  const prohibitedOperatingCountriesSourceIds =
    checkResult?.check_result?.prohibited_business_countries_source_ids || [];
  const prohibitedCustomerCountriesSourceIds =
    checkResult?.check_result?.prohibited_customer_countries_source_ids || [];

  const evidenceList = [
    ...(checkResult.verified_data?.business_countries || []).map((item: { country: string; evidence: any }) => ({
      found: item.country,
      evidence: item.evidence,
    })),
    ...(checkResult.verified_data?.customer_countries || []).map((item: { country: string; evidence: any }) => ({
      found: item.country,
      evidence: item.evidence,
    })),
  ];

  return (
    <div className="flex flex-col gap-y-3 w-full">
      {Header}
      {!checkResult.passed && <Alert message={checkResult.answer} level="failure" />}
      {checkResult.status === "running" ? (
        evidenceList.length > 0 ? (
          <div className="h-[200px]">
            <SmartSkeleton evidenceList={evidenceList} />
          </div>
        ) : (
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 text-xs">
            <div className="rounded-lg flex flex-col gap-y-2 p-4 bg-slate-50">
              <span className="font-semibold">Operating Countries</span>
              <div className="flex flex-col gap-y-2">
                <div className="h-2.5 bg-slate-200 rounded w-2/3 animate-pulse"></div>
                <div className="h-2.5 bg-slate-200 rounded w-1/3 animate-pulse"></div>
                <div className="h-2.5 bg-slate-200 rounded w-1/2 animate-pulse"></div>
                <div className="h-2.5 bg-slate-200 rounded w-1/4 animate-pulse"></div>
              </div>
            </div>
            <div className="rounded-lg flex flex-col gap-y-2 p-4 bg-slate-50">
              <span className="font-semibold">Customer Countries</span>
              <div className="flex flex-col gap-y-2">
                <div className="h-2.5 bg-slate-200 rounded w-2/3 animate-pulse"></div>
                <div className="h-2.5 bg-slate-200 rounded w-1/3 animate-pulse"></div>
                <div className="h-2.5 bg-slate-200 rounded w-1/2 animate-pulse"></div>
                <div className="h-2.5 bg-slate-200 rounded w-1/4 animate-pulse"></div>
              </div>
            </div>
          </div>
        )
      ) : checkResult.check_result ? (
        operatingCountries.length === 0 && customerCountries.length === 0 ? (
          <span className="p-3 bg-slate-50 text-slate-500 text-sm">No matches found</span>
        ) : (
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 text-xs">
            {operatingCountries.length > 0 && (
              <div
                className={twJoin(
                  "rounded-lg flex flex-col gap-y-2 p-4",
                  prohibitedOperatingCountries.length > 0
                    ? "border border-red-700"
                    : flaggedOperatingCountries.length > 0
                      ? "border border-yellow-700"
                      : "bg-slate-50",
                )}
              >
                <span className="font-semibold">Operating Countries</span>
                <div className="flex items-center gap-x-1">
                  {operatingCountriesSourceIds.map((sourceId: string) => (
                    <SourceTagCircle
                      key={sourceId}
                      source={sourcesDictionary[sourceId]}
                      isActive={activeSource === sourceId}
                      setActiveSource={setActiveSource}
                      handleSourceClick={handleSourceClick}
                    />
                  ))}
                </div>
                <div className="flex flex-col gap-y-2">
                  {operatingCountries.length > 0 ? (
                    operatingCountries.map(
                      (
                        country: { flagged: boolean; prohibited: boolean; country: string; source_id: string },
                        index: number,
                      ) => (
                        <div key={`${country.country}-${index}`} className="flex items-center justify-between gap-x-2">
                          <span
                            key={index}
                            className={twJoin(
                              "text-sm",
                              country.flagged
                                ? "font-semibold text-yellow-700"
                                : country.prohibited
                                  ? "font-semibold text-red-700"
                                  : "text-slate-700",
                            )}
                          >
                            {country.country}
                          </span>
                          {country.flagged && (
                            <WrappedValue
                              text={
                                <div className="flex items-center gap-x-0.5">
                                  <WarningRounded sx={{ fontSize: "1rem" }} />
                                  <span>High Risk</span>
                                </div>
                              }
                              level="caution"
                            />
                          )}
                          {country.prohibited && (
                            <WrappedValue
                              text={
                                <div className="flex items-center gap-x-0.5">
                                  <CancelRounded sx={{ fontSize: "1rem" }} />
                                  <span>Prohibited</span>
                                </div>
                              }
                              level="failure"
                            />
                          )}
                          {country.source_id && (
                            <SourceTagCircle
                              source={sourcesDictionary[country.source_id]}
                              isActive={activeSource === country.source_id}
                              setActiveSource={setActiveSource}
                              handleSourceClick={handleSourceClick}
                            />
                          )}
                        </div>
                      ),
                    )
                  ) : (
                    <span className="p-3 bg-slate-50 text-slate-500 text-sm">No matches found</span>
                  )}
                </div>
              </div>
            )}
            {customerCountries.length > 0 && (
              <div
                className={twJoin(
                  "rounded-lg flex flex-col gap-y-2 p-4",
                  prohibitedCustomerCountries.length > 0
                    ? "border border-red-700"
                    : flaggedCustomerCountries.length > 0
                      ? "border border-yellow-700"
                      : "bg-slate-50",
                )}
              >
                <span className="font-semibold">Customer Countries</span>
                <div className="flex items-center gap-x-1">
                  {customerCountriesSourceIds.map((sourceId: string) => (
                    <SourceTagCircle
                      key={sourceId}
                      source={sourcesDictionary[sourceId]}
                      isActive={activeSource === sourceId}
                      setActiveSource={setActiveSource}
                      handleSourceClick={handleSourceClick}
                    />
                  ))}
                </div>
                <div className="flex flex-col gap-y-2">
                  {customerCountries.length > 0 ? (
                    customerCountries.map(
                      (
                        country: {
                          flagged: any;
                          prohibited: any;
                          country: ReactNode;
                          source_id: string | null | undefined;
                        },
                        index: Key | null | undefined,
                      ) => (
                        <div className="flex items-center justify-between gap-x-2">
                          <span
                            key={index}
                            className={twJoin(
                              "text-sm",
                              country.flagged
                                ? "font-semibold text-yellow-700"
                                : country.prohibited
                                  ? "font-semibold text-red-700"
                                  : "text-slate-700",
                            )}
                          >
                            {country.country}
                          </span>
                          {country.flagged && (
                            <WrappedValue
                              text={
                                <div className="flex items-center gap-x-0.5">
                                  <WarningRounded sx={{ fontSize: "1rem" }} />
                                  <span>High Risk</span>
                                </div>
                              }
                              level="caution"
                            />
                          )}
                          {country.prohibited && (
                            <WrappedValue
                              text={
                                <div className="flex items-center gap-x-0.5">
                                  <CancelRounded sx={{ fontSize: "1rem" }} />
                                  <span>Prohibited</span>
                                </div>
                              }
                              level="failure"
                            />
                          )}
                          {country.source_id && (
                            <SourceTagCircle
                              source={sourcesDictionary[country.source_id]}
                              isActive={activeSource === country.source_id}
                              setActiveSource={setActiveSource}
                              handleSourceClick={handleSourceClick}
                            />
                          )}
                        </div>
                      ),
                    )
                  ) : (
                    <span className="p-3 bg-slate-50 text-slate-500 text-sm">No matches found</span>
                  )}
                </div>
              </div>
            )}
          </div>
        )
      ) : null}
    </div>
  );
};

export default HighRiskCountries;
