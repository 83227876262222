import { snakeCaseToProperCase } from "@/utils";
import { Policy } from "@mui/icons-material";
import { ReactNode, useState } from "react";

export type PolicyType = {
  match_type: string;
  url: string | null;
  explanation: string | null;
  sourceComponent?: ReactNode;
};

const WebsitePolicyCard = ({ match_type, url, explanation, sourceComponent }: PolicyType) => {
  const [iconLoadingError, setIconLoadingError] = useState(false);

  const urlToUse = url && !url.startsWith("http") ? `https://${url}` : url;
  const domain = url ? new URL(url).hostname : "";

  return (
    <div className={"flex flex-col gap-y-2 border rounded-lg p-3 border-slate-200"}>
      <div className="flex items-center gap-x-2">
        {!iconLoadingError ? (
          <img
            src={`https://www.google.com/s2/favicons?domain=${domain}`}
            alt={`${domain} favicon`}
            className="w-4 h-4 rounded-full"
            onError={({ currentTarget }) => {
              currentTarget.style.display = "none";
              setIconLoadingError(true);
            }}
          />
        ) : (
          <Policy sx={{ fontSize: "1rem" }} />
        )}
        {urlToUse ? (
          <a href={urlToUse} target="_blank" rel="noreferrer" className="text-brand-purple">
            {snakeCaseToProperCase(match_type)}
          </a>
        ) : (
          <span className="text-slate-700">{snakeCaseToProperCase(match_type)}</span>
        )}
        {sourceComponent ?? null}
      </div>
      {explanation && <p className="text-slate-700">{explanation}</p>}
    </div>
  );
};

export default WebsitePolicyCard;
