// @ts-nocheck
import { ChangeEvent, useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import Alert from "@/components/TailwindComponents/Alert";
import {
  CheckCircleRounded,
  CancelRounded,
  OfflineBoltRounded,
  SendRounded,
  UploadFileRounded,
  OpenInNew,
} from "@mui/icons-material";
import InputWithOverlappingLabel from "./TailwindComponents/InputWithOverlappingLabel";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useParchaApi } from "@/hooks/useParchaApi";
import FakeProgress from "fake-progress";
import Progress from "@/components/ui/progress";
import DocumentValue from "./TailwindComponents/DocumentValue";
import { Icon } from "@mui/material";
import { twJoin } from "tailwind-merge";
import CheckStatusMessage from "./TailwindComponents/CheckStatusMessage";
import { Endpoint } from "@/types";
import fastBankLogo from "@/assets/paxos_logo.png";
import valleyLogo from "@/assets/valley-logo.png";
import brexLogo from "@/assets/brex_logo.png";
import airwallexLogo from "@/assets/airwallex_logo.png";

/**
 *
 * TODO:
 * 1. Get endpoint from props
 * 2. Use checkId to match results with document component
 * 3. Add back interval to check job status
 *
 */
const RemediationPage = () => {
  const [formData, setFormData] = useState({
    businessName: "Parcha Labs, Inc.",
  });

  const [checkJobIds, setCheckJobIds] = useState({});
  const [latestApplicationState, setLatestApplicationState] = useState({});
  const [intervalId, setIntervalId] = useState(null);
  const [isApplicationSubmitted, setIsApplicationSubmitted] = useState(false);

  const [endpoints, setEndpoints] = useState<Endpoint[]>([]);
  const [endpoint, setEndpoint] = useState<Endpoint | null>(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const applicationId = searchParams.get("application_id");
  const navigate = useNavigate();

  const parchaApi = useParchaApi();

  const fetchEndpoints = async () => {
    const customerAgents = await parchaApi.getCustomerAgents();
    const { endpoints } = customerAgents as EndpointsAndTenant;
    setEndpoints(endpoints);
  };

  const fetchAgent = async () => {
    if (endpoint) {
      const agent = await parchaApi.getSimpleAgent(endpoint.endpointUrl, endpoint.agent_key);
    }
  };
  /** FETCHERS END */

  // If the application_id is not in the URL, we navigate to a random UUID
  useEffect(() => {
    if (!searchParams.get("application_id")) {
      navigate(`/remediation?application_id=${crypto.randomUUID()}`);
    }
  }, [searchParams]);

  // Step 1: Fetch all endpoints to populate the dropdown
  useEffect(() => {
    fetchEndpoints();
  }, []);

  // Step 2: When we have the endpoints and/or agentKey, we select the current endpoint
  useEffect(() => {
    if (endpoints.length > 0) {
      const remediationEndpoint = endpoints.find((endpoint) => endpoint.agentKey === "remediation-v1");

      if (!remediationEndpoint) {
        navigate("/jobs");
      }

      setEndpoint(remediationEndpoint);
    }
    fetchAgent();
  }, [endpoints]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileUpload = (checkId: string) => async (file: File) => {
    const filteredCurrentCheckResults = latestApplicationState?.current_check_results?.filter(
      (result) => result.command_id !== checkId,
    );

    if (filteredCurrentCheckResults?.length === 0) {
      setLatestApplicationState({
        ...latestApplicationState,
        current_check_results: filteredCurrentCheckResults,
      });
    }

    return runFileCheck(checkId, file);
  };

  const runFileCheck = async (checkId: string, file: File) => {
    if (!endpoint || !applicationId) {
      console.log("Endpoint or applicationId is missing");
      if (!endpoint) console.log("endpoint is missing");
      if (!applicationId) console.log("applicationId is missing");
      return;
    }

    try {
      // Convert file to base64
      const base64String = await fileToBase64(file);

      const checkRunResponse = await parchaApi.runCheck(endpoint.endpointUrl, checkId, {
        agent_key: "remediation-v1",
        kyb_schema: {
          id: applicationId,
          self_attested_data: {
            business_name: formData.businessName,
            registered_business_name: "Parcha Labs Incorporated",
            proof_of_address_documents:
              checkId === "kyb.proof_of_address_verification"
                ? [{ file_name: file.name, b64_document: base64String }]
                : undefined,
            incorporation_documents:
              checkId === "kyb.incorporation_document_verification"
                ? [{ file_name: file.name, b64_document: base64String }]
                : undefined,
            business_ownership_documents:
              checkId === "kyb.business_ownership_verification_tool"
                ? [{ file_name: file.name, b64_document: base64String }]
                : undefined,
            address_of_operation: {
              street_1: "755 Sansome St.",
              street_2: "Suite 350",
              city: "San Francisco",
              state: "CA",
              country_code: "US",
              postal_code: "94111",
            },
            address_of_incorporation: {
              street_1: "251 Little Falls Drive",
              street_2: null,
              city: "Wilmington",
              state: "DE",
              country_code: "US",
              postal_code: "19808",
            },
            incorporation_date: "2023-03-29",
            website: "https://parcha.ai",
            business_purpose: "B2B Software as a Service",
            description: "AI agents for ops and compliance workflow automation.",
            industry: "Technology",
            tin_number: "7379553",
            partners: [],
            customers: [],
            source_of_funds: ["Investment", "Revenue"],
            promo_marketing_documents: [],
          },
          associated_individuals: [
            {
              id: "parcha-kyc-test",
              self_attested_data: {
                first_name: "Ajmal",
                middle_name: "Arshan",
                last_name: "Asver",
                date_of_birth: "1985-12-24",
                address: {
                  street_1: "746 4th Ave",
                  street_2: null,
                  city: "San Francisco",
                  state: "CA",
                  country_code: "US",
                  postal_code: "94118",
                },
                country_of_nationality: "US",
                country_of_residence: "US",
                place_of_birth: "Colombo, Sri Lanka",
                sex: "Male",
                email: "aj@parcha.ai",
                phone: "4152902190",
                title: "CEO",
                is_applicant: true,
                is_business_owner: true,
                business_ownership_percentage: 0.45,
                proof_of_address_documents: [
                  {
                    url: "https://parcha-ai-backtest-data.s3.amazonaws.com/parcha/AJ_proof_of_address.pdf",
                    file_name: "AJ_proof_of_address.pdf",
                    source_type: "file_url",
                  },
                ],
              },
              government_id_check: {
                data: {
                  id_first_name: "Ajmal",
                  id_last_name: "Asver",
                  id_middle_names: "Arshan",
                  id_date_of_birth: "1985-12-24",
                  id_type: "Driver's License",
                  id_number: "987654321",
                  id_address: {
                    street_1: "746 4th Ave",
                    street_2: null,
                    city: "San Francisco",
                    state: "CA",
                    country_code: "US",
                    postal_code: "94118",
                  },
                  id_country: "US",
                  id_phone: "4152902190",
                  id_front_image_url: "https://parcha-ai-backtest-data.s3.amazonaws.com/parcha/IMG_0627.jpg",
                  id_back_image_url: null,
                  id_face_match_image_url: null,
                  id_face_match_video_url: null,
                  id_verification_vendor: "Veriff",
                  vendor_verification_url: null,
                  vendor_validated_document: true,
                  vendor_id_verification_data: {
                    veriff_id: "123456789",
                    veriff_status: "approved",
                    veriff_result: "clear",
                    veriff_code: "1002",
                  },
                },
              },
            },
            {
              id: "parcha-kyc-test-2",
              self_attested_data: {
                first_name: "Miguel",
                middle_name: "Angel",
                last_name: "Rios Berrios",
                date_of_birth: "1986-04-25",
                address: {
                  street_1: "216 Encounter Bay",
                  street_2: null,
                  city: "Alameda",
                  state: "CA",
                  country_code: "US",
                  postal_code: "94502",
                },
                country_of_nationality: "US",
                country_of_residence: "US",
                place_of_birth: "Aibonito, Puerto Rico",
                sex: "Male",
                email: "miguel@miguelrios.org",
                phone: "5103457204",
                title: "Chief Technology Officer",
                is_applicant: false,
                is_business_owner: true,
                business_ownership_percentage: 0.45,
                proof_of_address_documents: [
                  {
                    url: "https://parcha-ai-backtest-data.s3.amazonaws.com/parcha/bill-miguel.pdf",
                    file_name: "bill-miguel.pdf",
                    source_type: "file_url",
                  },
                ],
              },
              government_id_check: {
                data: {
                  id_first_name: "Miguel",
                  id_last_name: "Rios Berrios",
                  id_middle_names: "Angel",
                  id_date_of_birth: "1986-04-25",
                  id_type: "Driver's License",
                  id_number: "Y4655272",
                  id_address: {
                    street_1: "216 Encounter Bay",
                    street_2: null,
                    city: "Alameda",
                    state: "CA",
                    country_code: "US",
                    postal_code: "94502",
                  },
                  id_country: "US",
                  id_phone: "4153457204",
                  id_front_image_url: "https://parcha-ai-backtest-data.s3.amazonaws.com/parcha/IMG_4837_Original.JPG",
                  id_back_image_url: null,
                  id_face_match_image_url: null,
                  id_face_match_video_url: null,
                  id_verification_vendor: "Veriff",
                  vendor_verification_url: null,
                  vendor_validated_document: true,
                  vendor_id_verification_data: {
                    veriff_id: "123456789",
                    veriff_status: "approved",
                    veriff_result: "clear",
                    veriff_code: "1002",
                  },
                },
              },
            },
          ],
        },
      });
      setCheckJobIds({ ...checkJobIds, [checkId]: checkRunResponse.id });
      return checkRunResponse;
    } catch (error) {
      console.error("Error in runFileCheck:", error);
    }
  };

  // Helper function to convert File to base64
  const fileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result?.toString().split(",")[1];
        if (base64String) {
          resolve(base64String);
        } else {
          reject(new Error("Failed to convert file to base64"));
        }
      };
      reader.onerror = (error) => reject(error);
    });
  };

  useEffect(() => {
    if (!applicationId || !endpoint) return;

    const getApplicationState = async () => {
      const latestApplicationState = await parchaApi
        .getLatestApplicationState(endpoint.endpointUrl, applicationId, "remediation-v1")
        .catch((err) => ({ current_check_results: [], past_check_results: [] }));
      setLatestApplicationState(latestApplicationState);
    };

    getApplicationState();
  }, [applicationId, endpoint]);

  useEffect(() => {
    if (!checkJobIds || Object.keys(checkJobIds).length === 0 || !applicationId) return;

    const intervalId = setInterval(async () => {
      const latestApplicationState = await parchaApi.getLatestApplicationState(
        endpoint.endpointUrl,
        applicationId,
        "remediation-v1",
      );

      setLatestApplicationState(latestApplicationState);
    }, 2000);

    setIntervalId(intervalId);

    return () => {
      clearInterval(intervalId);
    };
  }, [checkJobIds]);

  useEffect(() => {
    if (latestApplicationState?.current_check_results?.length > 0) {
      const allCompleted = latestApplicationState?.current_check_results?.every(
        (checkResult) =>
          checkResult.answer !== null && checkResult.passed !== null && (checkResult.follow_up || checkResult.passed),
      );

      if (allCompleted) {
        clearInterval(intervalId);
      }
    }
  }, [latestApplicationState]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    await parchaApi.updateApplicationStatus(endpoint.endpointUrl, applicationId, "submitted");
    setIsApplicationSubmitted(true);
  };

  const incorporationResult = latestApplicationState?.current_check_results?.find(
    (result) => result.command_id === "kyb.incorporation_document_verification",
  );

  const pastIncorporationResults = latestApplicationState?.past_check_results?.filter(
    (result) => result.command_id === "kyb.incorporation_document_verification",
  );

  const pastBusinessOwnershipResults = latestApplicationState?.past_check_results?.filter(
    (result) => result.command_id === "kyb.business_ownership_verification_tool",
  );

  const businessOwnershipResult = latestApplicationState?.current_check_results?.find(
    (result) => result.command_id === "kyb.business_ownership_verification_tool",
  );

  const proofOfAddressResult = latestApplicationState?.current_check_results?.find(
    (result) => result.command_id === "kyb.proof_of_address_verification",
  );

  const pastProofOfAddressResults = latestApplicationState?.past_check_results?.filter(
    (result) => result.command_id === "kyb.proof_of_address_verification",
  );

  return isApplicationSubmitted ? (
    <div className="w-full h-full flex justify-center">
      <div className="mx-auto flex flex-col gap-y-3 mt-10 text-slate-900 text-xl">
        <h3 className="text-green-600 font-semibold">Success!</h3>
        <p className="font-semibold">Your documents have been validated and submitted</p>
        <p className="font-normal text-sm">We will be in touch after we complete reviewing your application</p>
        <div className="flex gap-x-5 mt-7">
          <button
            type="button"
            className="text-xs relative flex justify-center items-center rounded-md p-2 h-4 border border-solid border-slate-300 text-slate-900 focus:z-10 "
            onClick={() => (window.location.href = `/remediation?application_id=parcha-${Date.now()}`)}
          >
            <UploadFileRounded sx={{ fontSize: "1rem" }} className="mr-1 h-4 w-4" aria-hidden="true" />
            <span className="line-clamp-1">Submit more documents</span>
          </button>
          <button
            type="button"
            className="text-xs relative flex justify-center items-center rounded-md p-2 h-4 border border-solid border-brand-purple bg-brand-purple text-white focus:z-10 "
            onClick={() => window.open(`/applications/${applicationId}`)}
          >
            <span className="line-clamp-1">View in Parcha</span>
            <OpenInNew sx={{ fontSize: "1rem" }} className="ml-1 h-4 w-4" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  ) : (
    <div className="grid grid-rows-1 h-screen auto-rows-auto">
      <div className="w-full h-full p-0 lg:px-6 flex items-center justify-center text-xs">
        <div className="flex flex-col mx-auto p-8 w-0 min-w-full lg:min-w-[50rem] bg-white gap-10 h-full justify-center">
          <div className="grow-0">
            <h1 className="font-semibold text-lg">Provide Business & Identification Documents Securely</h1>
            <span>Validate and submit your encypted files</span>
          </div>
          <form className="h-full" onSubmit={handleSubmit}>
            <div className="h-0 min-h-full flex flex-col gap-10 grow overflow-y-auto">
              <InputWithOverlappingLabel
                label="Business Name"
                value={formData.businessName}
                name="businessName"
                id="businessName"
                fieldName="businessName"
                onChangeHandler={handleChange}
                className="w-1/2 mt-2"
              />
              <DocumentField
                iconKey="description"
                title="Business Registration"
                description={
                  <div className="flex flex-col gap-y-2">
                    <p>
                      Document(s) to verify the registered business name, address of incorporation, date of
                      incorporation, and business number.
                    </p>
                    <p>Must be signed by an agent</p>
                  </div>
                }
                fieldKey="incorporationDocs"
                endpoint={endpoint}
                afterFileUploadedCallback={handleFileUpload("kyb.incorporation_document_verification")}
                afterFileDeletedCallback={() => {}}
                result={incorporationResult}
                pastResults={pastIncorporationResults}
              />
              <DocumentField
                iconKey="request_quote"
                title="Business Ownership"
                description={
                  <div className="flex flex-col gap-y-2">
                    <p>
                      Document(s) to verify the ultimate business owners and the amount of equity each business owner
                      has.
                    </p>
                  </div>
                }
                fieldKey="businessOwnershipDocs"
                endpoint={endpoint}
                afterFileUploadedCallback={handleFileUpload("kyb.business_ownership_verification_tool")}
                afterFileDeletedCallback={() => {}}
                result={businessOwnershipResult}
                pastResults={pastBusinessOwnershipResults}
              />
              <DocumentField
                iconKey="markunread_mailbox"
                title="Proof of Business Operation Address"
                description={
                  <div className="flex flex-col gap-y-2">
                    <p>
                      Document(s) to verify the business operating address, such as a utility bill, lease agreement,
                      bank statement, or similar document.
                    </p>
                    <p>Must be dated no later than 90 days ago</p>
                  </div>
                }
                fieldKey="proofOfAddressDocs"
                endpoint={endpoint}
                afterFileUploadedCallback={handleFileUpload("kyb.proof_of_address_verification")}
                afterFileDeletedCallback={() => {}}
                result={proofOfAddressResult}
                pastResults={pastProofOfAddressResults}
              />
              <div className="w-full flex justify-end gap-x-3 py-5">
                <button
                  disabled={
                    !proofOfAddressResult?.passed || !incorporationResult?.passed || !businessOwnershipResult?.passed
                  }
                  type="submit"
                  className="inline-flex justify-center items-center py-2 px-4 border border-transparent shadow-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <SendRounded sx={{ fontSize: "1rem" }} className="mr-1 h-4 w-4" aria-hidden="true" />
                  <span className="line-clamp-1">Submit</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const DocumentFieldLabel = ({ iconKey, title, description }) => {
  return (
    <div className="flex gap-2.5">
      <Icon sx={{ fontSize: "1.25rem" }} className="material-icons-round text-slate-400">
        {iconKey}
      </Icon>
      <div className="flex flex-col gap-y-2.5">
        {title && <span className="font-semibold text-sm text-slate-700">{title}</span>}
        {description && <span className="text-xs text-slate-600">{description}</span>}
      </div>
    </div>
  );
};

const DocumentUploader = ({
  fieldKey,
  endpoint,
  afterFileUploadedCallback,
  setIsUploadingDocument,
  result,
}: {
  fieldKey: string;
  endpoint: { endpointUrl: string };
  afterFileUploadedCallback: (file: File) => Promise<any>;
  setIsUploadingDocument: (isUploading: boolean) => void;
  result: any;
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isCheckRunning, setIsCheckRunning] = useState(false);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const [progress, setProgress] = useState(0);

  const didPass = ["true", "pass", "passed", "yes"].includes(result?.passed?.toString().toLowerCase());

  useEffect(() => {
    if (!selectedFile) return;

    const p = new FakeProgress({
      timeConstant: 45000,
      autoStart: true,
    });

    const interval = setInterval(() => {
      setProgress(p.progress);
    }, 500);

    return () => clearInterval(interval);
  }, [selectedFile]);

  useEffect(() => {
    if (!result) {
      setIsCheckRunning(false);
      return;
    }

    if (result.answer && result.passed !== null && (result.follow_up || result.passed)) {
      setIsCheckRunning(false);
      setSelectedFile(null);
    } else {
      setIsCheckRunning(true);
    }
  }, [result]);

  const handleDocumentInputClick = () => {
    inputRef.current?.click();
  };

  const processFile = async (file: File) => {
    try {
      setSelectedFile(file);
      await afterFileUploadedCallback(file);
      setIsCheckRunning(true);
    } catch (error) {
      console.error("Error processing file:", error);
      // Handle error (e.g., show an error message to the user)
    } finally {
      setIsCheckRunning(false);
    }
  };

  const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files || !files.length) return;
    processFile(files[0]);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDraggingOver(false);
    const files = e.dataTransfer.files;
    if (!files || !files.length) return;
    processFile(files[0]);
  };

  const statusEvents = result?.status_messages.filter((message) => message.event === "status");
  const lastStatusMessage = statusEvents && statusEvents[statusEvents.length - 1];

  return (
    <div className="flex flex-col gap-2">
      {selectedFile ? (
        <>
          <div className="flex items-center gap-4">
            <div className="flex flex-col gap-y-2 p-5 border border-slate-300 rounded-lg grow">
              <div className="flex items-center justify-between">
                <DocumentValue fileName={selectedFile.name} maxWidth={1000} />

                {selectedFile && <Progress className="w-[5rem] h-[0.5rem]" value={progress * 100} />}
                {!selectedFile && result && result.passed !== null ? (
                  didPass ? (
                    <CheckCircleRounded sx={{ fontSize: "1.25rem" }} className="text-green-600" />
                  ) : (
                    <CancelRounded sx={{ fontSize: "1.25rem" }} className="text-red-500" />
                  )
                ) : null}
              </div>
              {isCheckRunning && lastStatusMessage && lastStatusMessage.content && (
                <CheckStatusMessage
                  className="px-0 mx-0 mt-2"
                  messageContents={{ status: lastStatusMessage.content.status }}
                />
              )}
              {result && result?.passed !== null && result.follow_up ? (
                !didPass ? (
                  <Alert level="failure" message={<ReactMarkdown>{result.follow_up}</ReactMarkdown>} />
                ) : null
              ) : null}
            </div>
          </div>
        </>
      ) : null}
      <div
        className={twJoin(
          "flex flex-col items-center gap-y-1 justify-center h-full text-xs border-2 border-dashed py-6 px-3 rounded-lg",
          isDraggingOver ? "border-blue-500 bg-blue-50" : "border-slate-300",
        )}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onDragExit={() => setIsDraggingOver(false)}
        onDragLeave={() => setIsDraggingOver(false)}
      >
        <UploadFileRounded sx={{ fontSize: "1.25rem" }} className="text-slate-400" />
        <p className="text-slate-700">
          Drag & Drop or{" "}
          <span className="underline text-brand-purple" role="button" onClick={handleDocumentInputClick}>
            Choose a document
          </span>{" "}
          to upload
        </p>
        <input
          type="file"
          name={fieldKey}
          accept="image/jpg,image/jpeg,image/png,application/pdf"
          onChange={handleFileSelect}
          onBlur={(e) => e.preventDefault()}
          ref={inputRef}
          className="hidden"
        />
      </div>
    </div>
  );
};

const DocumentField = ({
  iconKey,
  title,
  description,
  fieldKey,
  endpoint,
  setIsUploadingDocument,
  afterFileUploadedCallback,
  afterFileDeletedCallback,
  result,
  pastResults,
}) => {
  const currentResultDocument = result?.verification_data
    ? result?.command_id === "kyb.proof_of_address_verification"
      ? [
          ...(result?.verification_data?.proof_of_address_documents || []),
          ...(result?.verification_data?.valid_documents || []),
          ...(result?.verification_data?.invalid_documents || []),
        ][0]?.document
      : result?.command_id === "kyb.incorporation_document_verification"
        ? [
            ...(result?.verification_data?.documented_incorporation_information || []),
            ...(result?.verification_data?.valid_documents || []),
            ...(result?.verification_data?.invalid_documents || []),
          ][0]?.document
        : result?.command_id === "kyb.business_ownership_verification_tool"
          ? [
              ...(result?.verification_data?.documented_ownership || []),
              ...(result?.verification_data?.valid_documents || []),
              ...(result?.verification_data?.invalid_documents || []),
            ][0]?.document
          : null
    : null;

  return (
    <div className="flex flex-col gap-2.5">
      <DocumentFieldLabel iconKey={iconKey} title={title} description={description} />
      {pastResults && pastResults.length > 0 && (
        <div className="flex flex-col gap-y-2">
          {pastResults.map((result) => {
            if (!result.answer) return null;

            const document = result?.verification_data
              ? result?.command_id === "kyb.proof_of_address_verification"
                ? [
                    ...(result?.verification_data?.proof_of_address_documents || []),
                    ...(result?.verification_data?.valid_documents || []),
                    ...(result?.verification_data?.invalid_documents || []),
                  ][0]?.document
                : result?.command_id === "kyb.incorporation_document_verification"
                  ? [
                      ...(result?.verification_data?.documented_incorporation_information || []),
                      ...(result?.verification_data?.valid_documents || []),
                      ...(result?.verification_data?.invalid_documents || []),
                    ][0]?.document
                  : result?.command_id === "kyb.business_ownership_verification_tool"
                    ? [
                        ...(result?.verification_data?.documented_ownership || []),
                        ...(result?.verification_data?.valid_documents || []),
                        ...(result?.verification_data?.invalid_documents || []),
                      ][0]?.document
                    : null
              : null;

            return (
              <div className="border border-slate-300 rounded-lg p-5 flex flex-col gap-y-3" key={result.job_id}>
                <div className="w-full flex justify-between">
                  <DocumentValue fileName={document?.file_name ?? ""} url={document?.url} maxWidth={1000} />
                  {result.passed ? (
                    <CheckCircleRounded sx={{ fontSize: "1.25rem" }} className="text-green-600" />
                  ) : (
                    <CancelRounded sx={{ fontSize: "1.25rem" }} className="text-red-500" />
                  )}
                </div>
                {!result.passed && result.follow_up && (
                  <Alert level={"failure"} message={<ReactMarkdown>{result.follow_up}</ReactMarkdown>} />
                )}
              </div>
            );
          })}
        </div>
      )}
      {result && result.answer && result.passed !== null && (result.follow_up || result.passed) && (
        <div className="border border-slate-300 rounded-lg p-5 flex flex-col gap-y-3">
          {currentResultDocument && (
            <div className="w-full flex justify-between">
              <DocumentValue
                fileName={currentResultDocument?.file_name}
                url={currentResultDocument?.url}
                maxWidth={1000}
              />
              {result.passed ? (
                <CheckCircleRounded sx={{ fontSize: "1.25rem" }} className="text-green-600" />
              ) : (
                <CancelRounded sx={{ fontSize: "1.25rem" }} className="text-red-500" />
              )}
            </div>
          )}

          {result.passed !== null && !result.passed && result.follow_up && (
            <Alert level={"failure"} message={<ReactMarkdown>{result.follow_up}</ReactMarkdown>} />
          )}
        </div>
      )}
      <DocumentUploader
        endpoint={endpoint}
        afterFileUploadedCallback={afterFileUploadedCallback}
        afterFileDeletedCallback={afterFileDeletedCallback}
        result={result}
      />
    </div>
  );
};

export default RemediationPage;
