import xLogo from "../../assets/x_logo_dark_bg.png";

type XTwitterProfileCardProps = {
  description: string | null;
  followers: number | null;
  name: string | null;
  profilePicture: string | null;
  twitterUrl: string | null;
  userName: string | null;
};

const XTwitterProfileCard = ({
  description,
  followers,
  name,
  profilePicture,
  twitterUrl,
  userName,
}: XTwitterProfileCardProps) => {
  if (!name && !twitterUrl) return null;

  return (
    <div className="w-full rounded-xl text-slate-900 text-xs p-4 bg-slate-50 overflow-hidden h-fit mb-4">
      <div className="flex flex-col gap-y-5">
        <div className="flex flex-col gap-y-4">
          <div className="flex gap-x-2 items-center justify-between">
            <div className="flex gap-x-2">
              <img src={xLogo} alt="X (Twitter) Logo" className="w-4 h-4" />
              <span className="font-semibold">X (formerly Twitter)</span>
            </div>
            <div className="h-6.5 min-w-[6rem] flex justify-center items-center rounded-full bg-slate-50 border border-[#334155]/20 py-1 px-2 text-slate-700">
              Social Media
            </div>
          </div>
          {twitterUrl && (
            <a
              href={twitterUrl.startsWith("http") ? twitterUrl : `https://${twitterUrl}`}
              target="_blank"
              rel="noreferrer"
              className="max-w-fit truncate text-brand-purple hover:underline"
            >
              {twitterUrl}
            </a>
          )}
          <div className="flex gap-x-3">
            {profilePicture && (
              <img
                src={profilePicture}
                alt="Company Logo"
                className="w-12 h-12 rounded-full object-cover"
                onError={({ currentTarget }) => (currentTarget.style.display = "none")}
              />
            )}
            <div className="flex flex-col gap-y-0.5">
              <div className="flex flex-wrap items-baseline gap-x-1">
                <span className="text-base text-bold">{name}</span>
                {userName && <span className="text-slate-500">{userName}</span>}
              </div>
              {followers && <div className="text-slate-500">{followers} Followers</div>}
            </div>
          </div>
          {description && (
            <div className="flex flex-col gap-y-1">
              <span className="font-semibold">Description</span>
              <p className="text-slate-700">{description}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default XTwitterProfileCard;
