import { useParchaApi } from "@/hooks/useParchaApi";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ParchaReport from "@/components/TailwindComponents/PublicBDDReport";
import ParchaLoadingScreen from "../ParchaLoadingScreen";

const envTier = import.meta.env.VITE_ENV_TIER;
const endpointUrl =
  envTier === "development" ? "localhost:8001" : envTier === "staging" ? "staging.parcha.ai" : "demo.parcha.ai";

const Report = () => {
  const { reportJobId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [jobMetadata, setJobMetadata] = useState(null);

  const parchaApi = useParchaApi();

  useEffect(() => {
    if (!reportJobId) return;

    setIsLoading(true);

    parchaApi.getJobMetadata(endpointUrl, reportJobId).then((res: any) => {
      setJobMetadata(res);
      setIsLoading(false);
    });

    const interval = setInterval(() => {
      parchaApi.getJobMetadata(endpointUrl, reportJobId).then((res: any) => {
        setJobMetadata(res);
        const isJobDone = ["complete", "error", "failed"].includes(res.job.status);

        if (isJobDone) {
          clearInterval(interval);
        }
      });
    }, 2500);

    return () => clearInterval(interval);
  }, [reportJobId]);

  return isLoading ? (
    <ParchaLoadingScreen message="Loading your report..." size="large" />
  ) : jobMetadata ? (
    <div className="w-full h-0 min-h-full overflow-auto">
      <ParchaReport jobMetadata={jobMetadata} />
    </div>
  ) : null;
};

export default Report;
