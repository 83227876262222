import { twJoin } from "tailwind-merge";
import AddressValue, { AddressValueProps } from "./AddressValue";
import WrappedValue from "./WrappedValue";
import WebsiteValue from "./WebsiteValue";
import DocumentValue from "./DocumentValue";
import { tryToParseDate } from "@/utils";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { useContext, useEffect, useState } from "react";
import { useParchaApi } from "@/hooks/useParchaApi";
import { ErrorContext } from "@/contexts/ErrorContext";
import Business from "@mui/icons-material/Business";
import IndividualSelfAttestedDataCard from "./IndividualSelfAttestedDataCard";

type BusinessSelfAttestedDataCardProps = {
  generateURLs?: () => void | undefined;
  isParchaUser?: boolean;
  caseId: string;
  endpointUrl: string;
  level: "normal" | "success" | "failure";
  payload: { [key: string]: any };
  individuals?: { [key: string]: any };
};

export default function BusinessSelfAttestedDataCard({
  isParchaUser = false,
  caseId,
  endpointUrl,
  level,
  payload,
  individuals,
}: BusinessSelfAttestedDataCardProps) {
  const [payloadToUse, setPayloadToUse] = useState(payload);
  const [isGeneratingURLs, setIsGeneratingURLs] = useState(false);
  const parchaApi = useParchaApi();
  const { setError } = useContext(ErrorContext);

  useEffect(() => {
    setPayloadToUse(payload);
  }, [payload]);

  const {
    business_name: businessName,
    tin_number: tin,
    cannabis_license_number: cannabisLicenseNumber,
    industry,
    business_purpose: businessPurpose,
    registered_business_name: registeredBusinessName,
    address_of_incorporation: addressOfIncorporation,
    address_of_operation: addressOfOperation,
    website,
    partners,
    customers,
    source_of_funds: sourceOfFunds,
    incorporation_date: incorporationDate,
    incorporation_documents: incorporationDocuments,
    business_ownership_documents: businessOwnershipDocuments,
    proof_of_address_documents: proofOfAddressDocuments,
    promo_marketing_documents: promoMarketingDocuments,
    ein_documents: einDocuments,
    source_of_funds_documents: sourceOfFundsDocuments,
    cannabis_license_documents: cannabisLicenseDocuments,
    description,
    contact_phone_number,
    contact_email_address,
    business_registration_number: businessRegistrationNumber,
  } = payloadToUse;

  if (!website && !registeredBusinessName && !businessName && !businessPurpose && !description) return null;

  const addressOfIncorporationProps: AddressValueProps = {
    street1: addressOfIncorporation?.street_1,
    street2: addressOfIncorporation?.street_2,
    city: addressOfIncorporation?.city,
    state: addressOfIncorporation?.state,
    postalCode: addressOfIncorporation?.postal_code,
    countryCode: addressOfIncorporation?.country_code,
  };
  const addressOfOperationProps: AddressValueProps = {
    street1: addressOfOperation?.street_1,
    street2: addressOfOperation?.street_2,
    city: addressOfOperation?.city,
    state: addressOfOperation?.state,
    postalCode: addressOfOperation?.postal_code,
    countryCode: addressOfOperation?.country_code,
  };

  const handleGenerateURLsClick = async () => {
    if (isParchaUser) {
      try {
        setIsGeneratingURLs(true);
        const updatedPayload = await parchaApi.getWorkingURLs(endpointUrl, {
          case_id: caseId,
          self_attested_data: payload,
        });

        if (updatedPayload) {
          setPayloadToUse(updatedPayload);
        }
      } catch (error) {
        setError(error);
      } finally {
        setIsGeneratingURLs(false);
      }
    }
  };

  return (
    <>
      <div
        className={twJoin(
          "flex flex-col rounded-lg border text-sm text-slate-900 mt-2",
          level === "success" ? "border-green-600" : level === "failure" ? "border-red-600" : "border-slate-300",
        )}
      >
        {businessName || industry ? (
          <div
            className={twJoin(
              "flex justify-between items-center rounded-t-md p-4 border-b border-slate-300",
              level === "success"
                ? "bg-[#DCFCE7] border-b-green-600"
                : level === "failure"
                  ? "bg-red-100 border-b-red-600"
                  : "bg-slate-100 border-slate-300",
            )}
          >
            <div className="flex flex-col">
              <div className="flex gap-2 items-center">
                <Business className="text-brand-purple" sx={{ fontSize: "1rem" }} />
                {businessName ? <div className="font-medium text-sm uppercase">{businessName}</div> : null}
              </div>
              {industry ? <span className="text-xs">{industry}</span> : industry}
            </div>
            {isParchaUser &&
              (isGeneratingURLs ? (
                <p>Loading...</p>
              ) : (
                <button onClick={handleGenerateURLsClick}>
                  <AdminPanelSettingsIcon className="text-brand-purple" sx={{ fontSize: "1.5rem" }} />
                </button>
              ))}
          </div>
        ) : null}
        <div className="flex flex-col gap-4 py-3 px-4 text-xs">
          {businessName && (
            <div className="flex flex-col">
              <div className="font-semibold">Business Name:</div>
              <div>{businessName}</div>
            </div>
          )}
          {registeredBusinessName && (
            <div className="flex flex-col">
              <div className="font-semibold">Registered Business Name:</div>
              <div>{registeredBusinessName}</div>
            </div>
          )}
          {incorporationDate && (
            <div className="flex gap-2">
              <div className="font-semibold">Incorporated:</div>
              <div>{tryToParseDate(incorporationDate.replaceAll("-", "/"))}</div>
            </div>
          )}
          {tin?.length > 0 && (
            <div className="flex items-center gap-2">
              <div className="font-semibold">TIN:</div>
              <WrappedValue text={tin} level="normal" />
            </div>
          )}
          {businessRegistrationNumber?.length > 0 && (
            <div className="flex items-center gap-2">
              <div className="font-semibold">Registration Number:</div>
              <WrappedValue text={businessRegistrationNumber} level="normal" />
            </div>
          )}
          {cannabisLicenseNumber?.length > 0 && (
            <div className="flex items-center gap-2">
              <div className="font-semibold">Cannabis License Number:</div>
              <WrappedValue text={cannabisLicenseNumber} level="normal" />
            </div>
          )}
          {!website ? null : Array.isArray(website) ? (
            <div className="flex flex-col">
              <div className="font-semibold">Website:</div>
              {website.map((url, index) => (
                <WebsiteValue url={url} key={index} />
              ))}
            </div>
          ) : (
            <div className="flex flex-col">
              <div className="font-semibold">Website:</div>
              <WebsiteValue url={website} />
            </div>
          )}
          {businessPurpose && (
            <div className="flex flex-col">
              <div className="font-semibold">Business Purpose:</div>
              <span>{businessPurpose} </span>
            </div>
          )}
          {description && (
            <div className="flex flex-col">
              <div className="font-semibold">Description:</div>
              <div>{description}</div>
            </div>
          )}
          {addressOfOperationProps &&
            !Object.entries(addressOfOperationProps).every(
              ([k, v]) => k === "type" || v === null || v === "unverified" || v === "" || typeof v === "undefined",
            ) && (
              <div className="flex flex-col">
                <div className="font-semibold">Address of Operation:</div>
                <AddressValue {...addressOfOperationProps} />
              </div>
            )}
          {addressOfIncorporationProps &&
            !Object.entries(addressOfIncorporationProps).every(
              ([k, v]) => k === "type" || v === null || v === "unverified" || v === "" || typeof v === "undefined",
            ) && (
              <div className="flex flex-col">
                <div className="font-semibold">Address of Incorporation:</div>
                <AddressValue {...addressOfIncorporationProps} />
              </div>
            )}
          {contact_phone_number && (
            <div className="flex flex-col">
              <div className="font-semibold">Contact Phone Number:</div>
              <div>{contact_phone_number}</div>
            </div>
          )}
          {contact_email_address && (
            <div className="flex flex-col">
              <div className="font-semibold">Contact Email Address:</div>
              <div>{contact_email_address}</div>
            </div>
          )}
          {sourceOfFunds && sourceOfFunds.length > 0 && (
            <div className="flex flex-col">
              <span className="font-semibold">Source Of Funds: </span>
              <span>{Array.isArray(sourceOfFunds) ? sourceOfFunds.join(", ") : sourceOfFunds}</span>
            </div>
          )}
          {partners && partners.length > 0 && (
            <div className="flex flex-col">
              <span className="font-semibold">Partners: </span>
              <span>{Array.isArray(partners) ? partners.join(", ") : partners}</span>
            </div>
          )}
          {customers && customers.length > 0 && (
            <div className="flex flex-col">
              <span className="font-semibold">Customers: </span>
              <span>{Array.isArray(customers) ? customers.join(", ") : customers}</span>
            </div>
          )}
          {incorporationDocuments?.length > 0 && (
            <div className="flex flex-col">
              <span className="font-semibold">Incorporation Documents: </span>
              <div>
                {incorporationDocuments.map(({ file_name: fileName, url }: { file_name: string; url: string }) => (
                  <DocumentValue
                    key={`${fileName}${url}`}
                    fileName={fileName}
                    url={`${
                      window.location.protocol
                    }//${endpointUrl}/getDocument?case_id=${caseId}&expired_url=${encodeURIComponent(url)}`}
                    maxWidth={250}
                  />
                ))}
              </div>
            </div>
          )}
          {businessOwnershipDocuments?.length > 0 && (
            <div className="flex flex-col">
              <span className="font-semibold">Business Ownership Documents: </span>
              <div>
                {businessOwnershipDocuments.map(({ file_name: fileName, url }: { file_name: string; url: string }) => (
                  <DocumentValue
                    key={`${fileName}${url}`}
                    fileName={fileName}
                    url={`${
                      window.location.protocol
                    }//${endpointUrl}/getDocument?case_id=${caseId}&expired_url=${encodeURIComponent(url)}`}
                    maxWidth={250}
                  />
                ))}
              </div>
            </div>
          )}
          {proofOfAddressDocuments?.length > 0 && (
            <div className="flex flex-col">
              <span className="font-semibold">Proof of Address Documents: </span>
              <div>
                {proofOfAddressDocuments.map(({ file_name: fileName, url }: { file_name: string; url: string }) => (
                  <DocumentValue
                    key={`${fileName}${url}`}
                    fileName={fileName}
                    url={`${
                      window.location.protocol
                    }//${endpointUrl}/getDocument?case_id=${caseId}&expired_url=${encodeURIComponent(url)}`}
                    maxWidth={250}
                  />
                ))}
              </div>
            </div>
          )}
          {promoMarketingDocuments?.length > 0 && (
            <div className="flex flex-col">
              <span className="font-semibold">Promo and Marketing Documents: </span>
              <div>
                {promoMarketingDocuments.map(({ file_name: fileName, url }: { file_name: string; url: string }) => (
                  <DocumentValue
                    key={`${fileName}${url}`}
                    fileName={fileName}
                    url={`${
                      window.location.protocol
                    }//${endpointUrl}/getDocument?case_id=${caseId}&expired_url=${encodeURIComponent(url)}`}
                    maxWidth={250}
                  />
                ))}
              </div>
            </div>
          )}
          {einDocuments?.length > 0 && (
            <div className="flex flex-col">
              <span className="font-semibold">EIN Documents: </span>
              <div>
                {einDocuments.map(({ file_name: fileName, url }: { file_name: string; url: string }) => (
                  <DocumentValue
                    key={`${fileName}${url}`}
                    fileName={fileName}
                    url={`${
                      window.location.protocol
                    }//${endpointUrl}/getDocument?case_id=${caseId}&expired_url=${encodeURIComponent(url)}`}
                    maxWidth={250}
                  />
                ))}
              </div>
            </div>
          )}
          {sourceOfFundsDocuments?.length > 0 && (
            <div className="flex flex-col">
              <span className="font-semibold">Source of Funds Documents: </span>
              <div>
                {sourceOfFundsDocuments.map(({ file_name: fileName, url }: { file_name: string; url: string }) => (
                  <DocumentValue
                    key={`${fileName}${url}`}
                    fileName={fileName}
                    url={`${
                      window.location.protocol
                    }//${endpointUrl}/getDocument?case_id=${caseId}&expired_url=${encodeURIComponent(url)}`}
                    maxWidth={250}
                  />
                ))}
              </div>
            </div>
          )}
          {cannabisLicenseDocuments?.length > 0 && (
            <div className="flex flex-col">
              <span className="font-semibold">Cannabis License Documents: </span>
              <div>
                {cannabisLicenseDocuments.map(({ file_name: fileName, url }: { file_name: string; url: string }) => (
                  <DocumentValue
                    key={`${fileName}${url}`}
                    fileName={fileName}
                    url={`${
                      window.location.protocol
                    }//${endpointUrl}/getDocument?case_id=${caseId}&expired_url=${encodeURIComponent(url)}`}
                    maxWidth={250}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      {individuals &&
        individuals.map((individual: any) => (
          <IndividualSelfAttestedDataCard
            key={individual.id}
            caseId={individual.id}
            endpointUrl={endpointUrl}
            isParchaUser={isParchaUser}
            level={level}
            payload={individual.self_attested_data}
          />
        ))}
    </>
  );
}
