import { useState } from "react";
import { AdverseMediaHit as AdverseMediaHitType } from "@/types";
import { Tooltip } from "@mui/material";
import Alert from "./Alert";
import { BusinessRounded, CalendarToday, LocationOnRounded, Person } from "@mui/icons-material";
import { format } from "date-fns";
import _ from "lodash";
import { twJoin } from "tailwind-merge";

const INITIAL_TOPICS_COUNT = 5;

const AdverseMediaHit = ({
  adverseMediaHit,
  level,
}: {
  adverseMediaHit: AdverseMediaHitType;
  level: "failure" | "success" | "caution" | "normal";
}) => {
  if (!adverseMediaHit.source_name && !adverseMediaHit.description && !adverseMediaHit.title) {
    return null;
  }
  const articleMetadata = adverseMediaHit.article_metadata;

  const eventDay = articleMetadata?.when?.event_day || articleMetadata?.when?.day;
  const eventMonth = articleMetadata?.when?.event_month || articleMetadata?.when?.month;
  const eventYear = articleMetadata?.when?.event_year || articleMetadata?.when?.year;

  const publicationDay = articleMetadata?.when?.article_day;
  const publicationMonth = articleMetadata?.when?.article_month;
  const publicationYear = articleMetadata?.when?.article_year;

  const [showAllTopics, setShowAllTopics] = useState(false);

  return (
    <div
      className={twJoin(
        "w-full flex flex-col border rounded-lg p-5 gap-2",
        level === "success" ? "border-green-500" : level === "failure" ? "border-red-600" : "border-slate-300",
      )}
    >
      <div className="flex w-full flex-wrap grow flex-1">
        <div className="flex grow flex-col gap-2 flex-1">
          <div className="flex items-center gap-2.5 text-xs flex-1 flex-wrap break-words overflow-wrap">
            {adverseMediaHit.source_icon ? (
              <img
                className="w-4 h-4 object-scale-down"
                src={adverseMediaHit.source_icon}
                onError={({ currentTarget }) => (currentTarget.style.display = "none")}
              />
            ) : null}
            {adverseMediaHit.source_name ? <p>{adverseMediaHit.source_name}</p> : null}
          </div>
          {adverseMediaHit.title ? (
            <div>
              <a
                className="inline text-brand-purple text-md hover:underline line-clamp-2 align-middle break-words"
                target="_blank"
                rel="noreferrer"
                href={
                  adverseMediaHit.source_url?.startsWith("http")
                    ? adverseMediaHit.source_url
                    : `https://${adverseMediaHit.source_url}`
                }
              >
                {adverseMediaHit.title}
              </a>
              {publicationDay || publicationMonth || publicationYear ? (
                <span className="text-slate-500 text-xs leading-5 align-middle">
                  {" "}
                  - {publicationMonth ? format(new Date(2024, publicationMonth - 1, 1), "LLLL") : ""} {publicationDay}
                  {publicationYear ? (publicationMonth ? `, ${publicationYear}` : publicationYear) : ""}
                </span>
              ) : null}
            </div>
          ) : null}
          {adverseMediaHit.description ? (
            <p className="text-slate-500 text-xs line-clamp-3 break-words">{adverseMediaHit.description}</p>
          ) : null}
          {articleMetadata?.topics?.length ? (
            <div className="flex flex-wrap gap-x-1 gap-y-2 text-xs my-4">
              {showAllTopics
                ? articleMetadata.topics.map((topic: string) => (
                    <div
                      key={topic}
                      className="flex justify-center items-center border border-slate-300 font-medium text-slate-600 rounded-full px-2 py-1 bg-slate-100"
                    >
                      <Tooltip title={topic} placement="top">
                        <span className="line-clamp-1 break-words">{topic}</span>
                      </Tooltip>
                    </div>
                  ))
                : articleMetadata.topics.slice(0, INITIAL_TOPICS_COUNT).map((topic) => (
                    <div
                      key={topic}
                      className="flex justify-center items-center border border-slate-300 font-medium text-slate-600 rounded-full px-2 py-1 bg-slate-100"
                    >
                      <Tooltip title={topic} placement="top">
                        <span className="line-clamp-1 break-words">{topic}</span>
                      </Tooltip>
                    </div>
                  ))}

              {articleMetadata.topics.length > INITIAL_TOPICS_COUNT ? (
                <button
                  className="flex justify-center items-center text-brand-purple ml-2"
                  onClick={() => {
                    setShowAllTopics(!showAllTopics);
                  }}
                >
                  {showAllTopics
                    ? `Hide ${articleMetadata.topics.length - INITIAL_TOPICS_COUNT} record${
                        articleMetadata.topics.length - INITIAL_TOPICS_COUNT === 1 ? "" : "s"
                      }`
                    : `Show ${articleMetadata.topics.length - INITIAL_TOPICS_COUNT} more...`}
                </button>
              ) : null}
            </div>
          ) : null}
          <dl className="flex flex-col gap-y-2 text-xs w-full flex-wrap overflow-hidden">
            {articleMetadata?.who &&
              ((typeof articleMetadata.who === "string" && articleMetadata.who.length > 0) ||
                (typeof articleMetadata.who === "object" &&
                  articleMetadata.who.perpetrators &&
                  articleMetadata.who.perpetrators.length > 0 &&
                  !_.every(articleMetadata.who.perpetrators, (perpetrator) =>
                    Object.values(perpetrator).every((v) => v === null || v === ""),
                  ))) && (
                <div className="grid grid-cols-[5ch_minmax(0,_1fr)] gap-x-3 break-words">
                  <dt className="text-right">
                    {adverseMediaHit.type?.toLowerCase().startsWith("kyb") ? (
                      <BusinessRounded sx={{ fontSize: "1rem" }} className="text-slate-400" />
                    ) : (
                      <Person sx={{ fontSize: "1rem" }} className="text-slate-400" />
                    )}
                  </dt>
                  <dd className="text-slate-700 leading-normal break-words">
                    {typeof articleMetadata.who === "string"
                      ? articleMetadata.who
                      : articleMetadata.who.perpetrators
                          .map(
                            (perpetrator) =>
                              `${
                                perpetrator.name
                                  ? `${perpetrator.name}`
                                  : perpetrator.business_name
                                    ? perpetrator.business_name
                                    : ""
                              }${perpetrator.age !== null ? `, ${perpetrator.age} years old` : ""}${
                                perpetrator.profession ? `, ${perpetrator.profession}` : ""
                              }`,
                          )
                          .join("; ")}
                  </dd>
                </div>
              )}
            {(eventMonth || eventDay || eventYear) && (
              <div className="grid grid-cols-[5ch_minmax(0,_1fr)] gap-x-3 break-words">
                <dt className="text-right">
                  <CalendarToday sx={{ fontSize: "1rem" }} className="text-slate-400" />
                </dt>
                <dd className="text-slate-700 leading-normal break-words">
                  {eventMonth ? format(new Date(2023, eventMonth - 1, 1), "LLLL") : ""} {eventDay}
                  {eventYear ? (eventMonth ? `, ${eventYear}` : eventYear) : ""}
                </dd>
              </div>
            )}
            {articleMetadata?.where && (
              <div className="grid grid-cols-[5ch_minmax(0,_1fr)] gap-x-3 items-center break-words">
                <dt className="text-right">
                  <LocationOnRounded sx={{ fontSize: "1rem" }} className="text-slate-400" />
                </dt>
                <dd className="text-slate-700 leading-normal break-words">{articleMetadata.where}</dd>
              </div>
            )}
            {articleMetadata?.what && (
              <div className="grid grid-cols-[5ch_minmax(0,_1fr)] gap-x-3 break-words">
                <dt className="text-right">
                  <span className="font-semibold text-slate-500">What:</span>
                </dt>
                <dd className="text-slate-700 break-words">{articleMetadata.what}</dd>
              </div>
            )}

            {articleMetadata?.why && (
              <div className="grid grid-cols-[5ch_minmax(0,_1fr)] gap-x-3 break-words">
                <dt className="text-right">
                  <span className="font-semibold text-slate-500">Why:</span>
                </dt>
                <dd className="text-slate-700 break-words">{articleMetadata.why}</dd>
              </div>
            )}
            {articleMetadata?.how && (
              <div className="grid grid-cols-[5ch_minmax(0,_1fr)] gap-x-3 break-words">
                <dt className="text-right">
                  <span className="font-semibold text-slate-500">How:</span>
                </dt>
                <dd className="text-slate-700 break-words">{articleMetadata.how}</dd>
              </div>
            )}
          </dl>
        </div>
      </div>
      {adverseMediaHit.reason && <Alert level="failure" message={adverseMediaHit.reason} />}
    </div>
  );
};

export default AdverseMediaHit;
