import { twJoin } from "tailwind-merge";
import { format } from "date-fns";
import OneRowInfoValue from "./OneRowInfoValue";
import TwoRowInfoValue from "./TwoRowInfoValue";
import AddressValue, { AddressValueProps } from "./AddressValue";
import WrappedValue from "./WrappedValue";
import WebsiteValue from "./WebsiteValue";

type OpenCorporatesCardProps = {
  level: "success" | "failure" | "caution" | "normal";
  name: string;
  source: string;
  incorporationDate: string | null;
  inactive: boolean | null;
  address: AddressValueProps;
  openCorporatesUrl: string | null;
  registryUrl: string | null;
};

const OpenCorporatesCard = ({
  name,
  source,
  incorporationDate,
  inactive,
  address,
  openCorporatesUrl,
  registryUrl,
  level,
}: OpenCorporatesCardProps) => {
  const statusLabel = inactive === null ? "Unknown" : inactive ? "Inactive" : "Active";
  const statusLevel = inactive === null ? "caution" : inactive ? "failure" : "success";
  return (
    <div
      className={twJoin(
        "w-full h-auto flex flex-col mt-2 gap-2 border rounded-lg text-sm font-slate-900",
        level === "success" ? "border-green-300" : level === "failure" ? "border-red-300" : "border-slate-300",
      )}
    >
      <div
        className={twJoin(
          "flex justify-between rounded-t-md p-4 border-b",
          level === "success"
            ? "bg-[#DCFCE7] border-b-green-300"
            : level === "failure"
              ? "bg-red-100 border-b-red-300"
              : "bg-slate-100 border-slate-300",
        )}
      >
        <div className="flex flex-col justify-center">{name && <span className="font-medium">{name}</span>}</div>
      </div>
      <div className="grid grid-cols-2 grid-rows-1 gap-y-4 gap-x-4 py-3 px-4">
        <OneRowInfoValue
          label="Incorporation Date"
          value={
            incorporationDate ? format(new Date(incorporationDate?.replace(/-/g, "/")), "MMM. d, yyyy") : "Not Provided"
          }
        />
        <OneRowInfoValue label="Current Status" value={<WrappedValue level={statusLevel} text={statusLabel} />} />

        {/** Take the full width to cover fo */}
        <div className="col-span-2">
          <TwoRowInfoValue
            label="Address"
            value={
              !Object.entries(address).every(([k, v]) => k === "type" || v === null || v === "") ? (
                <AddressValue {...address} />
              ) : (
                "Not Provided"
              )
            }
          />
        </div>

        <TwoRowInfoValue
          label="Registry URL"
          value={<WebsiteValue url={registryUrl} label={registryUrl && source ? source : "Not Provided"} />}
        />
        <TwoRowInfoValue label="Opencorporates URL" value={<WebsiteValue url={openCorporatesUrl} />} />
      </div>
    </div>
  );
};

export default OpenCorporatesCard;
