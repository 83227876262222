// @ts-nocheck
import _ from "lodash";
import UserContext from "@/contexts/UserContext";
import { useParchaApi } from "@/hooks/useParchaApi";
import { useCallback, useContext, useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { AgentsDropdownMenu } from "./V2_Home_Page/Queue";
import { Agent, Endpoint } from "@/types";
import { MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import { INCORPORATION_DOCUMENT_CHECK_ID, PROOF_OF_ADDRESS_CHECK_ID, EIN_DOCUMENT_CHECK_ID } from "@/constants/vars";

const DocumentRequestsMainView = () => {
  const parchaApi = useParchaApi();
  const { agentKey } = useParams();
  const [agent, setAgent] = useState<Agent | null>(null);
  const userContext = useContext(UserContext);
  const { user, endpoints } = userContext;
  const navigate = useNavigate();

  const [selectedEnvType, setSelectedEnvType] = useState<string>("demo");
  const [selectedCustomer, setSelectedCustomer] = useState<string>("");
  const [filteredEndpoints, setFilteredEndpoints] = useState<typeof endpoints>([]);
  const [availableEnvTypes, setAvailableEnvTypes] = useState<string[]>([]);
  const [availableCustomers, setAvailableCustomers] = useState<string[]>([]);
  const [selectedEndpoint, setSelectedEndpoint] = useState<Endpoint | null>(null);

  const isUserParcha = user?.userTenants?.some((tenant) => tenant.tenantName.includes("Parcha"));
  const getEnvTypeDisplayName = (envType: string) => {
    switch (envType) {
      case "demo":
        return "Fastbank Demo Agents";
      case "prod":
        return "Production Agents";
      case "pilot":
        return "Pilot Agents";
      case "dev":
        return "Internal Dev Endpoints";
      default:
        return envType;
    }
  };

  const updateAvailableOptions = useCallback(() => {
    if (!endpoints) {
      setAvailableEnvTypes([]);
      setAvailableCustomers([]);
      setFilteredEndpoints([]);
      setSelectedEndpoint(null);
      return;
    }

    if (isUserParcha) {
      // Existing logic for Parcha users
      const envTypes = [...new Set(endpoints.map((e) => e.envType))];

      // Sort envTypes to ensure "demo" is first
      const sortedEnvTypes = envTypes.sort((a, b) => {
        if (a === "demo") return -1;
        if (b === "demo") return 1;
        return a.localeCompare(b);
      });

      setAvailableEnvTypes(sortedEnvTypes);

      if (sortedEnvTypes.length === 1) {
        setSelectedEnvType(sortedEnvTypes[0]);
      }

      let filtered = endpoints.filter((e) => e.envType === selectedEnvType);

      const customers = [
        ...new Set(filtered.flatMap((e) => e.tenants.map((t) => t.name)).filter((name) => name !== "Parcha")),
      ];
      setAvailableCustomers(customers);

      if (customers.length === 1) {
        setSelectedCustomer(customers[0]);
      } else if (customers.length === 0) {
        setSelectedCustomer("");
      }

      if (selectedCustomer) {
        filtered = filtered.filter((e) => e.tenants.some((t) => t.name === selectedCustomer));
      }

      setFilteredEndpoints(filtered);
    } else {
      // For non-Parcha users, show all available endpoints without duplicates
      const uniqueEndpoints = endpoints.reduce((acc, current) => {
        const x = acc.find((item) => item.agentKey === current.agentKey);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);

      // Sort endpoints, placing deprecated ones at the end
      const sortedEndpoints = uniqueEndpoints.sort((a, b) => {
        if (a.agentName.startsWith("(DEPRECATED)") && !b.agentName.startsWith("(DEPRECATED)")) {
          return 1;
        }
        if (!a.agentName.startsWith("(DEPRECATED)") && b.agentName.startsWith("(DEPRECATED)")) {
          return -1;
        }
        return a.agentName.localeCompare(b.agentName);
      });

      setFilteredEndpoints(sortedEndpoints);
    }
  }, [endpoints, selectedEnvType, selectedCustomer, isUserParcha]);

  useEffect(() => {
    updateAvailableOptions();
  }, [updateAvailableOptions]);

  // Update selected endpoint and navigate when filteredEndpoints change
  useEffect(() => {
    if (filteredEndpoints.length > 0) {
      const newSelectedEndpoint = filteredEndpoints.find((e) => e.agentKey === agentKey) || filteredEndpoints[0];
      setSelectedEndpoint(newSelectedEndpoint);
      if (newSelectedEndpoint.agentKey !== agentKey) {
        navigate(`/document-requests/${newSelectedEndpoint.agentKey}`, { replace: true });
      }
    } else {
      setSelectedEndpoint(null);
    }
  }, [filteredEndpoints, agentKey, navigate]);

  // Initialize selected envType and customer based on current agent
  useEffect(() => {
    if (endpoints && agentKey) {
      const currentAgent = endpoints.find((e) => e.agentKey === agentKey);
      if (currentAgent) {
        setSelectedEnvType(currentAgent.envType);

        const customer = currentAgent.tenants.find((t) => t.name !== "Parcha")?.name;
        if (customer) setSelectedCustomer(customer);
      }
    }
  }, [endpoints, agentKey]);

  // Handle envType change
  const handleEnvTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedEnvType(event.target.value as string);
    setSelectedCustomer(""); // Reset customer selection when envType changes
  };

  // Handle customer change
  const handleCustomerChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedCustomer(event.target.value as string);
  };

  // Handle agent selection change
  const handleAgentChange = (newAgentKey: string) => {
    const newSelectedEndpoint = filteredEndpoints.find((e) => e.agentKey === newAgentKey);
    setSelectedEndpoint(newSelectedEndpoint || null);
    navigate(`/document-requests/${newAgentKey}`, { replace: true });
  };

  useEffect(() => {
    if (!agentKey && userContext && userContext.endpoints && userContext.endpoints.length > 0) {
      navigate(`/document-requests/${userContext.endpoints[0].agentKey}`);
    }
  }, [userContext?.endpoints, agentKey]);

  useEffect(() => {
    if (!agentKey || !selectedEndpoint) return;

    parchaApi.getSimpleAgent(selectedEndpoint.endpointUrl, agentKey).then((res) => {
      setAgent(res);
      userContext?.saveAgent(res);
    });
  }, [agentKey, selectedEndpoint]);

  // Flutterwave didn't have access to run jobs with the current form.
  const isFlutterwaveMCC =
    selectedEndpoint?.agentName.includes("Flutterwave MCC") || selectedEndpoint?.agentName.includes("Cashfree MCC");

  const documentValidationCheckIds = [
    INCORPORATION_DOCUMENT_CHECK_ID,
    PROOF_OF_ADDRESS_CHECK_ID,
    EIN_DOCUMENT_CHECK_ID,
  ];

  return (
    <div className="h-full">
      <div className="w-full justify-between h-[3.75rem] border border-b-slate-200 flex items-center px-4">
        <div className="flex items-center gap-x-4">
          {filteredEndpoints.length > 0 && (
            <AgentsDropdownMenu
              items={filteredEndpoints.map((agent) => ({
                label: agent.agentName,
                url: `/document-requests/${agent.agentKey}`,
              }))}
              selectedItem={{
                label: selectedEndpoint?.agentName || filteredEndpoints[0].agentName,
                url: `/document-requests/${selectedEndpoint?.agentKey || filteredEndpoints[0].agentKey}`,
              }}
              onChange={handleAgentChange}
            />
          )}
        </div>
        {isUserParcha && (
          <div className="flex items-center gap-x-2">
            {availableEnvTypes.length > 1 && (
              <FormControl size="small" style={{ minWidth: "200px" }}>
                <InputLabel id="env-type-label">Agent Type</InputLabel>
                <Select
                  labelId="env-type-label"
                  value={selectedEnvType}
                  label="Agent Type"
                  onChange={handleEnvTypeChange}
                >
                  {availableEnvTypes.map((envType) => (
                    <MenuItem key={envType} value={envType}>
                      {getEnvTypeDisplayName(envType)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {availableCustomers.length > 1 && (
              <FormControl size="small" style={{ minWidth: "200px" }}>
                <InputLabel id="customer-label">Customer</InputLabel>
                <Select
                  labelId="customer-label"
                  value={selectedCustomer}
                  label="Customer"
                  onChange={handleCustomerChange}
                >
                  {availableCustomers.map((customer) => (
                    <MenuItem key={customer} value={customer}>
                      {customer}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </div>
        )}
      </div>
      <Outlet />
    </div>
  );
};

export default DocumentRequestsMainView;
