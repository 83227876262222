import ReactDOM from "react-dom/client";
// import * as Sentry from "@sentry/react";
import App from "./App";
import "./index.css";

// Sentry.init({
//   dsn: "https://c3f7389c1d4b6db423c75b10c30da34e@o4506617108561920.ingest.us.sentry.io/4507386881572864",
//   integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
//   environment: import.meta.env.ENV_TIER,
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ["localhost", /^https:\/\/parcha\.io/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(<App />);
