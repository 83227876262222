import {
  BUSINESS_OWNERSHIP_CHECK_ID,
  CANNABIS_LICENSE_CHECK_ID,
  EIN_DOCUMENT_CHECK_ID,
  INCORPORATION_DOCUMENT_CHECK_ID,
  PROOF_OF_ADDRESS_CHECK_ID,
  shimmerEffectClassNames,
  SOURCE_OF_FUNDS_CHECK_ID,
} from "@/constants/vars";
import { CancelRounded, CheckCircleRounded, PlagiarismRounded, TaskRounded } from "@mui/icons-material";
import { format } from "date-fns";
import { twJoin } from "tailwind-merge";

const DocumentList = ({
  input,
  endpointUrl,
  documentCheckResults,
  setSelectedDocumentCheck,
}: {
  input: { [key: string]: any };
  endpointUrl: string;
  documentCheckResults: { [key: string]: any }[];
  setSelectedDocumentCheck: React.SetStateAction<any>;
}) => {
  const kybDocuments = [
    ...(input.self_attested_data.incorporation_documents || []),
    ...(input.self_attested_data.proof_of_address_documents || []),
    ...(input.self_attested_data.source_of_funds_documents || []),
    ...(input.self_attested_data.business_ownership_documents || []),
    ...(input.self_attested_data.ein_documents || []),
    ...(input.self_attested_data.cannabis_license_documents || []),
  ];

  if (!documentCheckResults || documentCheckResults?.length === 0 || !kybDocuments || kybDocuments?.length === 0)
    return null;

  const atLeastOneDocumentNotComplete = documentCheckResults.some((doc) => !["error", "complete"].includes(doc.status));

  return (
    <div className="w-full flex flex-col gap-y-2">
      <div className="flex items-center gap-x-2">
        <PlagiarismRounded sx={{ fontSize: "1.25rem" }} className="text-slate-700" />
        <span className={twJoin("text-xl font-semibold", atLeastOneDocumentNotComplete ? shimmerEffectClassNames : "")}>
          Documents
        </span>
      </div>
      <div className="grid grid-cols-3 gap-3 wrap">
        {documentCheckResults.map((docCheckResult, index) => (
          <DocumentCard
            key={index}
            caseId={input.id}
            endpointUrl={endpointUrl}
            document={docCheckResult}
            setSelectedDocumentCheck={setSelectedDocumentCheck}
            selfAttestedData={input.self_attested_data}
          />
        ))}
      </div>
    </div>
  );
};

function DocumentCard({
  caseId,
  endpointUrl,
  document,
  setSelectedDocumentCheck,
  selfAttestedData,
}: {
  caseId: string;
  endpointUrl: string;
  document: { [key: string]: any };
  setSelectedDocumentCheck: (selectedDocument: any) => void;
  selfAttestedData: { [key: string]: any };
}) {
  if (!document || !document.verified_data) return null;

  const handleSelection = (selectedDocument: any) => {
    let values: any[] = [];

    const checkResult = document.check_result;

    switch (document.command_id) {
      case INCORPORATION_DOCUMENT_CHECK_ID:
        values = [
          {
            value: format(new Date(checkResult.verified_incorporation_date?.replace(/-/g, "/")), "MMMM dd, yyyy"),
            label: "Incorporation Date",
            type: "text",
          },
          {
            value: checkResult.verified_registration_number,
            label: "Registration Number",
            type: "wrapped",
          },
          {
            value: checkResult.verified_business_address,
            label: "Address of Incorporation",
            type: "address",
          },
          {
            value: checkResult.verified_business_activity,
            label: "Business Activity",
            type: "text",
          },
          {
            value: selectedDocument.summary,
            label: "Summary",
            type: "text",
          },
        ];
        break;
      case BUSINESS_OWNERSHIP_CHECK_ID:
        values = [
          {
            value: checkResult.verified_business_owners,
            label: "Verified Business Owners",
            type: "owner_info",
          },
          {
            value: checkResult.unverified_business_owners,
            label: "Unverified Business Owners",
            type: "owner_info",
          },
          {
            value: selectedDocument.summary,
            label: "Summary",
            type: "text",
          },
        ];
        break;
      case PROOF_OF_ADDRESS_CHECK_ID:
        if (checkResult.verified_address) {
          values.push({
            value: checkResult.verified_address,
            label: "Address",
            type: "address",
          });
        } else {
          values.push({
            value: "Address not verified",
            label: "Address",
            type: "text",
          });
        }
        values.push({
          value: selectedDocument.summary,
          label: "Summary",
          type: "text",
        });
        break;
      case EIN_DOCUMENT_CHECK_ID:
        values.push({
          value: checkResult.verified_business_name,
          label: "Business Name",
          type: "text",
        });
        values.push({
          value: checkResult.verified_ein,
          label: "EIN",
          type: "wrapped",
        });
        values.push({
          value: checkResult.verified_address,
          label: "Address of Operation",
          type: "address",
        });
        values.push({
          value: selectedDocument.summary,
          label: "Summary",
          type: "text",
        });
        break;
      case SOURCE_OF_FUNDS_CHECK_ID:
        values.push({
          value: checkResult.verified_amount || "Not verified",
          label: "Amount",
          type: "text",
        });
        values.push({
          value: selectedDocument.summary,
          label: "Summary",
          type: "text",
        });
        break;
      default:
        break;
    }
    setSelectedDocumentCheck({
      check_result: checkResult,
      command_id: document.command_id,
      command_name: document.command_name,
      agent_instance_id: document.agent_instance_id,
      documentUrl: selectedDocument.url,
      documentTitle: selectedDocument.file_name,
    });
  };

  const statusIcon = {
    pass: <CheckCircleRounded sx={{ fontSize: "1rem" }} className="text-green-600" />,
    fail: <CancelRounded sx={{ fontSize: "1rem" }} className="text-red-600" />,
  };

  const getDocuments = () => {
    let docs: any[] = [];

    switch (document.command_id) {
      case INCORPORATION_DOCUMENT_CHECK_ID:
        docs = selfAttestedData.incorporation_documents;
        break;
      case BUSINESS_OWNERSHIP_CHECK_ID:
        docs = selfAttestedData.business_ownership_documents;
        break;
      case PROOF_OF_ADDRESS_CHECK_ID:
        docs = selfAttestedData.proof_of_address_documents;
        break;
      case EIN_DOCUMENT_CHECK_ID:
        docs = selfAttestedData.ein_documents;
        break;
      case CANNABIS_LICENSE_CHECK_ID:
        docs = selfAttestedData.cannabis_license_documents;
        break;
      case SOURCE_OF_FUNDS_CHECK_ID:
        docs = selfAttestedData.source_of_funds_documents;
        break;
      default:
        break;
    }

    return docs?.map((doc, index) => {
      const document = doc.document ? doc.document : doc;
      return (
        <button
          key={index}
          onClick={() => handleSelection(document)}
          className="text-left w-fit text-brand-purple hover:underline"
        >
          <span>
            {document.file_name.length > 16
              ? `${document.file_name.slice(0, 16)}...${document.file_name.slice(-16)}`
              : document?.file_name}
          </span>
        </button>
      );
    });
  };

  const getProcessedAt = () => {
    return document.created_at ? format(new Date(`${document.created_at}Z`), "MMMM d, yyyy") : null;
  };

  return (
    <div
      className={`h-fit rounded-lg p-3 text-slate-900 border flex flex-col gap-y-2 text-xs overflow-hidden whitespace-nowrap text-ellipsis ${
        document.passed ? "bg-slate-50 border-slate-50" : "bg-white border border-red-700"
      }`}
    >
      <div className="flex justify-between">
        <h4 className="font-semibold text-sm text-wrap">{document.command_name?.replace("Check", "")}</h4>
        {document.passed !== null && (
          <div className="flex gap-x-1 pt-0.5">{document.passed ? statusIcon.pass : statusIcon.fail}</div>
        )}
      </div>
      {getDocuments()}
      <span className="text-slate-700 inline-flex items-center gap-x-0.5">
        <TaskRounded sx={{ fontSize: "1rem" }} className="text-slate-400" />
        Documents Processed • {getProcessedAt()}
      </span>
    </div>
  );
}

export default DocumentList;
