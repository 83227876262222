import React from "react";
import numeral from "numeral";
import instragramLogo from "../../assets/instagram_logo.png";
import { LinkRounded } from "@mui/icons-material";

interface InstagramPost {
  id: string;
  displayUrl: string;
  url: string;
}

interface InstagramWebsiteCardProps {
  logoUrl: string | null;
  latestPosts: InstagramPost[] | null;
  username: string | null;
  name: string | null;
  instagramUrl: string | null;
  externalUrl: string | null;
  biography: string | null;
  postsCount: number | null;
  followersCount: number | null;
  followCount: number | null;
  isVerified: boolean | null;
  businessCategoryName: string | null;
}

const InstagramWebsiteCard: React.FC<InstagramWebsiteCardProps> = ({
  logoUrl,
  latestPosts,
  username,
  name,
  biography,
  postsCount,
  followersCount,
  followCount,
  isVerified,
  instagramUrl,
  externalUrl,
  businessCategoryName,
}) => {
  const availablePosts = latestPosts?.filter((post) => {
    return !post.displayUrl?.includes("instagram");
  });

  return (
    <div className="min-w-full max-w-full md:max-w-[600px] flex flex-col justify-center rounded-xl text-slate-900 text-xs bg-white border border-slate-200 overflow-hidden">
      <div className="flex flex-col md:flex-row md:items-center gap-x-10 md:justify-between px-4 py-2 md:px-[2.5rem] md:py-[1.25rem]">
        {logoUrl && (
          <div className="grow-0 md:max-w-[7.5rem] rounded-full flex items-center justify-center w-full">
            <img src={logoUrl} alt="Profile" className="object-cover rounded-full" />
          </div>
        )}
        <div className="flex flex-col gap-y-2 md:gap-y-3 min-w-0">
          <div className="flex flex-wrap items-center gap-x-1">
            <a
              href={instagramUrl ?? undefined}
              target="_blank"
              rel="noreferrer"
              className="text-base font-bold hover:underline hover:text-brand-purple"
            >
              @{username}
            </a>
            {isVerified && (
              <svg className="w-[20px] h-[20px] text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                <path d="M10 2C5.59 2 2 5.59 2 10s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zm-0.83 12l-3.17-3.17 1.13-1.13 2.04 2.04 4.46-4.46 1.13 1.13L9.17 14z" />
              </svg>
            )}
          </div>
          <div className="flex flex-wrap justify-between items-center font-semibold text-sm gap-y-1">
            <div className="md:text-center">
              {postsCount !== null ? `${numeral(postsCount).format("0,0")} posts` : "-"}
            </div>
            <div className="md:text-center">
              {followersCount !== null ? `${numeral(followersCount).format("0.0a")} followers` : "-"}
            </div>
            <div className="md:text-center">
              {followCount !== null
                ? `${followCount > 1000 ? numeral(followCount).format("0.0a") : followCount} following`
                : "-"}
            </div>
          </div>
          <div className="flex flex-col min-w-0">
            {name && <div className="text-slate-900 font-bold text-sm">{name}</div>}
            {businessCategoryName && <div className="text-slate-500">{businessCategoryName}</div>}
            {biography && (
              <p className="text-slate-900 line-clamp-2 text-wrap break-words text-sm text-ellipsis whitespace-wrap overflow-hidden">
                {biography}
              </p>
            )}
            {externalUrl && (
              <div className="flex items-center gap-x-1 text-slate-500 grow-0 w-full shrink-1 overflow-hidden">
                <LinkRounded sx={{ fontSize: "1rem" }} />
                <a
                  href={externalUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="hover:underline hover:text-brand-purple text-ellipsis whitespace-nowrap overflow-hidden"
                >
                  {externalUrl}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
      {availablePosts && (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-0.5">
          {availablePosts.slice(0, 3).map((post) => {
            if (!post.url || !post.displayUrl) return null;

            return (
              <a href={post.url} key={post.id} target="_blank" rel="noreferrer">
                <img src={post.displayUrl} className="object-cover aspect-square" />
              </a>
            );
          })}
        </div>
      )}
      <div className="flex items-center justify-center gap-x-2 py-2 bg-slate-100">
        <img src={instragramLogo} className="object-cover w-4 h-4" />
        <span className="font-semibold">Instagram</span>
      </div>
    </div>
  );
};

export default InstagramWebsiteCard;
