import { Fragment, useState } from "react";
import { Autocomplete, LoadScript } from "@react-google-maps/api";
import InputWithOverlappingLabel from "../TailwindComponents/InputWithOverlappingLabel";
import { Menu, Transition } from "@headlessui/react";
import { ExpandMoreRounded } from "@mui/icons-material";
import { twMerge } from "tailwind-merge";

interface AddressComponents {
  street_1?: string;
  street_2?: string;
  city?: string;
  state?: string;
  country_code?: string;
  postal_code?: string;
}

interface ProofOfAddressFormProps {
  formData: {
    business_name: string | null;
    address_of_operation: AddressComponents | null;
    validity_period: number | null;
  };
  setFormData: React.Dispatch<React.SetStateAction<any>>;
  formErrors: string[];
}

const VALIDITY_PERIODS = [
  { label: "Last 30 Days", value: 30 },
  { label: "Last 60 Days", value: 60 },
  { label: "Last 90 Days", value: 90 },
  { label: "Last 120 Days", value: 120 },
  { label: "Last 180 Days", value: 180 },
];

const ProofOfAddressForm: React.FC<ProofOfAddressFormProps> = ({ formData, setFormData, formErrors }) => {
  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);
  const [address, setAddress] = useState<string>("");
  const libraries: "places"[] = ["places"];

  const onLoad = (autocomplete: google.maps.places.Autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const onPlaceSelectionChange = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      const addressComponents: AddressComponents = {};
      // Loop through the address components of the selected place
      place.address_components?.forEach((component) => {
        const types = component.types;
        if (types.includes("street_number")) {
          addressComponents.street_1 = `${component.long_name}${
            addressComponents.street_1 ? ` ${addressComponents.street_1}` : ""
          }`;
        }
        if (types.includes("route")) {
          addressComponents.street_1 = `${addressComponents.street_1 || ""} ${component.long_name}`;
        }
        if (types.includes("subpremise")) {
          addressComponents.street_2 = component.long_name;
        }
        if (types.includes("locality")) {
          addressComponents.city = component.long_name;
        }
        if (types.includes("administrative_area_level_1")) {
          addressComponents.state = component.short_name;
        }
        if (types.includes("country")) {
          addressComponents.country_code = component.short_name;
        }
        if (types.includes("postal_code")) {
          addressComponents.postal_code = component.long_name;
        }
      });

      setFormData({ ...formData, address_of_operation: addressComponents });
      setAddress(
        `${addressComponents.street_1 || ""}${addressComponents.street_2 ? ` ${addressComponents.street_2}` : ""} ${
          addressComponents.city ? `${addressComponents.city}, ` : ""
        }${addressComponents.state ? `${addressComponents.state} ` : ""}${
          addressComponents.postal_code ? `${addressComponents.postal_code}` : ""
        }`,
      );
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  return (
    <LoadScript googleMapsApiKey={import.meta.env.VITE_GOOGLE_MAPS_KEY} libraries={libraries}>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="grid grid-cols-4 gap-3">
          <InputWithOverlappingLabel
            required
            fieldName="business_name"
            label="Business Name"
            id="business_name"
            name="business_name"
            onChangeHandler={(e) => setFormData({ ...formData, business_name: e.target.value })}
            className="col-span-3"
            value={formData.business_name || ""}
          />
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button className="flex w-full gap-x-1.5 rounded-md bg-white p-2.5 text-xs md:text-md text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 hover:bg-slate-50">
                <span className="flex-1 line-clamp-1 text-left">
                  {formData.validity_period
                    ? VALIDITY_PERIODS.find((period) => period.value === formData.validity_period)?.label
                    : "Any"}
                </span>
                <ExpandMoreRounded
                  sx={{ fontSize: "1rem" }}
                  className="-mr-1 h-4 w-4 text-slate-400"
                  aria-hidden="true"
                />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="h-fit w-full max-h-[70vh] overflow-y-auto absolute left-0 z-50 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-slate-300 focus:outline-none">
                {VALIDITY_PERIODS.map((period) => (
                  <Menu.Item key={period.value}>
                    <button
                      className={twMerge(
                        "w-full text-left px-4 py-2 text-sm text-slate-700 font-normal",
                        formData.validity_period === period.value ? "font-medium bg-slate-100" : "",
                      )}
                      onClick={() => setFormData({ ...formData, validity_period: period.value })}
                    >
                      {period.label}
                    </button>
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </Menu>
          <div className="col-span-full">
            <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceSelectionChange}>
              <InputWithOverlappingLabel
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }}
                value={address}
                onChangeHandler={(e) => setAddress(e.target.value)}
                label="Address"
                id="address"
                name="address"
                fieldName="address"
                className="col-span-4"
                autoComplete="off"
              />
            </Autocomplete>
          </div>
        </div>
        {formErrors.length > 0 && (
          <div className="text-red-600 text-sm mt-4">
            <span className="font-semibold">Please correct the following errors:</span>
            <ul className="list-disc list-outside ml-6">
              {formErrors.map((error, index) => (
                <li className="list-item" key={index}>
                  {error}
                </li>
              ))}
            </ul>
          </div>
        )}
      </form>
    </LoadScript>
  );
};

export default ProofOfAddressForm;
