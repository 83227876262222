import { useState } from "react";
import { format, formatDistanceStrict } from "date-fns";
import { ArrowDownwardRounded, ArrowUpwardRounded, BusinessRounded, PersonRounded } from "@mui/icons-material";
import SourceItem from "./SourceItem";
import WrappedValue from "./WrappedValue";
import { PositionEntry } from "./PEPCardV2";
import { snakeCaseToProperCase } from "@/utils";
import Alert from "./Alert";

type PEPEventCardProps = {
  pepProfile: {
    full_name: string;
    gender: string | null;
    pep_title: string;
    pep_status: string;
    pep_type: string;
    age_as_of_today: number;
    date_of_birth: string;
    associated_countries: (
      | {
          original_country_input: string;
          country_name: string;
          alpha_2_country_code: string;
          alpha_3_country_code: string;
          numeric_country_code: string;
        }
      | string
    )[];
    associated_addesses: {
      street_1: string;
      street_2: string;
      city: string;
      state: string;
      postal_code: string;
      country_code: string;
    }[];
    roles: {
      start_date: string;
      end_date: string;
      pep_type: string;
      title: string;
      status: string;
    }[];
    reference_id: string;
    aliases: string[] | null;
    identification_info: string;
    biography_info: string;
    known_associates: {
      association_type: string;
      name: string;
      entity_type: "ORGANISATION" | "INDIVIDUAL";
      category: string;
    }[];
    weblinks?:
      | {
          url: string | null;
          date: string | null;
          has_photo: boolean | null;
          scanned_website: {
            webpage_text: string | null;
            webpage_title: string | null;
            is_valid_url: boolean | null;
          };
          pep_metadata?: {
            id: string;
            matching_name_found?: boolean | null;
            matching_name?: string | null;
            summary_of_text_content?: string | null;
            source_url?: string | null;
            associated_countries?: string | null;
            professional_affiliations_found?: string | null;
          };
          metadata?: {
            id: string;
            matching_name_found?: boolean | null;
            matching_name?: string | null;
            summary_of_text_content?: string | null;
            source_url?: string | null;
            associated_countries?: string | null;
            professional_affiliations_found?: string | null;
          };
        }[]
      | null;
    profile_review: {
      pep_level: {
        level: number | null;
        reason: string | null;
      } | null;
      is_foreign: boolean;
      match_rating: {
        match: string;
        reason: string;
      } | null;
      country_match: {
        exact_match: boolean;
        partial_match: boolean;
        mismatch?: boolean;
        countries: (string | { country_name: string })[];
        explanation: string;
      };
      first_name_match: {
        exact_match: boolean;
        partial_match: boolean;
        mismatch?: boolean;
        name: string;
        explanation: string;
      };
      last_name_match: {
        exact_match: boolean;
        partial_match: boolean;
        mismatch?: boolean;
        name: string;
        explanation: string;
      };
      age_match: {
        exact_match: boolean;
        partial_match: boolean;
        mismatch?: boolean;
        age: string | number;
        explanation: string;
      };
      full_name: string;
      risk_rating: string;
    } | null;
  };
};

const PEPEventCard = ({ pepProfile }: PEPEventCardProps) => {
  const [showAllArticles, setShowAllArticles] = useState(false);

  const currentPositions = pepProfile?.roles?.filter((role) => role.end_date === null || role.end_date === "Present");
  const pastPositions = pepProfile?.roles?.filter((role) => role.end_date !== null && role.end_date !== "Present");

  const matchRating = pepProfile?.profile_review?.match_rating;
  const match = matchRating?.match;
  const reason = matchRating?.reason;

  return (
    <div className="flex flex-col gap-y-5">
      {match && reason && (
        <Alert
          level={match === "strong_match" ? "failure" : match === "partial_match" ? "partial" : "caution"}
          message={reason}
        />
      )}
      <div className="grid grid-cols-2 gap-5">
        <div className="flex flex-col gap-y-5">
          {currentPositions?.length > 0 && (
            <div className="flex flex-col gap-y-4">
              {currentPositions?.length > 0 && (
                <div className="flex flex-col gap-y-1">
                  <span className="font-semibold">Current Position{currentPositions.length > 1 ? "s" : ""}</span>
                  <div className="flex flex-col gap-y-2">
                    {currentPositions.map((position, index) => (
                      <PositionEntry
                        key={index}
                        position={position.title}
                        startDate={
                          position.start_date
                            ? format(new Date(Number(position.start_date.split("-")[0]), 0), "yyyy")
                            : null
                        }
                        endDate={
                          position.end_date
                            ? position.end_date === "Present"
                              ? "Present"
                              : format(new Date(Number(position.end_date.split("-")[0]), 0), "yyyy")
                            : null
                        }
                        duration={
                          position.start_date && position.end_date
                            ? position.end_date === "Present"
                              ? formatDistanceStrict(new Date(position.start_date), new Date())
                              : formatDistanceStrict(new Date(position.start_date), new Date(position.end_date))
                            : null
                        }
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
          {pastPositions?.length > 0 && (
            <div className="flex flex-col gap-y-4">
              {pastPositions?.length > 0 && (
                <div className="flex flex-col gap-y-1">
                  <span className="font-semibold">Past Position{pastPositions.length > 1 ? "s" : ""}</span>
                  <div className="flex flex-col gap-y-2">
                    {pastPositions.map((position, index) => (
                      <PositionEntry
                        key={index}
                        position={position.title}
                        startDate={
                          position.start_date
                            ? format(new Date(Number(position.start_date.split("-")[0]), 0), "yyyy")
                            : "Not available"
                        }
                        endDate={
                          position.end_date
                            ? position.end_date === "Present"
                              ? "Present"
                              : format(new Date(Number(position.end_date.split("-")[0]), 0), "yyyy")
                            : "Not available"
                        }
                        duration={
                          position.start_date && position.end_date
                            ? position.end_date === "Present"
                              ? formatDistanceStrict(new Date(Number(position.start_date.split("-")[0]), 0), new Date())
                              : formatDistanceStrict(
                                  new Date(Number(position.start_date.split("-")[0]), 0),
                                  new Date(Number(position.end_date.split("-")[0]), 0),
                                )
                            : null
                        }
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
          {pepProfile?.known_associates && pepProfile.known_associates?.length > 0 && (
            <div className="flex flex-col gap-y-1">
              <span className="font-semibold">Known Associations</span>
              <div className="flex flex-col gap-y-2 text-slate-700">
                {pepProfile.known_associates.map((associate) => {
                  return (
                    <div className="flex items-center gap-x-2">
                      {associate.entity_type === "ORGANISATION" ? (
                        <BusinessRounded sx={{ fontSize: "1rem" }} className="text-slate-500" />
                      ) : (
                        <PersonRounded sx={{ fontSize: "1rem" }} className="text-slate-500" />
                      )}
                      <div className="text-slate-500">
                        <span className="text-sm text-slate-700">{associate.name}</span> /{" "}
                        <span>{snakeCaseToProperCase(associate.association_type)}</span> /{" "}
                        <span>{snakeCaseToProperCase(associate.category)}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {pepProfile?.biography_info && (
            <div className="flex flex-col gap-y-1">
              <span className="font-semibold">Biography Information</span>
              <div className="flex flex-col gap-y-2 text-slate-700">
                {pepProfile.biography_info.split(".").map((bioInfo) => (
                  <span className="break-keep">{bioInfo}</span>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col gap-y-5">
          <div className="flex flex-col gap-y-1">
            <span className="font-semibold">PEP Metadata</span>
            <div className="flex gap-x-2 items-center">
              {pepProfile?.profile_review?.is_foreign && (
                <WrappedValue text="Foreign" level="normal" className="rounded-full" />
              )}
              {pepProfile?.profile_review?.pep_level && (
                <WrappedValue
                  text={`Level ${pepProfile?.profile_review?.pep_level.level}`}
                  level={
                    (pepProfile?.profile_review?.pep_level.level ?? 0) < 2
                      ? "failure"
                      : (pepProfile?.profile_review?.pep_level.level ?? 0) < 4
                        ? "caution"
                        : "success"
                  }
                  className="rounded-full"
                />
              )}
              {pepProfile?.profile_review?.risk_rating && (
                <WrappedValue
                  text={`${pepProfile?.profile_review?.risk_rating
                    .replace("_", " ")
                    .toLowerCase()
                    .replace(/\b\w/g, (char) => char.toUpperCase())}`}
                  level={
                    pepProfile?.profile_review?.risk_rating === "high_risk"
                      ? "failure"
                      : pepProfile?.profile_review?.risk_rating === "medium_risk"
                        ? "caution"
                        : pepProfile?.profile_review?.risk_rating === "low_risk"
                          ? "success"
                          : pepProfile?.profile_review?.risk_rating === "no_risk"
                            ? "normal"
                            : "normal"
                  }
                  className="rounded-full"
                />
              )}
            </div>
          </div>
          {pepProfile?.aliases && pepProfile.aliases.length > 0 && (
            <div className="flex flex-col gap-y-1">
              <span className="font-semibold">Aliases</span>
              <div className="flex gap-x-2 items-center text-slate-700">{pepProfile.aliases.join(", ")}</div>
            </div>
          )}
          {pepProfile?.gender && (
            <div className="flex flex-col gap-y-1">
              <span className="font-semibold">Gender</span>
              <div className="flex gap-x-2 items-center text-slate-700">{pepProfile.gender}</div>
            </div>
          )}
          {pepProfile?.associated_countries?.length > 0 && (
            <div className="flex flex-col gap-y-1">
              <span className="font-semibold">Related Locations</span>
              <div className="flex gap-x-2 items-center text-slate-700">
                {pepProfile.associated_countries
                  .map((country) =>
                    typeof country === "object" && country.country_name ? country.country_name : country,
                  )
                  .join(", ")}
              </div>
            </div>
          )}
          {pepProfile?.identification_info && (
            <div className="flex flex-col gap-y-1">
              <span className="font-semibold">Identification Information</span>
              <div className="flex flex-col gap-y-2 text-slate-700">
                {pepProfile.identification_info.split(".").map((idInfo) => (
                  <span>{idInfo}</span>
                ))}
              </div>
            </div>
          )}
          {pepProfile?.reference_id && (
            <div className="flex flex-col gap-y-1">
              <span className="font-semibold">World Check ID</span>
              <div className="flex flex-col gap-y-2 text-slate-700">{pepProfile.reference_id.split("_").at(-1)}</div>
            </div>
          )}
        </div>
      </div>
      <div className="w-full flex flex-col gap-y-4">
        {showAllArticles && (
          <div className="flex flex-col gap-y-3">
            {pepProfile?.weblinks &&
              pepProfile?.weblinks?.length > 0 &&
              pepProfile?.weblinks?.map((weblink, index) => (
                <SourceItem
                  key={index}
                  url={weblink.url}
                  sourceName={weblink.url ? new URL(weblink.url).hostname : ""}
                  hasPhoto={weblink.has_photo}
                  text={weblink.scanned_website?.webpage_text}
                  title={weblink.scanned_website?.webpage_title}
                  summary={weblink?.metadata?.summary_of_text_content}
                  wasRemoved={!weblink.scanned_website?.is_valid_url}
                  who={weblink?.metadata?.matching_name}
                  when={weblink.date}
                  where={weblink?.metadata?.associated_countries}
                />
              ))}
          </div>
        )}
        {pepProfile?.weblinks && pepProfile?.weblinks?.length && pepProfile?.weblinks?.length > 0 ? (
          <button
            type="button"
            disabled={!pepProfile.weblinks?.length}
            className="box-border inline-flex items-center gap-x-1.5 rounded-lg px-3 py-2 w-full text-center justify-center border border-solid border-slate-300"
            onClick={() => setShowAllArticles(!showAllArticles)}
          >
            {/** Here we need to calculate the number of articles related to this Event */}
            {showAllArticles ? (
              <>
                {`Hide ${pepProfile.weblinks?.length ?? 0} Source${pepProfile.weblinks?.length === 1 ? "" : "s"}`}
                <ArrowUpwardRounded sx={{ fontSize: "1rem" }} className="-mr-0.5 h-5 w-5" aria-hidden="true" />
              </>
            ) : (
              <>
                <span>{`Show ${pepProfile.weblinks?.length ?? 0} Source${
                  pepProfile.weblinks?.length === 1 ? "" : "s"
                }`}</span>
                <ArrowDownwardRounded sx={{ fontSize: "1rem" }} className="-mr-0.5 h-5 w-5" aria-hidden="true" />
              </>
            )}
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default PEPEventCard;
