import { ExpandMoreRounded } from "@mui/icons-material";
import { useState } from "react";
import { twJoin, twMerge } from "tailwind-merge";

export type Tab = {
  name: string;
  id: string;
  count: number | null;
  values?: { label: string; value: string; commandInstanceId: string }[];
};

export default function Tabs({
  tabs,
  selectedTab,
  onTabChangeHandler,
  onValueSelectionHandler,
  enableValueSelector,
  tabLabelClassName,
}: {
  tabs: Tab[];
  selectedTab: string;
  onTabChangeHandler: (id: string) => void;
  onValueSelectionHandler?: ((id: string) => void) | undefined;
  values?: { label: string; value: string; commandInstanceId: string }[];
  enableValueSelector?: boolean;
  tabLabelClassName?: string;
}) {
  const [showValueSelector, setShowValueSelector] = useState(false);

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {tabs.length > 1 ? (
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
            defaultValue={tabs?.find((tab) => tab?.id === selectedTab)?.name || undefined}
            onChange={(e) => {
              setShowValueSelector(false);
              onTabChangeHandler(e.target.value);
            }}
          >
            {tabs.map((tab) => (
              <option key={tab.name} value={tab.id}>
                {tab.name}
              </option>
            ))}
          </select>
        ) : null}
      </div>
      <div className="hidden sm:block">
        {tabs.length > 1 ? (
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex" aria-label="Tabs">
              {tabs.map((tab: Tab) => (
                <div
                  key={tab.id}
                  className="relative w-full flex"
                  onClick={() => {
                    setShowValueSelector(false);
                    onTabChangeHandler(tab.id);
                  }}
                  role="button"
                >
                  <span
                    className={twMerge(
                      "flex justify-center w-full py-4 px-1 text-center text-sm font-medium",
                      tab.id === selectedTab
                        ? "border-b-2 border-brand-purple text-brand-purple"
                        : "text-gray-500 hover:border-gray-300 hover:text-gray-700 hover:cursor-pointer",
                      tabLabelClassName ? tabLabelClassName : "",
                    )}
                    aria-current={tab.id === selectedTab ? "page" : undefined}
                  >
                    {tab.name}
                    {tab.id === "alerts" || (tab.id !== "business" && tab.count && tab.count > 1) ? (
                      <div className="flex items-center gap-x-1">
                        <span
                          className={twJoin(
                            tab.id === selectedTab ? "bg-indigo-100 text-indigo-600" : "bg-slate-100 text-slate-900",
                            "ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block",
                          )}
                        >
                          {tab.count}
                        </span>
                        {tab.id !== "alerts" && tab.id !== "business" && enableValueSelector ? (
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              if (tab.id !== selectedTab) {
                                onTabChangeHandler(tab.id);
                              }
                              setShowValueSelector(!showValueSelector);
                            }}
                          >
                            <ExpandMoreRounded sx={{ fontSize: "1rem" }} />
                          </button>
                        ) : null}
                      </div>
                    ) : null}
                  </span>
                  {showValueSelector && tab.id === selectedTab && tab.values && tab.values.length > 0 && (
                    <div className="text-xs bg-white border border-slate-200 rounded-lg absolute top-12 left-5 flex flex-col gap-y-2 w-[20rem] p-2 overflow-auto h-auto z-[25]">
                      {tab.values.map((value) => (
                        <button
                          key={value.value}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (onValueSelectionHandler) {
                              onValueSelectionHandler(value.value);
                            }
                            setShowValueSelector(false);
                          }}
                          className="text-left hover:bg-slate-50 py-2 px-3 rounded-lg"
                        >
                          {value.label}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </nav>
          </div>
        ) : null}
      </div>
    </div>
  );
}
