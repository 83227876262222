// @ts-nocheck
import Alert from "@/components/TailwindComponents/Alert";
import SourceTagCircle from "@/components/TailwindComponents/SourceTagCircle";
import { shimmerEffectClassNames } from "@/constants/vars";
import { FoundAddressEntryProps } from "@/types";
import {
  CancelRounded,
  CheckCircleRounded,
  LocationOnRounded,
  HomeRounded,
  BusinessRounded,
  LocalPostOfficeRounded,
  AutoAwesomeRounded,
} from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { twJoin } from "tailwind-merge";
import GenericSkeleton from "./GenericSkeleton";
import CheckSectionStatusMessage from "./CheckSectionStatusMessage";
import AddressValue from "@/components/TailwindComponents/AddressValue";

const Addresses = ({
  checkResult,
  inputIncorporationAddress,
  inputOperatingAddress,
  sourcesDictionary,
  activeSource,
  setActiveSource,
  handleSourceClick,
}: {
  checkResult: any;
  inputIncorporationAddress: any;
  inputOperatingAddress: any;
  sourcesDictionary: any;
  activeSource: any;
  setActiveSource: any;
  handleSourceClick: any;
}) => {
  const Header = (
    <div className="flex items-center gap-x-2">
      <LocationOnRounded sx={{ fontSize: "1.25rem" }} className="text-slate-700" />
      <span
        className={twJoin(
          "text-xl font-semibold",
          ["pending", "running"].includes(checkResult?.status) ? shimmerEffectClassNames : "",
        )}
      >
        Addresses
      </span>
    </div>
  );

  const filteredStatusMessages =
    checkResult?.status_messages?.filter(
      (message: { event: string | null; content: { status: string | null } }) =>
        message.event === "status" && !message.content.status?.startsWith("Loading:"),
    ) ?? [];
  const lastStatusMessage =
    filteredStatusMessages.length > 0 ? filteredStatusMessages[filteredStatusMessages.length - 1] : null;

  if (["pending", "running"].includes(checkResult?.status) && !checkResult?.verified_data) {
    return (
      <div className="flex flex-col gap-y-2 rounded-lg md:w-full">
        {Header}
        {lastStatusMessage && <CheckSectionStatusMessage message={lastStatusMessage.content.status} />}
        <GenericSkeleton />
      </div>
    );
  }

  const dedupedAddresses = checkResult.check_result?.address_matches?.reduce((acc, address) => {
    const key =
      `${address.address?.street_1}-${address.address?.street_2}-${address.address?.city}-${address.address?.country_code}`.toLowerCase();
    if (!acc[key]) {
      acc[key] = address;
    }
    return acc;
  }, {});

  const renderNotAddressFoundTreatment = () => {
    return (
      <div className="w-full text-sm overflow-hidden flex flex-col gap-y-5">
        {Header}
        {!checkResult.passed && <Alert message={checkResult.answer} level="failure" />}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-3 gap-y-2 w-full">
          <div className="flex flex-col gap-y-2 bg-slate-50 p-4 rounded-lg">
            <span className="font-semibold">Incorporation Address</span>
            {inputIncorporationAddress ? (
              <span className="text-slate-400 italic">Address not found</span>
            ) : (
              <span className="text-slate-400 italic">Address was not provided • Address was not found</span>
            )}
          </div>
          <div className="flex flex-col gap-y-2 bg-slate-50 p-4 rounded-lg">
            <span className="font-semibold">Operating Address</span>
            {inputOperatingAddress ? (
              <span className="text-slate-400 italic">Address not found</span>
            ) : (
              <span className="text-slate-400 italic">Address was not provided • Address was not found</span>
            )}
          </div>
        </div>
      </div>
    );
  };

  return dedupedAddresses && Object.values(dedupedAddresses).length > 0 ? (
    <div className="w-full text-sm overflow-hidden flex flex-col gap-y-5">
      {Header}
      {!checkResult.passed && <Alert message={checkResult.answer} level="failure" />}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-3 gap-y-2 w-full">
        {Object.values(dedupedAddresses)
          .filter((address: any) => address.address && address.address.city)
          .map((address: any, index: number) => (
            <FoundAddressEntry
              key={index}
              data={address.address}
              addressType={address.address_type}
              foundAddress={address.found}
              explanation={address.explanation}
              addressInput={address.address_input}
              sourceTagCircle={
                <SourceTagCircle
                  source={sourcesDictionary[address.source_ids?.[0]]}
                  isActive={activeSource === address.source_ids?.[0]}
                  setActiveSource={setActiveSource}
                  handleSourceClick={handleSourceClick}
                />
              }
            />
          ))}
      </div>
    </div>
  ) : ["pending", "running"].includes(checkResult?.status) ? (
    <div className="flex flex-col gap-y-2 rounded-lg md:w-full">
      {Header}
      {lastStatusMessage && <CheckSectionStatusMessage message={lastStatusMessage.content.status} />}
      <GenericSkeleton />
    </div>
  ) : (
    renderNotAddressFoundTreatment()
  );
};

const FoundAddressEntry: React.FC<FoundAddressEntryProps> = ({
  data,
  addressInput,
  addressType,
  explanation,
  sourceTagCircle,
  foundAddress,
}) => {
  const addressLine1 = `${data?.street_1 && data?.street_1 !== "Unknown" ? data.street_1 : ""}${
    data?.street_2 && data?.street_2 !== "Unknown" ? `, ${data.street_2}` : ""
  }`
    .replace(/^, /, "")
    .trim();
  const addressLine2 = `${data?.city && data.city !== "Unknown" ? data.city : ""}${
    data?.state && data.state !== "Unknown" ? `, ${data.state}` : ""
  }${data?.country_code && data.country_code !== "Unknown" ? `, ${data.country_code}` : ""}${
    data?.postal_code && data.postal_code !== "Unknown" ? `, ${data.postal_code}` : ""
  }`
    .replace(/^, /, "")
    .trim();
  const gMapsAddress = encodeURIComponent(addressLine1 + " " + addressLine2).replaceAll(" ", "+");

  const isAddressFoundAndSelfAttested = foundAddress && addressInput !== null;
  const isAddressFoundAndNotSelfAttested = foundAddress && addressInput === null;
  const isAddressNotFoundAndSelfAttested = foundAddress === false && addressInput !== null;

  return (
    <div
      className={`text-xs border p-4 flex flex-col gap-y-2 rounded-lg ${
        isAddressNotFoundAndSelfAttested
          ? "border-red-500 bg-white"
          : isAddressFoundAndSelfAttested
            ? "border-slate-50 bg-slate-50"
            : isAddressFoundAndNotSelfAttested
              ? "border-slate-300 bg-white"
              : "border-slate-50 bg-slate-50"
      }`}
    >
      <div className="flex justify-between items-center">
        <span className="font-medium text-xs inline-flex gap-x-2 items-center">
          {data?.address_type === "operations"
            ? "Operation Address"
            : data?.address_type === "incorporation"
              ? "Incorporation Address"
              : !foundAddress
                ? "Self Attested Address"
                : "Found Address"}
          {sourceTagCircle}
        </span>
        {isAddressFoundAndSelfAttested ? (
          <CheckCircleRounded sx={{ fontSize: "1rem" }} className="text-green-600" />
        ) : isAddressFoundAndNotSelfAttested ? (
          <AutoAwesomeRounded sx={{ fontSize: "1rem" }} className="text-slate-500" />
        ) : isAddressNotFoundAndSelfAttested ? (
          <CancelRounded sx={{ fontSize: "1rem" }} className="text-red-600" />
        ) : null}
      </div>

      <div className="flex w-full gap-x-3">
        <div className="w-1/2 flex flex-col gap-y-2">
          {addressType && ["residential", "commercial", "po_box"].includes(addressType) && (
            <div className="flex items-center gap-x-2">
              <span className="text-xs font-medium text-slate-700 flex items-center gap-x-1">
                {addressType === "residential" ? (
                  <HomeRounded sx={{ fontSize: "1rem" }} className="text-slate-400" />
                ) : addressType === "commercial" ? (
                  <BusinessRounded sx={{ fontSize: "1rem" }} className="text-slate-400" />
                ) : (
                  <LocalPostOfficeRounded sx={{ fontSize: "1rem" }} className="text-slate-400" />
                )}
                {addressType === "residential" ? "Residential" : addressType === "commercial" ? "Commercial" : "PO Box"}
              </span>
            </div>
          )}
          <div className="flex flex-col gap-y-1 mt-1">
            <AddressValue
              street1={data?.street_1}
              street2={data?.street_2}
              city={data?.city}
              state={data?.state}
              postalCode={data?.postal_code}
              countryCode={data?.country_code}
            />
          </div>
        </div>
        <a
          href={`https://www.google.com/maps/search/?api=1&query=${gMapsAddress}`}
          target="_blank"
          rel="noreferrer"
          className="block w-1/2 h-[7.5rem] relative group"
        >
          <img
            src={`https://maps.googleapis.com/maps/api/staticmap?center=${gMapsAddress}&zoom=13&size=1200x600&maptype=roadmap&markers=color:red%7C${gMapsAddress}&key=AIzaSyA-5WSnfQmqQYax2f6JUb0_UFxTCVotbes`}
            className="object-cover max-w-[400px] place-self-center w-full h-full rounded-md transition-opacity group-hover:opacity-90"
          />
          <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
            <span className="bg-white text-slate-800 px-2 py-1 rounded text-xs font-medium">View on Google Maps</span>
          </div>
        </a>
      </div>

      {explanation && (
        <p
          className={twJoin(
            "text-xs p-3 rounded-lg",
            isAddressFoundAndSelfAttested
              ? "text-slate-700 bg-slate-50"
              : isAddressFoundAndNotSelfAttested
                ? "text-slate-700 bg-slate-100"
                : isAddressNotFoundAndSelfAttested
                  ? "text-red-700 bg-red-50"
                  : "text-slate-700 bg-slate-50",
          )}
        >
          {explanation}
        </p>
      )}
    </div>
  );
};

export default Addresses;
