import { UIDocument } from "@/types";
import InsertDriveFileRounded from "@mui/icons-material/InsertDriveFileRounded";
import { TruncatedFilename } from "./AuditLogDocument";

const DocumentValue = ({
  fileName,
  url,
  maxWidth = 200,
  checkResult,
  handleDocumentValidationClick,
}: UIDocument & { maxWidth?: number; handleDocumentValidationClick?: any; checkResult?: any }) => {
  if (!fileName || !fileName?.length) {
    return null;
  }

  return handleDocumentValidationClick ? (
    <button
      className="w-fit flex items-center gap-x-1 text-xs group"
      onClick={(e) => {
        e.preventDefault();
        handleDocumentValidationClick(checkResult, {
          document: {
            file_name: fileName,
            url,
          },
        });
      }}
    >
      <InsertDriveFileRounded
        sx={{ fontSize: "1rem", height: "1rem", width: "1rem" }}
        className="text-brand-purple grow-0"
      />
      <p className="flex-1 grow group-hover:underline text-brand-purple leading-4">
        <TruncatedFilename filename={fileName} maxWidth={maxWidth} />
      </p>
    </button>
  ) : (
    <a
      href={url ? (url.startsWith("http") ? url : `https://${url}`) : undefined}
      target="_blank"
      rel="noopener noreferrer"
      className="w-fit flex items-center gap-x-1 text-xs group"
    >
      <InsertDriveFileRounded
        sx={{ fontSize: "1rem", height: "1rem", width: "1rem" }}
        className="text-brand-purple grow-0"
      />
      <span className="flex-1 grow group-hover:underline text-brand-purple leading-4">
        <TruncatedFilename filename={fileName} maxWidth={maxWidth} />
      </span>
    </a>
  );
};

export default DocumentValue;
