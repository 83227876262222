import { Link } from "@mui/material";
import { COLORS } from "../../../constants/colors";
import styles from "./styles.module.css";
import { AgentStatus } from "../../../types";
import LaunchIcon from "@mui/icons-material/LaunchRounded";
import { motion } from "framer-motion";
import { twMerge } from "tailwind-merge";

const CheckStatusMessage = ({ messageContents, className }: { messageContents: AgentStatus; className?: string }) => {
  const agentStatusContent = messageContents;
  const shouldShowLink = !!agentStatusContent.url;

  return (
    <div className={twMerge("flex items-center gap-3 py-1", className)}>
      <motion.img
        src="/working_loader.svg"
        alt="logo"
        className={styles.loader}
        style={{ width: 20, padding: 0 }}
        animate={{ rotate: 360 }}
        transition={{ duration: 2.5, repeat: Infinity, ease: "linear", delay: 0.5 }}
      />
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="text-xs font-normal"
      >
        <span className="text-xs line-clamp-1">{agentStatusContent.status}</span>
      </motion.div>
      {shouldShowLink && (
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="text-xs flex items-center gap-[0.15rem]"
        >
          <Link
            sx={{
              textDecoration: "none",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
            href={agentStatusContent.url}
            target="__blank"
            rel="noopener noreferrer"
          >
            {agentStatusContent.button_label}
          </Link>
          <LaunchIcon width="16" fontSize="inherit" sx={{ color: COLORS.parchaBrandPurple }} />
        </motion.div>
      )}
    </div>
  );
};

export default CheckStatusMessage;
