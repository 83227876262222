import BusinessBasicProfile from "@/components/TailwindComponents/BusinessBasicProfile";
import { ErrorOutline, LanguageRounded } from "@mui/icons-material";
import {
  AdverseMedia,
  BusinessOwners,
  HighRiskIndustries,
  LowProfileConfidenceBanner,
  MCC,
  OnlinePresence,
  Policies,
  SanctionsWatchlist,
  WebsiteCardSkeleton,
} from "@/components/TailwindComponents/PublicBDDReport";
import FindingsSection from "@/components/TailwindComponents/FindingsSection";
import SourceTagCircle from "@/components/TailwindComponents/SourceTagCircle";
import AnimatedParagraph from "@/components/TailwindComponents/AnimatedParagraph";
import {
  ADDRESS_CHECK_ID,
  BUSINESS_OWNERS_CHECK_ID,
  BUSINESS_REVIEWS_CHECK_ID,
  HIGH_RISK_INDUSTRY_V2_CHECK_ID,
  MCC_CHECK_ID,
  MCC_V2_CHECK_ID,
  POLICY_CHECK_ID,
  shimmerEffectClassNames,
} from "@/constants/vars";

import InstagramWebsiteCard from "@/components/TailwindComponents/InstagramWebsiteCard";
import PhysicalLocationReviews from "@/components/TailwindComponents/PhysicalLocationReviews";

import WebsiteCard from "@/components/TailwindComponents/WebsiteCard";

import { motion } from "framer-motion";
import { useState } from "react";
import { Key } from "readline";
import Addresses from "../PublicBDDComponents/sections/Addresses";
import HighRiskCountries from "../PublicBDDComponents/sections/HighRiskCountries";
import FindingsSource from "@/components/TailwindComponents/FindingsSource";

const BUSINESS_PROFILE_CHECK_ID = "kyb.basic_business_profile_check";
const WEB_PRESENCE_CHECK_ID = "kyb.web_presence_check";
const HIGH_RISK_COUNTRY_CHECK_ID = "kyb.high_risk_country_tool";
const HIGH_RISK_INDUSTRY_CHECK_ID = "kyb.high_risk_industry_tool";
const ADVERSE_MEDIA_CHECK_ID = "kyb.screening.adverse_media_tool";
const SANCTIONS_WATCHLIST_CHECK_ID = "kyb.sanctions_watchlist_check";

const AssociatedEntityFindings = ({
  checkResults,
  sources,
  job,
}: {
  checkResults: any;
  onTryAgainClick: () => void;
  job: any;
  sources: any[];
}) => {
  const [activeSource, setActiveSource] = useState<number | null>(null);

  const businessReviewsCheckResult = checkResults.find(
    (checkResult: { command_id: string }) => checkResult.command_id === BUSINESS_REVIEWS_CHECK_ID,
  );

  const businessBasicProfileCheckResult = checkResults.find(
    (checkResult: { command_id: string }) => checkResult.command_id == BUSINESS_PROFILE_CHECK_ID,
  );

  const webPresenceCheckResult = checkResults.find(
    (checkResult: { command_id: string }) => checkResult.command_id === WEB_PRESENCE_CHECK_ID,
  );
  const addressesCheckResult = checkResults.find(
    (checkResult: { command_id: string }) => checkResult.command_id === ADDRESS_CHECK_ID,
  );
  const highRiskCountriesCheckResult = checkResults.find(
    (checkResult: { command_id: string }) => checkResult.command_id === HIGH_RISK_COUNTRY_CHECK_ID,
  );

  const highRiskIndustriesCheckResult = checkResults.find(
    (checkResult: { command_id: string }) => checkResult.command_id === HIGH_RISK_INDUSTRY_CHECK_ID,
  );

  const highRiskIndustriesV2CheckResult = checkResults.find(
    (checkResult: { command_id: string }) => checkResult.command_id === HIGH_RISK_INDUSTRY_V2_CHECK_ID,
  );

  const adverseMediaCheckResult = checkResults.find(
    (checkResult: { command_id: string }) => checkResult.command_id === ADVERSE_MEDIA_CHECK_ID,
  );
  const sanctionsWatchlistCheckResult = checkResults.find(
    (checkResult: { command_id: string }) => checkResult.command_id === SANCTIONS_WATCHLIST_CHECK_ID,
  );
  const policiesCheckResult = checkResults.find(
    (checkResult: { command_id: string }) => checkResult.command_id === POLICY_CHECK_ID,
  );
  const mccCheckResult = checkResults.find(
    (checkResult: { command_id: string }) =>
      checkResult.command_id === MCC_CHECK_ID || checkResult.command_id === MCC_V2_CHECK_ID,
  );
  const businessOwnersCheckResult = checkResults.find(
    (checkResult: { command_id: string }) => checkResult.command_id === BUSINESS_OWNERS_CHECK_ID,
  );

  const officialWebsite =
    webPresenceCheckResult?.verified_data?.official_websites?.length > 0
      ? webPresenceCheckResult?.verified_data?.official_websites?.[0]
      : null;
  const websiteUrl =
    officialWebsite?.scanned_website?.webpage_url || webPresenceCheckResult?.check_result?.official_website_match?.url;
  const websiteTitle =
    officialWebsite?.scanned_website?.webpage_title ||
    webPresenceCheckResult?.check_result?.official_website_match?.title;
  const websiteScreenshotUrl =
    officialWebsite?.scanned_website?.screenshot_url ||
    webPresenceCheckResult?.check_result?.official_website_match?.screenshot_url;
  const websiteVisualSummary =
    officialWebsite?.analysis?.extracted_metadata?.visual_summary ||
    webPresenceCheckResult?.check_result?.official_website_match?.visual_summary;
  const scrapeType = officialWebsite?.scanned_website?.scrape_type;

  const sites =
    (webPresenceCheckResult?.verified_data?.self_attested_webpages?.length > 0
      ? webPresenceCheckResult?.verified_data?.self_attested_webpages
      : webPresenceCheckResult?.verified_data?.google_search_results) || [];
  let officialBusinessWebsite =
    sites?.find(
      (website: any) =>
        website.webpage_metadata?.webpage_type === "official_business_website" ||
        website.analysis?.extracted_metadata?.webpage_type === "official_business_website",
    ) || webPresenceCheckResult?.verified_data?.self_attested_webpages?.[0];

  if (officialBusinessWebsite) {
    officialBusinessWebsite["title"] =
      officialBusinessWebsite.webpage_title || officialBusinessWebsite.scanned_website?.webpage_title;
    officialBusinessWebsite["visual_summary"] =
      officialBusinessWebsite.webpage_metadata?.visual_summary ||
      officialBusinessWebsite.analysis?.extracted_metadata?.visual_summary ||
      officialBusinessWebsite.webpage_metadata?.summary ||
      officialBusinessWebsite.scanned_website?.webpage_metadata?.summary;
    officialBusinessWebsite["url"] =
      officialBusinessWebsite.webpage_url || officialBusinessWebsite.scanned_website?.webpage_url;
  } else {
    officialBusinessWebsite =
      webPresenceCheckResult?.check_result?.official_website_matches ||
      webPresenceCheckResult?.check_result?.official_website_match;
  }

  if (
    officialBusinessWebsite &&
    !officialBusinessWebsite.screenshot_url &&
    !officialBusinessWebsite.scanned_website?.screenshot_url
  ) {
    // use first self attested result (backwards compatibility)
    officialBusinessWebsite.screenshot_url =
      webPresenceCheckResult?.verified_data?.self_attested_webpages?.find(
        (item: any) =>
          item.webpage_url === officialBusinessWebsite?.url ||
          item.scanned_website?.webpage_url === officialBusinessWebsite?.url,
      )?.screenshot_url ||
      webPresenceCheckResult?.verified_data?.self_attested_webpages?.find(
        (item: any) =>
          item.webpage_url === officialBusinessWebsite?.url ||
          item.scanned_website?.webpage_url === officialBusinessWebsite?.url,
      )?.webpage_structured_scrape?.profilePicUrlHD;
  }

  const website = webPresenceCheckResult?.verified_data?.self_attested_webpages?.[0]?.webpage_url;

  const sourcesDictionary = sources?.reduce(
    (acc: { [x: string]: any }, source: { source_id: string | number }, index: number) => {
      acc[source.source_id] = { ...source, id: index + 1 };
      return acc;
    },
    {},
  );

  const handleSourceClick = (sourceId: number): void => {
    const sourceElement = document.getElementById(`source-${sourceId}`);
    if (sourceElement) {
      sourceElement.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
    }
  };

  return (
    <div className="md:px-[2.5rem] px-4 py-4 md:py-10 h-full flex flex-col gap-y-6 md:max-w-[1120px]">
      {webPresenceCheckResult?.verified_data?.business_profile?.profile_confidence === "LOW" && (
        <LowProfileConfidenceBanner />
      )}
      {webPresenceCheckResult?.status === "complete" &&
        webPresenceCheckResult?.verified_data?.only_used_extended_search_results &&
        !webPresenceCheckResult?.verified_data?.no_business_information_found && (
          <div className="flex items-center justify-center gap-x-5 py-5 bg-indigo-100 text-slate-700 rounded-lg">
            <LanguageRounded sx={{ fontSize: "1.25rem" }} className="text-slate-500" />
            <span className="font-semibold">
              {website
                ? "We used an expanded web search because the provided business website was unreachable."
                : "We used an expanded web search because a business website was not provided."}
            </span>
          </div>
        )}
      {webPresenceCheckResult?.status === "complete" &&
        webPresenceCheckResult?.verified_data?.no_business_information_found && (
          <div className="flex items-center gap-x-5 py-5 px-8 bg-red-50 text-red-700 rounded-lg">
            <ErrorOutline sx={{ fontSize: "2rem" }} className="text-red-700" />
            <div>
              <span className="text-base font-semibold">Business not found</span>
              <p className="text-xs">
                We were unable to confidently create a profile of any sort as we could not find any sources related to
                the business.
              </p>
            </div>
          </div>
        )}
      <div className="flex flex-col gap-8 px-6 md:p-0">
        {(webPresenceCheckResult || businessBasicProfileCheckResult) && (
          <div className="flex flex-col lg:flex-row gap-[40px] w-full">
            {websiteUrl ? (
              <motion.div
                initial={{ opacity: 0, filter: "blur(10px)" }}
                animate={{ opacity: 1, filter: "blur(0px)" }}
                transition={{ duration: 1 }}
                className="w-full lg:w-fit mx-auto md:m-0"
              >
                {scrapeType === "instagram" ? (
                  <InstagramWebsiteCard
                    biography={officialWebsite?.scanned_website?.webpage_structured_scrape?.biography}
                    username={officialWebsite?.scanned_website?.webpage_structured_scrape?.username}
                    businessCategoryName={
                      officialWebsite?.scanned_website?.webpage_structured_scrape?.businessCategoryName
                    }
                    logoUrl={officialWebsite?.scanned_website?.webpage_structured_scrape?.profilePicUrlHD}
                    name={officialWebsite?.scanned_website?.webpage_structured_scrape?.fullName}
                    followersCount={officialWebsite?.scanned_website?.webpage_structured_scrape?.followersCount}
                    instagramUrl={officialWebsite?.scanned_website?.webpage_structured_scrape?.url}
                    externalUrl={officialWebsite?.scanned_website?.webpage_structured_scrape?.externalUrl}
                    latestPosts={officialWebsite?.scanned_website?.webpage_structured_scrape?.latestPosts}
                    isVerified={officialWebsite?.scanned_website?.webpage_structured_scrape?.verified}
                    postsCount={officialWebsite?.scanned_website?.webpage_structured_scrape?.postsCount}
                    followCount={officialWebsite?.scanned_website?.webpage_structured_scrape?.followsCount}
                  />
                ) : (
                  <WebsiteCard
                    websiteUrl={websiteUrl}
                    websiteLabel={websiteTitle}
                    screenshotUrl={websiteScreenshotUrl}
                    visualSummary={websiteVisualSummary}
                  />
                )}
              </motion.div>
            ) : webPresenceCheckResult ? (
              <WebsiteCardSkeleton
                message={
                  webPresenceCheckResult?.verified_data?.no_business_information_found
                    ? "No business information found"
                    : webPresenceCheckResult?.verified_data?.unreachable_self_attested_websites
                      ? "Unreachable website"
                      : undefined
                }
                disableShimmer={["error", "complete"].includes(webPresenceCheckResult.status)}
              />
            ) : null}
            {businessBasicProfileCheckResult && (
              <div className="flex-1">
                <BusinessBasicProfile
                  checkResult={businessBasicProfileCheckResult}
                  input={job.input_payload}
                  sourcesDictionary={sourcesDictionary}
                  activeSource={activeSource}
                  setActiveSource={setActiveSource}
                  handleSourceClick={handleSourceClick}
                />
              </div>
            )}
          </div>
        )}
        {(webPresenceCheckResult?.verified_data?.business_profile?.business_description?.length > 0 &&
          webPresenceCheckResult?.verified_data?.business_profile?.business_description.some(
            (d: { description: string }) => d.description.length > 0,
          )) ||
        businessBasicProfileCheckResult?.verified_data?.business_description_found ? (
          <FindingsSection
            title="Business Description"
            content={
              <div className="flex flex-col gap-y-3">
                {webPresenceCheckResult?.verified_data?.business_profile?.business_description?.length > 0 ? (
                  webPresenceCheckResult.verified_data.business_profile.business_description.map(
                    (descriptionEntry: { description: string; sources?: { source_id: number }[] }, index: number) => (
                      <div key={index}>
                        <AnimatedParagraph text={descriptionEntry.description} />
                        <span className="inline-flex gap-1 ml-2">
                          {descriptionEntry.sources?.map((s) => (
                            <SourceTagCircle
                              key={s.source_id}
                              source={sourcesDictionary[s.source_id]}
                              isActive={s.source_id === activeSource}
                              setActiveSource={setActiveSource}
                              handleSourceClick={handleSourceClick}
                            />
                          ))}
                        </span>
                      </div>
                    ),
                  )
                ) : businessBasicProfileCheckResult?.verified_data?.business_description_found ? (
                  <div>
                    <AnimatedParagraph
                      text={businessBasicProfileCheckResult.verified_data.business_description_found.description}
                      disableAnimation={false}
                    />
                    <span className="inline-flex gap-1 ml-2">
                      {businessBasicProfileCheckResult.verified_data.business_description_found.sources?.map(
                        (s: { source_id: number }) => (
                          <SourceTagCircle
                            key={s.source_id}
                            source={sourcesDictionary[s.source_id]}
                            isActive={s.source_id === activeSource}
                            setActiveSource={setActiveSource}
                            handleSourceClick={handleSourceClick}
                          />
                        ),
                      )}
                    </span>
                  </div>
                ) : null}
              </div>
            }
            sources={[]}
            activeSource={null}
            setActiveSource={setActiveSource}
          />
        ) : ["error", "complete"].includes(webPresenceCheckResult?.status) ? null : webPresenceCheckResult ? (
          <div className="flex flex-col gap-y-4">
            <span className={`font-medium ${shimmerEffectClassNames}`}>Business Description</span>
            <div className="animate-pulse flex flex-col gap-y-2">
              <div className="w-1/2 h-3 bg-slate-200 rounded-lg"></div>
              <div className="w-1/3 h-3 bg-slate-200 rounded-lg"></div>
              <div className="w-1/4 h-3 bg-slate-200 rounded-lg"></div>
              <div className="w-1/3 h-3 bg-slate-200 rounded-lg"></div>
              <div className="w-1/2 h-3 bg-slate-200 rounded-lg"></div>
            </div>
          </div>
        ) : null}
        {webPresenceCheckResult?.verified_data?.business_profile?.business_products_and_services
          ?.products_and_services_summary?.length > 0 ||
        webPresenceCheckResult?.verified_data?.business_profile?.business_products_and_services
          ?.products_and_services_list?.length ? (
          <FindingsSection
            title="Products and Services"
            content={
              <div>
                <div>
                  <span className="mr-2">
                    <AnimatedParagraph
                      text={
                        webPresenceCheckResult?.verified_data?.business_profile?.business_products_and_services
                          ?.products_and_services_summary
                      }
                    />
                  </span>
                  <span className="inline-flex gap-x-1">
                    {webPresenceCheckResult?.verified_data?.business_profile?.business_products_and_services?.sources?.map(
                      (s: { source_id: number }) => (
                        <SourceTagCircle
                          key={s.source_id}
                          source={sourcesDictionary[s.source_id]}
                          isActive={s.source_id === activeSource}
                          setActiveSource={setActiveSource}
                          handleSourceClick={handleSourceClick}
                        />
                      ),
                    )}
                  </span>
                </div>
                <ul className="list-disc list-outside ml-8 mt-2 space-y-2">
                  {webPresenceCheckResult?.verified_data?.business_profile?.business_products_and_services.products_and_services_list?.map(
                    (product: { name: string; description: string }, index: number) => (
                      <li key={index}>
                        <span className="font-semibold">
                          <AnimatedParagraph text={product.name} className="inline" />
                        </span>
                        {" - "}
                        <span>
                          <AnimatedParagraph text={product.description} className="inline" />
                        </span>
                      </li>
                    ),
                  )}
                </ul>
              </div>
            }
            sources={[]}
            activeSource={null}
            setActiveSource={setActiveSource}
          />
        ) : businessBasicProfileCheckResult?.verified_data?.business_products_and_services_found ? (
          <FindingsSection
            title="Products and Services"
            content={
              <div>
                <div>
                  <span className="mr-2">
                    <AnimatedParagraph
                      text={
                        businessBasicProfileCheckResult.verified_data.business_products_and_services_found
                          .products_and_services
                      }
                      disableAnimation={false}
                    />
                  </span>
                  <span className="inline-flex gap-x-1">
                    {businessBasicProfileCheckResult.verified_data.business_products_and_services_found.sources?.map(
                      (s: { source_id: number }) => (
                        <SourceTagCircle
                          key={s.source_id}
                          source={sourcesDictionary[s.source_id]}
                          isActive={s.source_id === activeSource}
                          setActiveSource={setActiveSource}
                          handleSourceClick={handleSourceClick}
                        />
                      ),
                    )}
                  </span>
                </div>
              </div>
            }
            sources={[]}
            activeSource={null}
            setActiveSource={function (source: number | null): void {
              throw new Error("Function not implemented.");
            }}
          />
        ) : ["error", "complete"].includes(webPresenceCheckResult?.status) ? null : webPresenceCheckResult ? (
          <div className="flex flex-col gap-y-4 h-full">
            <span className={`font-medium ${shimmerEffectClassNames}`}>Products and Services</span>
            <div className="animate-pulse flex flex-col gap-y-2">
              <div className="w-1/2 h-3 bg-slate-200 rounded-lg"></div>
              <div className="w-1/3 h-3 bg-slate-200 rounded-lg"></div>
              <div className="w-0 h-0"></div>
              <div className="ml-2 w-1/4 h-3 bg-slate-200 rounded-lg"></div>
              <div className="ml-2 w-1/4 h-3 bg-slate-200 rounded-lg"></div>
              <div className="ml-2 w-1/4 h-3 bg-slate-200 rounded-lg"></div>
            </div>
          </div>
        ) : null}

        {webPresenceCheckResult?.verified_data?.business_profile?.business_customers?.length > 0 ? (
          <FindingsSection
            title="Current Customers"
            content={
              <div>
                <ol className="list-decimal list-outside ml-8 mt-2 space-y-2">
                  {webPresenceCheckResult.verified_data.business_profile.business_customers.map(
                    (customer: string, index: number) => (
                      <li key={index}>
                        <AnimatedParagraph text={customer} className="inline" />
                      </li>
                    ),
                  )}
                </ol>
              </div>
            }
            sources={[]}
            activeSource={null}
            setActiveSource={setActiveSource}
          />
        ) : null}
        {businessReviewsCheckResult && <PhysicalLocationReviews checkResult={businessReviewsCheckResult} />}
        {businessBasicProfileCheckResult && (
          <OnlinePresence checkResult={businessBasicProfileCheckResult} sourcesDictionary={sourcesDictionary} />
        )}
        {addressesCheckResult && (
          <Addresses
            checkResult={addressesCheckResult}
            inputIncorporationAddress={job.input_payload.self_attested_data.address_of_incorporation ?? null}
            inputOperatingAddress={job.input_payload.self_attested_data.address_of_operation ?? null}
            sourcesDictionary={sourcesDictionary}
            activeSource={activeSource}
            setActiveSource={setActiveSource}
            handleSourceClick={handleSourceClick}
          />
        )}
        {highRiskCountriesCheckResult || highRiskIndustriesCheckResult || highRiskIndustriesV2CheckResult ? (
          <div className="flex-1 flex flex-col lg:flex-row gap-5 justify-between last:pb-5">
            {highRiskCountriesCheckResult && (
              <div className="w-full lg:w-1/2">
                <HighRiskCountries
                  checkResult={highRiskCountriesCheckResult}
                  activeSource={activeSource}
                  sourcesDictionary={sourcesDictionary}
                  setActiveSource={setActiveSource}
                  handleSourceClick={handleSourceClick}
                />
              </div>
            )}

            {(highRiskIndustriesCheckResult || highRiskIndustriesV2CheckResult) && (
              <div className={`w-full ${highRiskCountriesCheckResult ? "lg:w-1/2" : ""}`}>
                <HighRiskIndustries
                  checkResult={highRiskIndustriesCheckResult || highRiskIndustriesV2CheckResult}
                  activeSource={activeSource}
                  sourcesDictionary={sourcesDictionary}
                  setActiveSource={setActiveSource}
                  handleSourceClick={handleSourceClick}
                />
              </div>
            )}
          </div>
        ) : null}

        {mccCheckResult && (
          <div className="flex-1 last:pb-[5rem]">
            <MCC checkResult={mccCheckResult} />
          </div>
        )}

        {(adverseMediaCheckResult || sanctionsWatchlistCheckResult) && (
          <div className="flex-1 last:pb-[5rem]">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              {adverseMediaCheckResult && (
                <div className="w-full">
                  <AdverseMedia checkResult={adverseMediaCheckResult} />
                </div>
              )}

              {sanctionsWatchlistCheckResult && (
                <div className="w-full">
                  <SanctionsWatchlist checkResult={sanctionsWatchlistCheckResult} />
                </div>
              )}
            </div>
          </div>
        )}

        {policiesCheckResult && (
          <div className="flex-1 last:pb-[5rem]">
            <Policies
              checkResult={policiesCheckResult}
              sourcesDictionary={sourcesDictionary}
              activeSource={activeSource}
              setActiveSource={setActiveSource}
              handleSourceClick={handleSourceClick}
            />
          </div>
        )}

        {businessOwnersCheckResult && (
          <div className="flex-1 last:pb-[5rem]">
            <BusinessOwners checkResult={businessOwnersCheckResult} sourcesDictionary={sourcesDictionary} />
          </div>
        )}

        {sources.length > 0 && sources.every((source) => !("text" in source)) && (
          <div className="flex flex-col gap-y-3 w-full">
            <div className="flex items-center gap-x-2">
              <LanguageRounded sx={{ fontSize: "1.25rem" }} className="text-slate-700" />
              <span className="text-lg font-semibold">Sources</span>
            </div>
            {sources.map((source, index) => {
              return <FindingsSource source={{ ...source, id: index + 1 }} key={source.source_id} />;
            })}
          </div>
        )}
      </div>
    </div>
  );
};
export default AssociatedEntityFindings;
