import { useState } from "react";
import { format } from "date-fns";
import DOMPurify from "dompurify";
import {
  DoNotDisturbRounded,
  EventRounded,
  ExpandLessRounded,
  ExpandMoreRounded,
  LabelRounded,
  LanguageRounded,
  LocationOnRounded,
  PersonRounded,
  PhotoCameraRounded,
} from "@mui/icons-material";
import WrappedValue from "./WrappedValue";

type SourceItemProps = {
  url?: string | null;
  title?: string | null;
  summary?: string | null;
  sourceName?: string | null;
  text?: string | null;
  hasPhoto?: boolean | null;
  wasRemoved?: boolean | null;
  who?: string | null;
  when?: number | string | null;
  where?: string | null;
  why?: string[] | null;
  perpetratorAge?: number | null;
};

const SourceItem = ({
  url,
  title,
  summary,
  text,
  hasPhoto,
  wasRemoved,
  when,
  who,
  where,
  why,
  perpetratorAge,
}: SourceItemProps) => {
  const [showText, setShowText] = useState(false);
  const [iconLoadingError, setIconLoadingError] = useState(false);
  const domain = url ? new URL(url).hostname : "";
  return (
    <div className="w-full p-3 bg-slate-50 rounded-lg text-xs text-slate-900 flex flex-col gap-y-3">
      <div className="w-full flex flex-col gap-y-2">
        <div className="flex flex-1 items-center gap-x-3 justify-between text-slate-500">
          <div className="line-clamp-1 flex items-center">
            {domain &&
              (!iconLoadingError ? (
                <img
                  src={`https://www.google.com/s2/favicons?domain=${domain}`}
                  alt={`${domain} favicon`}
                  className="w-4 h-4 mr-2 rounded-full"
                  onError={({ currentTarget }) => {
                    currentTarget.style.display = "none";
                    setIconLoadingError(true);
                  }}
                />
              ) : (
                <LanguageRounded sx={{ fontSize: "1rem" }} className="mr-2" />
              ))}
            {(url || title) && (
              <div className="text-sm truncate">
                {url ? (
                  <a
                    href={url.startsWith("http") ? url : `https://${url}`}
                    target="_blank"
                    rel="noreferrer"
                    className="text-brand-purple text-ellipsis overflow-hidden whitespace-nowrap hover:underline"
                  >
                    {title || domain}
                  </a>
                ) : title ? (
                  <span>{title}</span>
                ) : null}
              </div>
            )}
          </div>
          <div className="flex flex-1 items-center gap-2 place-content-end">
            {wasRemoved ? (
              <WrappedValue
                level="failure"
                text={
                  <div className="flex items-center gap-1">
                    <DoNotDisturbRounded sx={{ fontSize: "1rem" }} />
                    <span>Article is unavailable</span>
                  </div>
                }
              />
            ) : null}
            {hasPhoto && (
              <WrappedValue
                level="normal"
                text={
                  <div className="flex items-center gap-1">
                    <PhotoCameraRounded sx={{ fontSize: "1rem" }} />
                    <span>Has a photo</span>
                  </div>
                }
              />
            )}
          </div>
        </div>
      </div>
      {summary && <p className="whitespace-normal text-sm leading-[1.125rem]">{summary}</p>}
      <div className="grid grid-cols-2 gap-3 text-sm text-slate-700">
        {who && (
          <div className="flex flex-col gap-1">
            <span className="font-semibold text-xs">Who</span>
            <div className="flex gap-1 items-center">
              <PersonRounded sx={{ fontSize: "1rem" }} />
              <span>{who}</span>{" "}
              {perpetratorAge ? (
                <span className="text-xs text-slate-500 self-end leading-[1.125rem]">
                  {perpetratorAge} years old today
                </span>
              ) : null}
            </div>
          </div>
        )}
        {where && (
          <div className="flex flex-col gap-1">
            <span className="font-semibold text-xs">Where</span>
            <div className="flex gap-1 items-center">
              <LocationOnRounded sx={{ fontSize: "1rem" }} />
              <span className="text-sm">{where}</span>
            </div>
          </div>
        )}
        {when && when !== "None" && (
          <div className="flex flex-col gap-1">
            <span className="font-semibold text-xs">When</span>
            <div className="flex gap-1 items-center">
              <EventRounded sx={{ fontSize: "1rem" }} />
              <span className="text-sm">
                {when ? (
                  typeof when === "number" || (typeof when === "string" && when.length === 4) ? (
                    <span>{when}</span>
                  ) : (
                    <span>{format(new Date(when), "MMMM dd, yyyy")}</span>
                  )
                ) : null}
              </span>
            </div>
          </div>
        )}
        {why && (
          <div className="flex flex-col gap-1">
            <span className="font-semibold text-xs">Why</span>
            <div className="flex gap-1 items-center">
              <LabelRounded sx={{ fontSize: "1rem" }} />
              <div className="flex gap-1">
                {why.map((reason, index) => (
                  <span key={index} className="text-xs bg-white rounded-full px-2 py-0.5 border border-slate-200">
                    {reason}
                  </span>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
      {text && (
        <>
          <button
            className="inline-flex items-center gap-1 w-fit text-brand-purple"
            onClick={() => setShowText(!showText)}
          >
            {showText ? (
              <>
                <ExpandLessRounded sx={{ fontSize: "1rem" }} /> Hide source text
              </>
            ) : (
              <>
                <ExpandMoreRounded sx={{ fontSize: "1rem" }} /> Show source text
              </>
            )}
          </button>
          {showText && (
            <div
              className="whitespace-normal text-slate-700"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}
            ></div>
          )}
        </>
      )}
    </div>
  );
};

export default SourceItem;
