import { useState } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { highlightPlugin, Trigger } from "@react-pdf-viewer/highlight";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
// Imporing styles to React PDF Viewer
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/highlight/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import type { RenderHighlightsProps } from "@react-pdf-viewer/highlight";
import { twJoin } from "tailwind-merge";
import { HighlightArea } from "@/components/TailwindComponents/UDAPDocumentCard";

const PDFViewer = ({
  fileUrl,
  highlightedAreas,
  selectedArea,
}: {
  fileUrl: string;
  highlightedAreas: HighlightArea[];
  selectedArea?: HighlightArea | null;
}) => {
  const [selectedHighlightArea, setSelectedHighlightArea] = useState<HighlightArea | null>(selectedArea ?? null);
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const makeBold = (keyword: string, text: string) => {
    const regex = new RegExp(`(${keyword})`, "gi");
    return `&quot;${text.replace(regex, "<b>$1</b>")}&quot;`;
  };

  const renderHighlights = (props: RenderHighlightsProps) => {
    return (
      <div>
        {highlightedAreas
          .filter((area) => area.pageIndex === props.pageIndex)
          .map((area, idx) => (
            <div
              key={idx}
              className="highlight-area"
              style={Object.assign(
                {},
                {
                  borderRadius: "0.25rem",
                  border: `2px solid #DC2626`,
                  background: "#DC262633",
                },
                props.getCssProperties(area, props.rotation),
              )}
            />
          ))}
      </div>
    );
  };

  const highlightPluginInstance = highlightPlugin({
    renderHighlights,
    trigger: Trigger.None,
  });

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
      <div className="relative grid grid-cols-[20%_minmax(80%,_1fr)] h-0 min-h-full overflow-y-auto">
        <div className="sticky top-0 left-0 px-5 py-2.5 text-xs text-slate-900 flex flex-col gap-y-2 h-full">
          {/* <span className="font-semibold">Matched Keywords</span> */}
          <div className="flex flex-col ml-2 text-xs overflow-auto h-[46rem] pb-2" id="area-container">
            {highlightedAreas.map((entry, index) => (
              <div className="py-2">
                <button
                  disabled={Number.isNaN(entry?.left)}
                  onClick={() => {
                    setSelectedHighlightArea(entry);
                    highlightPluginInstance.jumpToHighlightArea(entry);
                  }}
                >
                  <div
                    key={index}
                    id={`highlight-${index}`}
                    className={twJoin(
                      "flex flex-col lg:flex-row lg:justify-between p-2 lg:p-4 border border-solid border-red-600 rounded-2xl gap-x-2 gap-y-2",
                      entry?.left === selectedHighlightArea?.left &&
                        entry?.contextText === selectedHighlightArea.contextText &&
                        entry?.top === selectedHighlightArea?.top
                        ? "bg-red-100"
                        : "",
                    )}
                  >
                    <div className="flex flex-col gap-y-3 w-full">
                      <div className="flex justify-between items-center">
                        <div className="w-fit max-w-[12rem] px-2 py-1 text-slate-700 rounded-full bg-slate-50 border border-solid border-[#334155]/20">
                          <p
                            className={twJoin(
                              "font-medium truncate",
                              entry?.left === selectedHighlightArea?.left &&
                                entry?.contextText === selectedHighlightArea.contextText &&
                                entry?.top === selectedHighlightArea?.top
                                ? "text-red-600"
                                : "",
                            )}
                          >
                            {entry?.triggeredKeyword || ""}
                          </p>
                        </div>
                        {entry?.pageIndex ? <span className="text-slate-500">page {entry.pageIndex + 1}</span> : null}
                      </div>
                      {entry?.contextText && entry?.triggeredKeyword ? (
                        <p
                          className="text-sm"
                          dangerouslySetInnerHTML={{
                            __html: makeBold(entry.triggeredKeyword, entry.contextText),
                          }}
                        />
                      ) : null}
                      {entry?.reason ? <p>{entry.reason}</p> : null}
                    </div>
                  </div>
                </button>
              </div>
            ))}
          </div>
        </div>

        <Viewer
          transformGetDocumentParams={(options) => ({ ...options, isEvalSupported: false })}
          fileUrl={fileUrl}
          plugins={[highlightPluginInstance]}
          defaultScale={2.0}
          onDocumentLoad={() => {
            if (selectedArea) {
              highlightPluginInstance.jumpToHighlightArea(selectedArea);
            }
            const areaContainer = document.getElementById("area-container");
            if (areaContainer && selectedHighlightArea) {
              const highlightElement = document.getElementById(
                `highlight-${highlightedAreas.indexOf(selectedHighlightArea)}`,
              );
              if (highlightElement) {
                areaContainer.scrollTo({
                  top: highlightElement.offsetTop - areaContainer.offsetTop,
                  behavior: "smooth",
                });
              }
            }
          }}
        />
      </div>
    </Worker>
  );
};

export default PDFViewer;
