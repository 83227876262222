import { twMerge } from "tailwind-merge";

const StrengthIndicator = ({ matchRating, hideLabel = false }: { matchRating: string; hideLabel?: boolean }) => {
  const getStrengthLabel = (matchRating: string) => {
    switch (matchRating) {
      case "strong_match":
        return "Strong Match";
      case "partial_match":
        return "Partial Match";
      case "weak_match":
        return "Weak Match";
      case "no_match":
        return "No Match";
      default:
        return "No Match";
    }
  };

  return (
    <div className="flex gap-x-2 items-center text-xs">
      <div className="flex items-center w-[7.5rem] h-3 overflow-hidden gap-x-[0.0875rem] shrink-0">
        <div
          className={twMerge(
            "grow h-full rounded-l-full",
            matchRating === "no_match" ? "bg-[#F1F5F9]" : "bg-yellow-500",
          )}
        ></div>
        <div
          className={twMerge(
            "grow h-full",
            ["partial_match", "strong_match"].includes(matchRating) ? "bg-orange-500" : "bg-[#F1F5F9]",
          )}
        ></div>
        <div
          className={twMerge(
            "grow h-full rounded-r-full",
            matchRating === "strong_match" ? "bg-red-500" : "bg-[#F1F5F9]",
          )}
        ></div>
      </div>
      {!hideLabel && (
        <span
          className={twMerge(
            "font-semibold",
            matchRating === "strong_match"
              ? "text-red-700"
              : matchRating === "partial_match"
                ? "text-orange-600"
                : matchRating === "no_match"
                  ? "text-slate-500"
                  : "text-yellow-600",
          )}
        >
          {getStrengthLabel(matchRating)}
        </span>
      )}
    </div>
  );
};

export default StrengthIndicator;
