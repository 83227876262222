import {
  BusinessCenterRounded,
  BusinessRounded,
  CalculateRounded,
  GavelRounded,
  HouseRounded,
  MarkunreadMailboxRounded,
  MonitorRounded,
} from "@mui/icons-material";
import { twJoin } from "tailwind-merge";
import AddressValue from "./AddressValue";
import { snakeCaseToProperCase } from "@/utils";

type SelfAttestedAddressProps = {
  address: {
    street_1: string | null;
    street_2: string | null;
    city: string | null;
    state: string | null;
    country_code: string | null;
    postal_code: string | null;
    address_type: "operations" | "incorporation";
  } | null;
  address_type:
    | "REGISTERED_AGENT"
    | "ACCOUNTANT"
    | "LAWYER"
    | "BUSINESS"
    | "VIRTUAL_OFFICE"
    | "RESIDENTIAL"
    | "PO_BOX"
    | "UNVERIFIED";
  business_name: string;
  explanation: string;
  found_website: string;

  passed: boolean;
};

const SelfAttestedAddressVerificationCard: React.FC<SelfAttestedAddressProps> = ({
  address,
  business_name,
  address_type,
  explanation,
  found_website,
  passed,
}) => {
  const addressLine1 = `${address?.street_1 ?? ""}${address?.street_2 ? `, ${address.street_2}` : ""}`;
  const addressLine2 = `${address?.city ?? ""}${address?.state ? `, ${address.state}` : ""}${
    address?.country_code ? `, ${address.country_code}` : ""
  }${address?.postal_code ? `, ${address.postal_code}` : ""}`;
  const gMapsAddress = encodeURIComponent(addressLine1 + " " + addressLine2).replaceAll(" ", "+");

  const iconClassName = twJoin("text-xs", !passed ? "text-red-500" : "text-slate-400");
  const textClassName = twJoin("text-xs", !passed ? "text-red-500 font-semibold" : "text-slate-700");

  const addressTypeElement = (
    <div className="flex gap-1 items-center">
      {address_type === "REGISTERED_AGENT" ? (
        <BusinessCenterRounded className={iconClassName} sx={{ fontSize: "1rem" }} />
      ) : address_type === "ACCOUNTANT" ? (
        <CalculateRounded className={iconClassName} sx={{ fontSize: "1rem" }} />
      ) : address_type === "LAWYER" ? (
        <GavelRounded className={iconClassName} sx={{ fontSize: "1rem" }} />
      ) : address_type === "BUSINESS" ? (
        <BusinessRounded className={iconClassName} sx={{ fontSize: "1rem" }} />
      ) : address_type === "VIRTUAL_OFFICE" ? (
        <MonitorRounded className={iconClassName} sx={{ fontSize: "1rem" }} />
      ) : address_type === "RESIDENTIAL" ? (
        <HouseRounded className={iconClassName} sx={{ fontSize: "1rem" }} />
      ) : address_type === "PO_BOX" ? (
        <MarkunreadMailboxRounded className={iconClassName} sx={{ fontSize: "1rem" }} />
      ) : null}
      <span className={textClassName}>
        {address_type !== "PO_BOX" ? snakeCaseToProperCase(address_type) : "P.O. Box"}
      </span>
    </div>
  );

  const FoundBusinessNameElement = found_website ? (
    <a
      href={found_website ? (found_website.startsWith("http") ? found_website : `https://${found_website}`) : ""}
      target="_blank"
      rel="noreferrer"
    >
      {business_name ? business_name : found_website}
    </a>
  ) : (
    <span>{business_name}</span>
  );

  return (
    <div
      className={`text-xs border p-4 flex flex-col gap-y-2 rounded-lg ${
        !passed ? "border-red-500 bg-white" : "border-slate-50 bg-slate-50"
      }`}
    >
      <div className="flex justify-between items-center">
        <span className="font-medium text-xs inline-flex gap-x-2 items-center">
          {address?.address_type === "operations"
            ? "Operation Address"
            : address?.address_type === "incorporation"
              ? "Incorporation Address"
              : "Business Address"}
        </span>
      </div>

      <div className="flex w-full gap-x-3">
        <div className="flex flex-col gap-4 w-1/2">
          <div className="flex flex-col gap-2">
            {FoundBusinessNameElement}
            <span>{addressTypeElement}</span>
          </div>
          <AddressValue
            street1={address?.street_1}
            street2={address?.street_2}
            city={address?.city}
            state={address?.state}
            postalCode={address?.postal_code}
            countryCode={address?.country_code}
          />
        </div>
        <a
          href={`https://www.google.com/maps/search/?api=1&query=${gMapsAddress}`}
          target="_blank"
          rel="noreferrer"
          className="block w-1/2 h-[7.5rem] relative group"
        >
          <img
            src={`https://maps.googleapis.com/maps/api/staticmap?center=${gMapsAddress}&zoom=13&size=1200x600&maptype=roadmap&markers=color:red%7C${gMapsAddress}&key=AIzaSyA-5WSnfQmqQYax2f6JUb0_UFxTCVotbes`}
            className="object-cover max-w-[400px] place-self-center w-full h-full rounded-md transition-opacity group-hover:opacity-90"
          />
          <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
            <span className="bg-white text-slate-800 px-2 py-1 rounded text-xs font-medium">View on Google Maps</span>
          </div>
        </a>
      </div>
      {explanation && <p className="mt-2 text-slate-700 text-xs leading-snug">{explanation}</p>}
    </div>
  );
};
export default SelfAttestedAddressVerificationCard;
