import RequestQuote from "@mui/icons-material/RequestQuoteOutlined";
import IncorporationSignatureImg from "../../assets/IncorporatorSignature.png";
import InfoValue from "./InfoValue";
import { OwnerData, UIDocument } from "@/types";
import DocumentValue from "./DocumentValue";
import { twJoin } from "tailwind-merge";
import { WarningRounded } from "@mui/icons-material";

type OwnershipDocumentProps = {
  level: "success" | "failure" | "normal" | undefined;
  businessOwners: OwnerData[];
  ownershipDocuments: UIDocument[];
  unverifiedBusinessOwners?: OwnerData[];
  handleDocumentValidationClick?: (checkResult: any, document: any) => void;
  checkResult?: any;
};

const OwnershipDocument = ({
  level,
  businessOwners,
  unverifiedBusinessOwners,
  ownershipDocuments,
  handleDocumentValidationClick,
  checkResult,
}: OwnershipDocumentProps) => {
  if (!ownershipDocuments || !ownershipDocuments.length) {
    return null;
  }

  return (
    <div className="relative">
      <div
        className={twJoin(
          "relative border w-[272px] left-3.5",
          level === "success" ? "border-green-600" : level === "failure" ? "border-red-600" : "border-slate-200",
        )}
      >
        <div
          className={twJoin(
            "absolute border-l -rotate-45 w-20 h-20 z-10 top-[-40.5px] left-[230px] bg-white",
            level === "success" ? "border-green-600" : level === "failure" ? "border-red-600" : "border-slate-200",
          )}
        />
        <div className="flex flex-col gap-y-4 z-0 p-5 text-xs">
          <div className="flex items-center gap-1 text-[#CECECE]">
            <RequestQuote sx={{ fontSize: "1rem" }} />
            <h2 className="uppercase">Ownership</h2>
          </div>
          <div className="flex flex-col gap-y-5">
            {businessOwners.length > 0 ? (
              <div className="flex flex-col gap-y-2">
                <h3 className="text-green-700">Verified Business Owners</h3>
                {businessOwners?.map((owner) => (
                  <div key={owner.fullName} className="flex flex-col">
                    <span className="uppercase">{owner.fullName}</span>
                    {owner.percentage === null ? (
                      <InfoValue label="Percent Ownership" value="Unknown" />
                    ) : typeof owner.percentage === "number" ? (
                      <InfoValue
                        label="Percent Ownership"
                        value={`${
                          owner.percentage >= 0 && owner.percentage <= 1
                            ? (owner.percentage * 100).toFixed(0)
                            : owner.percentage % 1 === 0
                              ? Math.round(owner.percentage)
                              : owner.percentage.toFixed(2)
                        }%`}
                      />
                    ) : null}
                    {owner.shares === null ? (
                      <InfoValue label="Shares" value="Unknown" />
                    ) : (
                      typeof owner.shares === "number" && (
                        <InfoValue label="Shares" value={`${owner.shares.toLocaleString()}`} />
                      )
                    )}
                  </div>
                ))}
              </div>
            ) : null}
            {unverifiedBusinessOwners && unverifiedBusinessOwners.length > 0 ? (
              <div className="flex flex-col gap-y-2">
                <h3 className="text-red-700">Unverified Business Owners</h3>
                {unverifiedBusinessOwners?.map((owner) => (
                  <div key={owner.fullName} className="flex flex-col ">
                    <span className="uppercase">{owner.fullName}</span>
                    {owner.percentage === null ? (
                      <InfoValue label="Percent Ownership" value="Unknown" />
                    ) : typeof owner.percentage === "number" ? (
                      <InfoValue
                        label="Percent Ownership"
                        value={`${
                          owner.percentage >= 0 && owner.percentage <= 1
                            ? (owner.percentage * 100).toFixed(0)
                            : owner.percentage % 1 === 0
                              ? Math.round(owner.percentage)
                              : owner.percentage.toFixed(2)
                        }%`}
                      />
                    ) : null}
                    {owner.shares === null ? (
                      <InfoValue label="Shares" value="Unknown" />
                    ) : typeof owner.shares === "number" ? (
                      <InfoValue label="Shares" value={`${owner.shares.toLocaleString()}`} />
                    ) : null}
                  </div>
                ))}
              </div>
            ) : null}
          </div>
          <img width="76" height="30" src={IncorporationSignatureImg} alt="incorporator signature" />
          <div>
            <span className="font-medium">Ownership Documents:</span>
            {ownershipDocuments.map((document) => (
              <div className="flex flex-col gap-y-2">
                <DocumentValue
                  key={`${document.fileName}${document.url}`}
                  fileName={document.fileName}
                  url={document.url}
                  maxWidth={150}
                  handleDocumentValidationClick={handleDocumentValidationClick}
                  checkResult={checkResult}
                />
                {document.fraudVerificationData &&
                  document.fraudVerificationData.verification_data
                    .filter((verificationDataEntry) => verificationDataEntry.type === "RISK")
                    .map((verificationDataEntry) => (
                      <div className="flex flex-col gap-y-3">
                        <div className="flex gap-x-2 text-yellow-700">
                          <WarningRounded sx={{ fontSize: "1rem", height: "1rem", width: "1rem" }} />
                          <p className="text-xs">
                            <span className="font-semibold">Fraud Risk:</span> {verificationDataEntry.description}
                          </p>
                        </div>
                      </div>
                    ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OwnershipDocument;
