import { Box } from "@mui/material";
import { StatusMessage, StatusType } from "@parcha/types";
import MessageUI from "../MessageUI";
import { useEffect, useRef } from "react";

interface ContentProps {
  isHighRisk?: boolean;
  isLegacyJob: boolean;
  isParchaUser: boolean;
  jobStatus: StatusType;
  statusMessages: StatusMessage[];
  feedbackData?: { [key: string]: any };
  caseId: string;
  endpointUrl: string;
}

export default function Content({
  isHighRisk = false,
  isLegacyJob = false,
  isParchaUser,
  jobStatus,
  statusMessages,
  feedbackData,
  caseId,
  endpointUrl,
}: ContentProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const isAtBottomRef = useRef(false);

  const handleScroll = (e: any) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    const bottomOffset = scrollHeight - clientHeight - scrollTop;
    isAtBottomRef.current = bottomOffset < 10; // adjust the range as needed
  };
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);

      if (jobStatus === "in progress") {
        container.scrollTop = container.scrollHeight;
      }

      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    const container = containerRef.current;
    if (container && isAtBottomRef.current) {
      // use the ref's current property
      container.scrollTop = container.scrollHeight;
    }
  }, [statusMessages]);

  // this will be probably more naive than it should be.
  // if the last message was by the same agent, we'll just append it to the last message,
  // if not, then show a new one.

  return (
    <Box
      id="audit-log-container"
      m={0}
      p={0}
      sx={{
        overflowY: "auto",
        height: "100%",
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
      ref={containerRef}
    >
      {statusMessages.map(function (currentMessage, i) {
        const lastMessage = statusMessages[i - 1];
        const isSameAgent = lastMessage && lastMessage.content.agent_key == currentMessage.content.agent_key;
        return (
          <MessageUI
            isLegacyJob={isLegacyJob}
            isHighRisk={isHighRisk}
            key={`${currentMessage.content.agent_key}${currentMessage.timestamp}${i}`}
            id={`${currentMessage.content.agent_key}${currentMessage.timestamp}`}
            isParchaUser={isParchaUser}
            appendToPrev={isSameAgent}
            statusMessage={currentMessage}
            feedbackData={feedbackData}
            caseId={caseId}
            endpointUrl={endpointUrl}
          />
        );
      })}
    </Box>
  );
}
