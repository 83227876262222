import { twJoin } from "tailwind-merge";
import { LocationOn, Warning, ChevronLeft, ChevronRight, CheckCircleRounded, StarHalf } from "@mui/icons-material";
import Ratings from "./Ratings";
import { shimmerEffectClassNames } from "../../constants/vars";
import { useRef, useState } from "react";
import CheckSectionStatusMessage from "../V2_Home_Page/PublicBDDComponents/sections/CheckSectionStatusMessage";
import GenericSkeleton from "../V2_Home_Page/PublicBDDComponents/sections/GenericSkeleton";
import Alert from "./Alert";
import { format } from "date-fns";
import { getFormattedAddressOneLine } from "@/utils";
import SmartSkeleton from "./SmartSkeleton";
import { Tooltip } from "@mui/material";

const SOURCES_TO_DOMAINS_MAP: Record<string, string> = {
  google_places: "google.com",
};

export default function PhysicalLocationReviews({
  checkResult,
}: {
  checkResult?: {
    check_result: {
      business_name: string;
      business_status: "OPERATIONAL" | "CLOSED" | "PERMANENTLY_CLOSED" | "TEMPORARILY_CLOSED" | "UNKNOWN" | null;
      business_summary: string;
      avg_score: number;
      num_reviews: number;
      high_risk_mentions:
        | {
            topic: string;
            num_mentions: number;
          }[]
        | null;
      suspectful_reviews: {
        date: string;
        score: number;
        source: "google_places" | "yelp";
        review_text: string;
        suspect_reason: string;
      }[];
      business_images: string[];
      business_address: {
        street_1: string;
        street_2: string;
        city: string;
        state: string;
        country_code: string;
        postal_code: string;
        type: "Address";
      };
    } | null;
    verified_data: {
      google_review_results: {
        reviews: {
          stars: number;
          text: string;
          name: string;
        }[];
        business_info: {
          url: string;
        };
      };
    };
    status: "pending" | "running" | "complete";
    passed: boolean | null;
    answer: string | null;
    status_messages: {
      event: string | null;
      content: {
        status: string | null;
      };
    }[];
  };
}) {
  const Header = (
    <div className="flex items-center gap-x-2">
      <StarHalf sx={{ fontSize: "1.25rem" }} className="text-slate-700" />
      <span
        className={twJoin(
          "text-xl font-semibold",
          ["pending", "running"].includes(checkResult?.status || "") ? shimmerEffectClassNames : "",
        )}
      >
        Reviews
      </span>
      {checkResult?.passed && (
        <Tooltip arrow title={checkResult.answer} className="w-4 h-4">
          <CheckCircleRounded sx={{ fontSize: "1rem" }} className="text-green-600" />
        </Tooltip>
      )}
    </div>
  );

  const filteredStatusMessages =
    checkResult?.status_messages?.filter(
      (message: { event: string | null; content: { status: string | null } }) =>
        message.event === "status" && !message.content.status?.startsWith("Loading:"),
    ) ?? [];
  const lastStatusMessage =
    filteredStatusMessages.length > 0 ? filteredStatusMessages[filteredStatusMessages.length - 1] : null;

  if (["pending", "running"].includes(checkResult?.status || "")) {
    if (!checkResult?.verified_data) {
      return (
        <div className="flex flex-col gap-y-2 rounded-lg md:w-full">
          {Header}
          {lastStatusMessage && <CheckSectionStatusMessage message={lastStatusMessage.content.status || ""} />}
          <GenericSkeleton />
        </div>
      );
    } else {
      const suspectGoogleReviews =
        checkResult.verified_data.google_review_results.reviews?.sort((a: any, b: any) => a.stars - b.stars) || [];

      const evidenceList = suspectGoogleReviews.map((review: any) => ({
        found: `${review.name} reviewed this place with ${review.stars} star${review.stars === 1 ? "" : "s"}`,
        evidence: review.text,
      }));

      return (
        <div className="flex flex-col gap-y-5 rounded-lg md:w-full">
          {Header}
          {evidenceList.length > 0 ? (
            <div className="h-[200px]">
              <SmartSkeleton evidenceList={evidenceList} />
            </div>
          ) : (
            lastStatusMessage && <CheckSectionStatusMessage message={lastStatusMessage.content.status || ""} />
          )}
        </div>
      );
    }
  }

  const payload = checkResult?.check_result;

  const businessName = payload?.business_name;
  const businessStatus = payload?.business_status;
  const businessSummary = payload?.business_summary;
  const avgScore = payload?.avg_score;
  const numReviews = payload?.num_reviews;
  const highRiskMentions = payload?.high_risk_mentions || [];
  const suspectfulReviews = payload?.suspectful_reviews;
  const businessImages = payload?.business_images;

  return (
    <div className="flex flex-col gap-y-5 rounded-lg md:w-full">
      {Header}
      {!checkResult?.passed && <Alert message={checkResult?.answer || ""} level="failure" />}
      <div className="text-xs">
        <div className="flex justify-between items-start mb-4">
          <div className="flex flex-col gap-1">
            <div className="flex items-center gap-2">
              <h3 className="text-lg font-semibold">{businessName}</h3>
              <div
                //OPERATIONAL, PERMANENTLY_CLOSED, TEMPORARILY_CLOSED, UNKNOWN
                className={twJoin(
                  "text-xs font-semibold px-2.5 py-0.5 rounded-full inline-block",
                  ["CLOSED", "PERMANENTLY_CLOSED", "TEMPORARILY_CLOSED"].includes(businessStatus || "") &&
                    "bg-red-100 text-red-800",
                  businessStatus === "OPERATIONAL" && "bg-green-100 text-green-800",
                  businessStatus === "UNKNOWN" && "bg-slate-100 text-slate-800",
                )}
              >
                {["CLOSED", "PERMANENTLY_CLOSED", "TEMPORARILY_CLOSED"].includes(businessStatus || "") ? (
                  <span>Out of Business</span>
                ) : businessStatus === "OPERATIONAL" ? (
                  <span>Operational</span>
                ) : (
                  <span>Unknown</span>
                )}
              </div>
            </div>
            {payload?.business_address && (
              <div className="flex items-center gap-1 text-slate-700 text-xs">
                <LocationOn sx={{ fontSize: "1rem" }} className="text-slate-500" />
                {checkResult?.verified_data?.google_review_results?.business_info?.url ? (
                  <a
                    href={checkResult?.verified_data.google_review_results.business_info.url}
                    target="_blank"
                    className="text-brand-purple hover:cursor-pointer"
                    rel="noreferrer"
                  >
                    <span>{getFormattedAddressOneLine(payload?.business_address)}</span>
                  </a>
                ) : (
                  <span>{getFormattedAddressOneLine(payload?.business_address)}</span>
                )}
              </div>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <div className="flex gap-2 items-center text-right">
              <span className="text-xl font-semibold">{avgScore || 0}</span>
              <Ratings rating={avgScore || 0} size="medium" />
            </div>
            {checkResult?.verified_data?.google_review_results?.business_info?.url ? (
              <a
                href={checkResult?.verified_data.google_review_results.business_info.url}
                target="_blank"
                className="text-brand-purple hover:cursor-pointer"
                rel="noreferrer"
              >
                {numReviews} Customer Reviews
              </a>
            ) : (
              <span className="text-slate-500">{numReviews} Customer Reviews</span>
            )}
          </div>
        </div>

        {businessSummary !== undefined && businessSummary?.length > 0 && (
          <>
            <h4 className="font-semibold mb-2">Business Summary from the Reviews</h4>
            <p className="text-slate-700 mb-4">{businessSummary}</p>
          </>
        )}

        {highRiskMentions.length > 0 && (
          <div className="flex flex-wrap items-center gap-2">
            <h4 className="font-semibold flex items-center gap-2">
              <Warning sx={{ fontSize: "1rem" }} className="w-4 h-4 text-yellow-600" /> High Risk Mentions
            </h4>
            {highRiskMentions
              .sort((a, b) => b.num_mentions - a.num_mentions)
              .map((mention: { num_mentions: number; topic: string }, index: number) => (
                <span
                  key={index}
                  className="inline-flex whitespace-nowrap gap-1 items-center border border-slate-300 bg-slate-50 text-slate-800 font-medium text-xs px-2 py-1 rounded-full"
                >
                  <span className="truncate">{mention.topic}</span>
                  <span>{mention.num_mentions}</span>
                </span>
              ))}
          </div>
        )}
        {/* Individual reviews */}
        <div className="mt-6 space-y-5">
          {suspectfulReviews?.map((review: any, index: number) => (
            <div key={index} className="flex flex-col gap-2">
              <div className="flex items-center gap-2 text-xs">
                <img
                  src={`https://www.google.com/s2/favicons?domain=${SOURCES_TO_DOMAINS_MAP[review.source]}`}
                  alt={`${SOURCES_TO_DOMAINS_MAP[review.source] || review.source} favicon`}
                  className="w-4 h-4 rounded-full"
                />
                {review.date && <div className="text-slate-500">{format(new Date(review.date), "MMMM d, yyyy")}</div>}
                <div className="flex">
                  <Ratings rating={review.score} size="small" />
                </div>
              </div>
              <ExpandableReviewText text={review.review_text} index={index} />
            </div>
          ))}
        </div>

        {/* Photos section */}
        {businessImages && businessImages.length > 0 && (
          <div className="mt-6">
            <h4 className="font-semibold mb-2">Photos from Reviews</h4>
            <div className="relative">
              <div className="flex overflow-x-auto gap-2 pb-4">
                {businessImages?.map((image: any, index: number) => (
                  <a href={image} key={index} target="_blank" rel="noreferrer">
                    <img
                      key={index}
                      src={image}
                      alt={`Business Image ${index + 1}`}
                      className="w-48 h-32 flex-shrink-0 rounded object-cover"
                    />
                  </a>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const ExpandableReviewText = ({ text, index }: { text: string; index: number }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const MAX_TEXT_LENGTH = 350;

  return (
    <div className="flex flex-col items-baseline">
      <div className={`relative ${isExpanded ? "h-fit" : "h-[3em] overflow-hidden"}`}>
        <p className="text-gray-700">{text}</p>
        {text.length > MAX_TEXT_LENGTH && !isExpanded && (
          <div className="absolute bottom-0 left-0 w-full h-5 bg-gradient-to-t from-white to-transparent"></div>
        )}
      </div>
      {text.length > MAX_TEXT_LENGTH && (
        <button
          className="self-end text-brand-purple hover:underline focus:outline-none"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? "read less" : "read more"}
        </button>
      )}
    </div>
  );
};
