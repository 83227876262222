import { Descope, getRefreshToken } from "@descope/react-sdk";
import { Grid, Snackbar } from "@mui/material";
import useAuth from "../hooks/useAuth";
import { Navigate, useSearchParams } from "react-router-dom";
import { useState } from "react";

export default function SignIn() {
  const { isLoading, isAuthenticated, user } = useAuth();
  const [searchParams] = useSearchParams();
  const [showNotAllowedMsg, setShowNotAllowedMsg] = useState(searchParams.get("authorized") === "false");

  const isUserWithoutTenant = user && user.userTenants?.length === 0;

  if (isLoading) return null;

  if (isAuthenticated) {
    const redirectTo = searchParams.get("redirectTo");

    if (redirectTo) {
      window.location.replace(redirectTo);
      return null;
    }

    return isUserWithoutTenant ? <Navigate to="/reports" /> : <Navigate to="/jobs" />;
  }

  return (
    <Grid container justifyContent="center" mt={8}>
      <Grid item xs={11} md={4}>
        <Snackbar
          message="You don't have access, please contact support@parcha.ai"
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showNotAllowedMsg}
          onClose={() => setShowNotAllowedMsg(false)}
          autoHideDuration={2500}
        />
        <Descope flowId="sign-up-or-in-magic-link-no-free-email" />
      </Grid>
    </Grid>
  );
}
