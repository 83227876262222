import React, { useState } from "react";
import {
  Business,
  LocationOn,
  VisibilityRounded,
  SearchRounded,
  ThumbsUpDownRounded,
  SummarizeRounded,
  LanguageRounded,
} from "@mui/icons-material";
import { snakeCaseToProperCase } from "@/utils";
import { WebRelevanceCheckResult } from "@/types";

type WebRelevanceCardProps = {
  payload: WebRelevanceCheckResult;
};

const WebRelevanceCard: React.FC<WebRelevanceCardProps> = ({ payload }) => {
  const { extracted_metadata: metadata } = payload;

  if (!metadata) {
    return null;
  }

  return (
    <div className="w-full bg-white border border-slate-200 rounded-lg overflow-hidden mt-2 p-6 text-xs">
      <div className="space-y-5">
        <div className="flex flex-col gap-3">
          <h3 className="text-sm font-semibold flex items-center gap-2">
            <Business sx={{ fontSize: "1rem" }} className="text-brand-purple" />
            Business Details
          </h3>
          <div className="space-y-2">
            <p>
              <strong>Name:</strong> {metadata.found_business_name}
            </p>
            <p>
              <strong>Country:</strong> {metadata.found_business_country || "Not specified"}
            </p>
            <p>
              <strong>Website Type:</strong> {snakeCaseToProperCase(metadata.webpage_type)}{" "}
            </p>
          </div>
        </div>

        {metadata.visual_summary && (
          <div>
            <h3 className="text-sm font-semibold mb-2 flex items-center">
              <VisibilityRounded sx={{ fontSize: "1rem" }} className="mr-2 text-brand-purple" />
              Visual Summary
            </h3>
            <p className="text-slate-700">{metadata.visual_summary}</p>
          </div>
        )}

        <div className="space-y-3">
          <h3 className="text-sm font-semibold flex items-center gap-2">
            <SearchRounded sx={{ fontSize: "1rem" }} className="text-brand-purple" />
            Keywords searched
          </h3>
          <div className="flex flex-wrap gap-2">
            {metadata.search_keywords.map((keyword, index) => (
              <span
                key={index}
                className="bg-slate-50 border border-slate-200 text-slate-700 px-2 py-1 rounded-full text-xs"
              >
                {keyword}
              </span>
            ))}
          </div>
        </div>

        <div className="space-y-3">
          <h3 className="text-sm font-semibold flex items-center gap-2">
            <LocationOn sx={{ fontSize: "1rem" }} className="text-brand-purple" />
            URLs to Analyze
          </h3>
          <ul className="list-inside space-y-2">
            {metadata.urls_to_analyze.map((url, index) => (
              <li key={index} className="truncate">
                <URLEntry url={url} />
              </li>
            ))}
          </ul>
        </div>

        <div className="space-y-5">
          <h3 className="text-sm font-semibold flex items-center gap-2">
            <SummarizeRounded sx={{ fontSize: "1rem" }} className="text-brand-purple" />
            Summary
          </h3>
          <div className="space-y-2">
            <h4 className="font-medium">Business Description</h4>
            {metadata.simple_profile.business_description.map((description, index) => {
              return <p key={index}>{description}</p>;
            })}
          </div>
          {(metadata.simple_profile.business_products_and_services.products_and_services_list.length > 0 ||
            metadata.simple_profile.business_products_and_services.products_and_services_summary) && (
            <div className="space-y-2">
              <h4 className="font-medium">Products and Services</h4>
              {metadata.simple_profile.business_products_and_services.products_and_services_summary && (
                <p className="text-slate-700">
                  {metadata.simple_profile.business_products_and_services.products_and_services_summary}
                </p>
              )}
              {metadata.simple_profile.business_products_and_services.products_and_services_list.length > 0 && (
                <ol className="list-decimal list-inside text-slate-700 space-y-2">
                  {metadata.simple_profile.business_products_and_services.products_and_services_list.map(
                    (product, index) => {
                      return (
                        <li key={index}>
                          <span className="font-semibold">{product.name}</span>: <span>{product.description}</span>
                        </li>
                      );
                    },
                  )}
                </ol>
              )}
            </div>
          )}
          {metadata.simple_profile.business_customers?.length > 0 && (
            <div className="space-y-2">
              <h4 className="font-medium">Current Customers</h4>
              <ol className="list-decimal list-inside text-slate-700 space-y-2">
                {metadata.simple_profile.business_customers.map((customer, index) => {
                  return <li key={index}>{customer}</li>;
                })}
              </ol>
            </div>
          )}
        </div>
        <div className="space-y-5">
          <h3 className="text-sm font-semibold flex items-center gap-2">
            <ThumbsUpDownRounded sx={{ fontSize: "1rem" }} className="text-brand-purple" />
            Relevance Analysis
          </h3>
          <div className="flex flex-col gap-5 text-xs">
            <div className="flex flex-col gap-1">
              <h4 className="font-medium text-green-700">Reasons for relevance:</h4>
              <p className="text-slate-700">{payload.reason_for_relevance || "None"}</p>
            </div>
            <div className="flex flex-col gap-1">
              <h4 className="font-medium text-red-700">Reasons against relevance:</h4>
              <p className="text-slate-700">{payload.reason_against_relevance || "None"}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const URLEntry = ({ url }: { url: string }) => {
  const [iconLoadingError, setIconLoadingError] = useState(false);

  const domain = url ? new URL(url).hostname : "";

  return (
    <div className="line-clamp-1 flex items-center">
      {domain &&
        (!iconLoadingError ? (
          <img
            src={`https://www.google.com/s2/favicons?domain=${domain}`}
            alt={`${domain} favicon`}
            className="w-4 h-4 mr-2 rounded-full"
            onError={({ currentTarget }) => {
              currentTarget.style.display = "none";
              setIconLoadingError(true);
            }}
          />
        ) : (
          <LanguageRounded sx={{ fontSize: "1rem" }} className="mr-2" />
        ))}
      {url && (
        <div className="text-xs truncate text-slate-700">
          {url ? (
            <a
              href={url.startsWith("http") ? url : `https://${url}`}
              target="_blank"
              rel="noreferrer"
              className="text-ellipsis overflow-hidden whitespace-nowrap hover:underline"
            >
              {url}
            </a>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default WebRelevanceCard;
