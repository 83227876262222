import { twMerge } from "tailwind-merge";

type Shape = "rounded" | "square" | "circular";

type SkeletonProps = {
  width?: string;
  height?: string;
  shape?: Shape;
  additionalClasses?: string;
  children?: React.ReactNode;
};

const Skeleton = ({
  children,
  width = "w-full",
  height = "h-4",
  shape = "rounded",
  additionalClasses = "",
}: SkeletonProps) => {
  const baseClasses = "bg-slate-200 animate animate-pulse";
  const shapeClasses: { [key in "rounded" | "square" | "circular"]: string } = {
    rounded: "rounded-md",
    square: "",
    circular: "rounded-full",
  };
  const mergedClasses = twMerge(baseClasses, shapeClasses[shape as Shape], width, height, additionalClasses);

  return <div className={mergedClasses}>{children}</div>;
};

export default Skeleton;
