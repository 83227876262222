// @ts-nocheck
import { ChangeEvent, Fragment, Key, useEffect, useState } from "react";
import ParchaLogo from "../../assets/Parcha_Logo_With_Name.png";
import BusinessDueDiligenceImage from "../../assets/biz report skeleton 1.svg";
import WebsiteCard from "./WebsiteCard";
import {
  FactoryRounded,
  LanguageRounded,
  PolicyRounded,
  PeopleRounded,
  WarningRounded,
  Pin,
  CheckRounded,
  CancelRounded,
  BusinessRounded,
  PublicRounded,
  AutoAwesomeRounded,
  ImageSearch,
  NewspaperRounded,
  VisibilityRounded,
  SubdirectoryArrowRightRounded,
  CheckCircleRounded,
  WorkspacePremiumRounded,
  GroupRounded,
  TaskRounded,
  ExpandLessRounded,
  ExpandMoreRounded,
  PlagiarismRounded,
  CloseRounded,
  PersonRounded,
  LinkRounded,
  ErrorOutline,
} from "@mui/icons-material";
import { Dialog, Transition } from "@headlessui/react";
import FindingsSource from "@/components/TailwindComponents/FindingsSource";
import FindingsSection from "./FindingsSection";
import WebPresenceCard from "./WebPresenceCard";
import InstagramProfileCard from "./InstagramProfileCard";
import XTwitterProfileCard from "./XTwitterProfileCard";
import LinkedInCompanyCard from "./LinkedInCompanyCard";
import FacebookProfileCard from "./FacebookProfileCard";
import Alert from "./Alert";

import SourceTagCircle from "./SourceTagCircle";
import WebsitePolicyCard from "./WebsitePolicyCard";

import { twJoin, twMerge } from "tailwind-merge";
import { useNavigate } from "react-router-dom";
import WrappedValue from "./WrappedValue";
import AdverseMediaHit from "./AdverseMediaHit";
import SanctionsWatchlistCard from "./SanctionsWatchlistCard";
import AnimatedParagraph from "./AnimatedParagraph";
import { motion } from "framer-motion";
import { BDDInputWithOverlappingLabel } from "../V2_Home_Page/PublicReportForm";
import COUNTRIES from "@/data/countries";
import { v4 as uuidv4 } from "uuid";
import { useParchaApi } from "@/hooks/useParchaApi";
import SmartSkeleton from "./SmartSkeleton";
import { CircularProgress } from "@mui/material";
import CrunchbaseCompanyCard from "./CrunchbaseCompanyCard";
import CheckStatusMessage from "./CheckStatusMessage";
import { Tooltip } from "@mui/material";
import UpsellSectionWrapper from "./UpsellSectionWrapper";
import LegalBusinessEntitiesImg from "../../assets//LegalBusinessEntities.png";
import BusinessOwnersImg from "../../assets//BusinessOwners.png";
import IncorporationDocsImg from "../../assets//IncorporationDocs.png";
import BusinessBasicProfile from "../V2_Home_Page/PublicBDDComponents/sections/BusinessBasicProfile";
import {
  BUSINESS_PROFILE_CHECK_ID,
  WEB_PRESENCE_CHECK_ID,
  ADDRESS_CHECK_ID,
  HIGH_RISK_COUNTRY_CHECK_ID,
  HIGH_RISK_INDUSTRY_CHECK_ID,
  HIGH_RISK_INDUSTRY_V2_CHECK_ID,
  ADVERSE_MEDIA_CHECK_ID,
  SANCTIONS_WATCHLIST_CHECK_ID,
  POLICY_CHECK_ID,
  MCC_CHECK_ID,
  MCC_V2_CHECK_ID,
  BUSINESS_OWNERS_CHECK_ID,
  INCORPORATION_DOCUMENT_CHECK_ID,
  BUSINESS_OWNERSHIP_CHECK_ID,
  CANNABIS_LICENSE_CHECK_ID,
  EIN_DOCUMENT_CHECK_ID,
  PROOF_OF_ADDRESS_CHECK_ID,
  SOURCE_OF_FUNDS_CHECK_ID,
  BUSINESS_REVIEWS_CHECK_ID,
  shimmerEffectClassNames,
} from "@/constants/vars";
import Addresses from "../V2_Home_Page/PublicBDDComponents/sections/Addresses";
import HighRiskCountries from "../V2_Home_Page/PublicBDDComponents/sections/HighRiskCountries";
import CheckSectionStatusMessage from "../V2_Home_Page/PublicBDDComponents/sections/CheckSectionStatusMessage";
import GenericSkeleton from "../V2_Home_Page/PublicBDDComponents/sections/GenericSkeleton";
import Skeleton from "./Skeleton";
import DocumentList from "../V2_Home_Page/PublicBDDComponents/sections/DocumentList";
import DocumentValidation from "./DocumentValidation";
import InstagramWebsiteCard from "./InstagramWebsiteCard";
import numeral from "numeral";
import PhysicalLocationReviews from "./PhysicalLocationReviews";

const ParchaReport = ({ agent, jobMetadata, disableAnimation = false }: any) => {
  if (!jobMetadata) return null;

  const job = jobMetadata.job;

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    business_name: job?.input_payload?.self_attested_data.business_name || "",
    registered_business_name: job?.input_payload?.self_attested_data.registered_business_name || "",
    description: job?.input_payload?.self_attested_data.description || "",
    country_of_operation: job?.input_payload?.self_attested_data.address_of_operation?.country_code || "",
  });
  const [formErrors, setFormErrors] = useState<{ country_of_operation: string | null }>({
    country_of_operation: null,
  });

  const [error, setError] = useState("");
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);
  const [activeSource, setActiveSource] = useState<string | null>(null);
  const [showingSources, setShowingSources] = useState(false);
  const [selectedDocumentCheck, setSelectedDocumentCheck] = useState<string | null>(null);

  const envTier = import.meta.env.VITE_ENV_TIER;
  const endpointUrl =
    envTier === "development" ? "localhost:8001" : envTier === "staging" ? "staging.parcha.ai" : "demo.parcha.ai";

  useEffect(() => {
    if (job.status === "complete") {
      setShowingSources(true);
    }
  }, [job.status]);

  const toggleShowingSources = () => {
    setShowingSources(!showingSources);
  };

  const parchaApi = useParchaApi();

  const onFieldChangeHandler = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const value = e.target.value;
    if (e.target.name === "country_of_operation") {
      if (value === "") {
        setFormErrors({
          ...formErrors,
          [e.target.name]: "Please select a country of operation",
        });
      } else {
        setFormErrors({ ...formErrors, [e.target.name]: null });
      }
    } else {
      setFormErrors({ ...formErrors, [e.target.name]: null });
    }
    setFormData({ ...formData, [e.target.name]: value.replaceAll("https://", "").replaceAll("http://", "") });
  };

  const handleOnSubmit = (e: SubmitEvent) => {
    e.preventDefault();
    setIsGeneratingReport(true);
    const input = {
      agent_key: job?.agent_id,
      kyb_schema: {
        id: `parcha-${uuidv4().replace(/-/g, "").substring(0, 8)}`,
        self_attested_data: {
          business_name: formData.business_name.length > 0 ? formData.business_name : undefined,
          registered_business_name:
            formData.registered_business_name.length > 0 ? formData.registered_business_name : undefined,
          address_of_operation: formData.country_of_operation
            ? {
                country_code: formData.country_of_operation,
              }
            : undefined,
          description: formData.description.length > 0 ? formData.description : undefined,
        },
      },
      run_in_parallel: true,
    };

    parchaApi.enqueueAgent(endpointUrl, input, "kyb").then((res) => {
      if (res.job_id) {
        window.location =
          job.agent_id === "public-bdd" ? `/reports/${res.job_id}` : `/jobs/${job.agent_id}/${res.job_id}`;
      } else {
        setError(res.message);
      }
      setIsGeneratingReport(false);
    });
  };

  const agentInstanceId = "kyb" in jobMetadata ? Object.keys(jobMetadata["kyb"])[0] : null;
  const agentInstance = agentInstanceId ? jobMetadata["kyb"][agentInstanceId] : null;
  const sourcesValues = agentInstance?.sources;
  const sourcesDictionary = sourcesValues?.reduce(
    (acc: { [x: string]: any }, source: { source_id: string | number }, index: number) => {
      acc[source.source_id] = { ...source, id: index + 1 };
      return acc;
    },
    {},
  );

  const website = jobMetadata?.job?.input_payload?.self_attested_data?.website;

  const handleSourceClick = (id) => {
    const sourceElement = document.getElementById(`source-${id}`);
    if (sourceElement) {
      sourceElement.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
    } else {
      setShowingSources(true);
      setTimeout(() => {
        handleSourceClick(id);
      }, 200);
    }
  };

  if (job?.status === "failed" && job?.agent_id === "public-bdd") {
    return (
      <div className="w-[400px] flex justify-center py-[1.75rem] mx-auto">
        <div className=" flex flex-col items-center gap-y-[3.75rem]">
          <div className="flex items-center flex-col gap-y-[2.5rem]">
            <div className="flex items-center justify-center w-[4rem] h-[4rem] bg-indigo-100 rounded-full">
              <WarningRounded sx={{ fontSize: "2rem" }} className="text-brand-purple" />
            </div>
            <div className="flex flex-col gap-y-5">
              <span className="text-center font-semibold text-xl">
                We were unable to gather enough information from the website provided.
              </span>
              <span className="text-center text-base text-slate-500">
                Please check that the information is correct and provide any additional information for us to do an
                updated search.
              </span>
            </div>
          </div>
          <form onSubmit={handleOnSubmit} className="w-full flex flex-col gap-y-10 justify-center mx-auto">
            <div className="flex flex-col gap-y-10">
              <div className="flex flex-col gap-y-8">
                <BDDInputWithOverlappingLabel
                  label="Business Name"
                  placeholder="Enter a business of interest"
                  required
                  fieldName="business_name"
                  value={formData.business_name}
                  onChangeHandler={onFieldChangeHandler}
                  icon={<BusinessRounded sx={{ fontSize: "1.25rem" }} className="text-slate-500" />}
                />
                <BDDInputWithOverlappingLabel
                  label={
                    <>
                      Required Business Name <span className="font-thin">(Optional)</span>
                    </>
                  }
                  placeholder="Provide the name used for business registration"
                  fieldName="registered_business_name"
                  value={formData.registered_business_name}
                  onChangeHandler={onFieldChangeHandler}
                  icon={<BusinessRounded sx={{ fontSize: "1.25rem" }} className="text-slate-500" />}
                />
                <div className="relative">
                  <label
                    htmlFor="description"
                    className="absolute -top-2 left-2 inline-block bg-white px-1 text-sm font-medium text-slate-900 w-auto truncate"
                  >
                    Business Description
                    <span className="text-red-500 ml-0.5">*</span>
                  </label>
                  <textarea
                    rows={4}
                    name="description"
                    id="description"
                    required
                    placeholder="Give a brief explanation of what the business does"
                    onChange={onFieldChangeHandler}
                    className="box-border block w-full rounded-md py-3 text-slate-900 ring-1 ring-inset ring-slate-100 border border-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-brand-purple"
                    value={formData.description}
                  />
                </div>
                <div className="relative">
                  <label className="absolute -top-2 left-2 inline-block bg-white px-1 text-sm font-medium text-slate-900 w-auto truncate">
                    Country <span className="text-red-500 ml-0.5">*</span>
                  </label>

                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-4">
                    <PublicRounded sx={{ fontSize: "1.25rem" }} className="text-slate-500" />
                  </div>

                  <select
                    id="country_of_operation"
                    name="country_of_operation"
                    className={twMerge(
                      "box-border block w-full rounded-md py-3 pl-12 ring-1 ring-inset ring-slate-100 border border-slate-300 focus:ring-2 focus:ring-inset focus:ring-brand-purple",
                      formData.country_of_operation === "" ? "text-slate-400" : "text-slate-900",
                    )}
                    value={formData.country_of_operation}
                    onChange={onFieldChangeHandler}
                    required
                  >
                    <option value="" disabled>
                      Select a country of operation
                    </option>
                    {COUNTRIES.map((country) => (
                      <option key={country.value} value={country.value}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            {error && <Alert message={error} level="failure" />}
            <button
              type="submit"
              className="w-[18rem] place-self-center justify-center inline-flex items-center gap-x-1.5 rounded-md bg-brand-purple px-3 py-2 text-sm md:text-base font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-purple disabled:opacity-60 disabled:cursor-not-allowed"
              disabled={
                !formData.business_name.length ||
                !formData.description.length ||
                !formData.country_of_operation ||
                isGeneratingReport
              }
            >
              {isGeneratingReport ? (
                <>
                  <CircularProgress color="inherit" size="1.25rem" className="-ml-2 animate-spin text-white h-5 w-5" />
                  Generating Report...
                </>
              ) : (
                <>
                  <AutoAwesomeRounded className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                  Generate Compliance Report
                </>
              )}
            </button>
          </form>
        </div>
      </div>
    );
  }

  const businessReviewsCheckResult = agentInstance?.check_results.find(
    (checkResult: { command_id: string }) => checkResult.command_id === BUSINESS_REVIEWS_CHECK_ID,
  );

  const businessBasicProfileCheckResult = agentInstance?.check_results.find(
    (checkResult: { command_id: string }) => checkResult.command_id == BUSINESS_PROFILE_CHECK_ID,
  );

  const webPresenceCheckResult = agentInstance?.check_results.find(
    (checkResult: { command_id: string }) => checkResult.command_id === WEB_PRESENCE_CHECK_ID,
  );
  const addressesCheckResult = agentInstance?.check_results.find(
    (checkResult: { command_id: string }) => checkResult.command_id === ADDRESS_CHECK_ID,
  );
  const highRiskCountriesCheckResult = agentInstance?.check_results.find(
    (checkResult: { command_id: string }) => checkResult.command_id === HIGH_RISK_COUNTRY_CHECK_ID,
  );

  const highRiskIndustriesCheckResult = agentInstance?.check_results.find(
    (checkResult: { command_id: string }) => checkResult.command_id === HIGH_RISK_INDUSTRY_CHECK_ID,
  );

  const highRiskIndustriesV2CheckResult = agentInstance?.check_results.find(
    (checkResult: { command_id: string }) => checkResult.command_id === HIGH_RISK_INDUSTRY_V2_CHECK_ID,
  );

  const adverseMediaCheckResult = agentInstance?.check_results.find(
    (checkResult: { command_id: string }) => checkResult.command_id === ADVERSE_MEDIA_CHECK_ID,
  );
  const sanctionsWatchlistCheckResult = agentInstance?.check_results.find(
    (checkResult: { command_id: string }) => checkResult.command_id === SANCTIONS_WATCHLIST_CHECK_ID,
  );
  const policiesCheckResult = agentInstance?.check_results.find(
    (checkResult: { command_id: string }) => checkResult.command_id === POLICY_CHECK_ID,
  );
  const mccCheckResult = agentInstance?.check_results.find(
    (checkResult: { command_id: string }) =>
      checkResult.command_id === MCC_CHECK_ID || checkResult.command_id === MCC_V2_CHECK_ID,
  );
  const businessOwnersCheckResult = agentInstance?.check_results.find(
    (checkResult: { command_id: string }) => checkResult.command_id === BUSINESS_OWNERS_CHECK_ID,
  );

  /**
   * Document Checks Start
   */
  const incorporationDocumentCheckResult = agentInstance?.check_results.find(
    (checkResult: { command_id: string }) => checkResult.command_id === INCORPORATION_DOCUMENT_CHECK_ID,
  );
  const businessOwnershipCheckResult = agentInstance?.check_results.find(
    (checkResult: { command_id: string }) => checkResult.command_id === BUSINESS_OWNERSHIP_CHECK_ID,
  );
  const sourceOfFundsCheckResult = agentInstance?.check_results.find(
    (checkResult: { command_id: string }) => checkResult.command_id === SOURCE_OF_FUNDS_CHECK_ID,
  );
  const proofOfAddressCheckResult = agentInstance?.check_results.find(
    (checkResult: { command_id: string }) => checkResult.command_id === PROOF_OF_ADDRESS_CHECK_ID,
  );
  const cannabisLicenseCheckResult = agentInstance?.check_results.find(
    (checkResult: { command_id: string }) => checkResult.command_id === CANNABIS_LICENSE_CHECK_ID,
  );
  const einDocumentCheckResult = agentInstance?.check_results.find(
    (checkResult: { command_id: string }) => checkResult.command_id === EIN_DOCUMENT_CHECK_ID,
  );
  /**
   * Document Checks End
   * */
  const documentCheckResults = [
    incorporationDocumentCheckResult,
    businessOwnershipCheckResult,
    sourceOfFundsCheckResult,
    proofOfAddressCheckResult,
    cannabisLicenseCheckResult,
    einDocumentCheckResult,
  ].filter(Boolean);

  const currentStatusMessage = webPresenceCheckResult?.status_messages
    .filter(
      (msg: { event: string; content: { status: string } }) =>
        msg.event === "status" && msg.content.status !== "Loading: WebPresence Data Loader...",
    )
    .pop();

  const getJobName = () => {
    if (!job?.input_payload?.self_attested_data) return "";

    return (
      job.input_payload?.self_attested_data?.name ||
      job.input_payload?.self_attested_data?.business_name ||
      job.input_payload?.self_attested_data?.registered_business_name ||
      job.input_payload?.self_attested_data?.website?.replace(/https?:\/\/(www\.)?|www\./gi, "") ||
      `${job.input_payload?.self_attested_data?.first_name}${
        job.input_payload?.self_attested_data?.middle_name
          ? ` ${job.input_payload?.self_attested_data?.middle_name} `
          : " "
      }${job.input_payload?.self_attested_data?.last_name}`
    );
  };

  if (!job || !agentInstanceId)
    return (
      <div className="md:px-[2.5rem] py-10 flex flex-col gap-y-6 h-full max-w-[1120px]">
        <div className="bg-indigo-100 pr-10 pl-6 pt-6 rounded-xl flex flex-col gap-y-6 w-full">
          <img src={ParchaLogo} alt="Parcha Logo" className="object-cover self-start md:h-7 h-4" />
          <div className="flex flex-col lg:flex-row gap-x-4 justify-between h-full">
            <div className="flex flex-col gap-y-5">
              <h1 className="text-xl md:text-2xl font-bold">Your report is being generated...</h1>
              <div className="text-sm md:text-base text-slate-700">
                <p>Give us a minute or two while Parcha works our magic.</p>
                <p>We will email you when the report is completed as well.</p>
              </div>
            </div>
            <img
              src={BusinessDueDiligenceImage}
              alt="Parcha Logo"
              className="object-fit h-[8.5rem] w-[10rem] md:h-[12.79669rem] md:w-[15rem]"
            />
          </div>
        </div>
        <div className="flex flex-col lg:flex-row gap-[40px] w-full">
          <WebsiteCardSkeleton />
          <BusinessBasicProfile
            checkResult={businessBasicProfileCheckResult}
            input={job.input_payload}
            sourcesDictionary={sourcesDictionary}
            activeSource={activeSource}
            setActiveSource={setActiveSource}
            handleSourceClick={handleSourceClick}
          />
        </div>
        <div className="flex flex-col gap-y-4 h-full">
          <span className={shimmerEffectClassNames}>Business Description</span>
          <div className="animate-pulse flex flex-col gap-y-2">
            <div className="w-1/2 h-3 bg-slate-200 rounded-lg"></div>
            <div className="w-1/3 h-3 bg-slate-200 rounded-lg"></div>
            <div className="w-1/4 h-3 bg-slate-200 rounded-lg"></div>
            <div className="w-1/3 h-3 bg-slate-200 rounded-lg"></div>
            <div className="w-1/2 h-3 bg-slate-200 rounded-lg"></div>
          </div>
        </div>
        <div className="flex flex-col gap-y-4 h-full">
          <span className={shimmerEffectClassNames}>Products and Services</span>
          <div className="animate-pulse flex flex-col gap-y-2">
            <div className="w-1/2 h-3 bg-slate-200 rounded-lg"></div>
            <div className="w-1/3 h-3 bg-slate-200 rounded-lg"></div>
            <div className="w-0 h-0"></div>
            <div className="ml-2 w-1/4 h-3 bg-slate-200 rounded-lg"></div>
            <div className="ml-2 w-1/5 h-3 bg-slate-200 rounded-lg"></div>
            <div className="ml-2 w-1/3 h-3 bg-slate-200 rounded-lg"></div>
          </div>
        </div>
      </div>
    );

  const officialWebsite =
    webPresenceCheckResult?.verified_data?.official_websites?.length > 0
      ? webPresenceCheckResult?.verified_data?.official_websites?.[0]
      : null;
  const websiteUrl =
    officialWebsite?.scanned_website?.webpage_url || webPresenceCheckResult?.check_result?.official_website_match?.url;
  const websiteTitle =
    officialWebsite?.scanned_website?.webpage_title ||
    webPresenceCheckResult?.check_result?.official_website_match?.title;
  const websiteScreenshotUrl =
    officialWebsite?.scanned_website?.screenshot_url ||
    webPresenceCheckResult?.check_result?.official_website_match?.screenshot_url;
  const websiteVisualSummary =
    officialWebsite?.analysis?.extracted_metadata?.visual_summary ||
    webPresenceCheckResult?.check_result?.official_website_match?.visual_summary;
  const scrapeType = officialWebsite?.scanned_website?.scrape_type;

  return (
    <div className="md:px-[2.5rem] px-4 py-4 md:py-10 h-full flex flex-col gap-y-6 md:max-w-[1120px]">
      {webPresenceCheckResult?.verified_data?.business_profile?.profile_confidence === "LOW" && (
        <LowProfileConfidenceBanner />
      )}
      {webPresenceCheckResult?.status === "complete" &&
        webPresenceCheckResult?.verified_data?.only_used_extended_search_results &&
        !webPresenceCheckResult?.verified_data?.no_business_information_found && (
          <div className="flex items-center justify-center gap-x-5 py-5 bg-indigo-100 text-slate-700 rounded-lg">
            <LanguageRounded sx={{ fontSize: "1.25rem" }} className="text-slate-500" />
            <span className="font-semibold">
              {website
                ? "We used an expanded web search because the provided business website was unreachable."
                : "We used an expanded web search because a business website was not provided."}
            </span>
          </div>
        )}
      {webPresenceCheckResult?.status === "complete" &&
        webPresenceCheckResult?.verified_data?.no_business_information_found && (
          <div className="flex items-center gap-x-5 py-5 px-8 bg-red-50 text-red-700 rounded-lg">
            <ErrorOutline sx={{ fontSize: "2rem" }} className="text-red-700" />
            <div>
              <span className="text-base font-semibold">Business not found</span>
              <p className="text-xs">
                We were unable to confidently create a profile of any sort as we could not find any sources related to
                the business.
              </p>
            </div>
          </div>
        )}
      {["in progress", "queued"].includes(job.status) && (
        <div className="flex bg-indigo-100 pr-10 pl-6 py-6 lg:pt-6 sm:pb-0 rounded-xl w-full gap-6 justify-between items-end">
          <div className="flex flex-col gap-y-6 ">
            <img src={ParchaLogo} alt="Parcha Logo" className="object-cover self-start md:h-7 h-5" />
            <div className="flex flex-row gap-x-4 justify-between h-full">
              <div className="flex flex-col gap-y-5">
                <h1 className="text-xl md:text-2xl font-bold">Your report is being generated...</h1>

                <div className="text-sm md:text-base text-slate-700">
                  <p>Give us a couple of minutes while Parcha works our magic.</p>
                  <p>We will email you when the report is completed as well.</p>
                </div>
              </div>
            </div>

            <div className="sm:pb-6">
              {currentStatusMessage &&
                webPresenceCheckResult?.verified_data?.business_profile?.profile_status !== "completed" &&
                currentStatusMessage.content && <CheckStatusMessage messageContents={currentStatusMessage.content} />}
            </div>
          </div>
          <img
            src={BusinessDueDiligenceImage}
            alt="Parcha Logo"
            className="hidden sm:block object-fit h-[8.5rem] w-[10rem] md:h-[12.79669rem] md:w-[15rem]"
          />
        </div>
      )}
      <div className="flex flex-col gap-8 px-6 md:p-0">
        {(webPresenceCheckResult || businessBasicProfileCheckResult) && (
          <div className="flex flex-col lg:flex-row gap-[40px] w-full">
            {websiteUrl ? (
              <motion.div
                initial={{ opacity: 0, filter: "blur(10px)" }}
                animate={{ opacity: 1, filter: "blur(0px)" }}
                transition={{ duration: disableAnimation ? 0 : 1 }}
                className="w-full lg:w-fit mx-auto md:m-0"
              >
                {scrapeType === "instagram" ? (
                  <InstagramWebsiteCard
                    biography={officialWebsite?.scanned_website?.webpage_structured_scrape?.biography}
                    username={officialWebsite?.scanned_website?.webpage_structured_scrape?.username}
                    businessCategoryName={
                      officialWebsite?.scanned_website?.webpage_structured_scrape?.businessCategoryName
                    }
                    logoUrl={officialWebsite?.scanned_website?.webpage_structured_scrape?.profilePicUrlHD}
                    name={officialWebsite?.scanned_website?.webpage_structured_scrape?.fullName}
                    followersCount={officialWebsite?.scanned_website?.webpage_structured_scrape?.followersCount}
                    instagramUrl={officialWebsite?.scanned_website?.webpage_structured_scrape?.url}
                    externalUrl={officialWebsite?.scanned_website?.webpage_structured_scrape?.externalUrl}
                    latestPosts={officialWebsite?.scanned_website?.webpage_structured_scrape?.latestPosts}
                    isVerified={officialWebsite?.scanned_website?.webpage_structured_scrape?.verified}
                    postsCount={officialWebsite?.scanned_website?.webpage_structured_scrape?.postsCount}
                    followCount={officialWebsite?.scanned_website?.webpage_structured_scrape?.followsCount}
                  />
                ) : (
                  <WebsiteCard
                    websiteUrl={websiteUrl}
                    websiteLabel={websiteTitle}
                    screenshotUrl={websiteScreenshotUrl}
                    visualSummary={websiteVisualSummary}
                  />
                )}
              </motion.div>
            ) : webPresenceCheckResult ? (
              <WebsiteCardSkeleton
                message={
                  webPresenceCheckResult?.verified_data?.no_business_information_found
                    ? "No business information found"
                    : webPresenceCheckResult?.verified_data?.unreachable_self_attested_websites
                      ? "Unreachable website"
                      : undefined
                }
                disableShimmer={["error", "complete"].includes(webPresenceCheckResult.status)}
              />
            ) : null}
            {businessBasicProfileCheckResult && (
              <div className="flex-1">
                <BusinessBasicProfile
                  checkResult={businessBasicProfileCheckResult}
                  input={job.input_payload}
                  sourcesDictionary={sourcesDictionary}
                  activeSource={activeSource}
                  setActiveSource={setActiveSource}
                  handleSourceClick={handleSourceClick}
                />
              </div>
            )}
          </div>
        )}
        {(webPresenceCheckResult?.verified_data?.business_profile?.business_description?.length > 0 &&
          webPresenceCheckResult?.verified_data?.business_profile?.business_description.some(
            (d) => d.description.length > 0,
          )) ||
        businessBasicProfileCheckResult?.verified_data?.business_description_found ? (
          <FindingsSection
            title="Business Description"
            content={
              <div className="flex flex-col gap-y-3">
                {webPresenceCheckResult?.verified_data?.business_profile?.business_description?.length > 0 ? (
                  webPresenceCheckResult.verified_data.business_profile.business_description.map(
                    (descriptionEntry: { description: string; sources?: { source_id: number }[] }, index: number) => (
                      <div key={index}>
                        <AnimatedParagraph text={descriptionEntry.description} disableAnimation={disableAnimation} />
                        <span className="inline-flex gap-1 ml-2">
                          {descriptionEntry.sources?.map((s) => (
                            <SourceTagCircle
                              key={s.source_id}
                              source={sourcesDictionary[s.source_id]}
                              isActive={s.source_id === activeSource}
                              setActiveSource={setActiveSource}
                              handleSourceClick={handleSourceClick}
                            />
                          ))}
                        </span>
                      </div>
                    ),
                  )
                ) : businessBasicProfileCheckResult?.verified_data?.business_description_found ? (
                  <div>
                    <AnimatedParagraph
                      text={businessBasicProfileCheckResult.verified_data.business_description_found.description}
                      disableAnimation={disableAnimation}
                    />
                    <span className="inline-flex gap-1 ml-2">
                      {businessBasicProfileCheckResult.verified_data.business_description_found.sources?.map((s) => (
                        <SourceTagCircle
                          key={s.source_id}
                          source={sourcesDictionary[s.source_id]}
                          isActive={s.source_id === activeSource}
                          setActiveSource={setActiveSource}
                          handleSourceClick={handleSourceClick}
                        />
                      ))}
                    </span>
                  </div>
                ) : null}
              </div>
            }
            sources={[]}
            activeSource={null}
            setActiveSource={setActiveSource}
          />
        ) : ["error", "complete"].includes(webPresenceCheckResult?.status) ? null : webPresenceCheckResult ? (
          <div className="flex flex-col gap-y-4">
            <span className={`font-medium ${shimmerEffectClassNames}`}>Business Description</span>
            <div className="animate-pulse flex flex-col gap-y-2">
              <div className="w-1/2 h-3 bg-slate-200 rounded-lg"></div>
              <div className="w-1/3 h-3 bg-slate-200 rounded-lg"></div>
              <div className="w-1/4 h-3 bg-slate-200 rounded-lg"></div>
              <div className="w-1/3 h-3 bg-slate-200 rounded-lg"></div>
              <div className="w-1/2 h-3 bg-slate-200 rounded-lg"></div>
            </div>
          </div>
        ) : null}
        {webPresenceCheckResult?.verified_data?.business_profile?.business_products_and_services
          ?.products_and_services_summary?.length > 0 ||
        webPresenceCheckResult?.verified_data?.business_profile?.business_products_and_services
          ?.products_and_services_list?.length ? (
          <FindingsSection
            title="Products and Services"
            content={
              <div>
                <div>
                  <span className="mr-2">
                    <AnimatedParagraph
                      text={
                        webPresenceCheckResult?.verified_data?.business_profile?.business_products_and_services
                          ?.products_and_services_summary
                      }
                      disableAnimation={disableAnimation}
                    />
                  </span>
                  <span className="inline-flex gap-x-1">
                    {webPresenceCheckResult?.verified_data?.business_profile?.business_products_and_services?.sources?.map(
                      (s: { source_id: string }) => (
                        <SourceTagCircle
                          key={s.source_id}
                          source={sourcesDictionary[s.source_id]}
                          isActive={s.source_id === activeSource}
                          setActiveSource={setActiveSource}
                          handleSourceClick={handleSourceClick}
                        />
                      ),
                    )}
                  </span>
                </div>
                <ul className="list-disc list-outside ml-8 mt-2 space-y-2">
                  {webPresenceCheckResult?.verified_data?.business_profile?.business_products_and_services.products_and_services_list?.map(
                    (product: { name: string; description: string }, index: number) => (
                      <li key={index}>
                        <span className="font-semibold">
                          <AnimatedParagraph
                            text={product.name}
                            className="inline"
                            disableAnimation={disableAnimation}
                          />
                        </span>
                        {" - "}
                        <span>
                          <AnimatedParagraph
                            text={product.description}
                            className="inline"
                            disableAnimation={disableAnimation}
                          />
                        </span>
                      </li>
                    ),
                  )}
                </ul>
              </div>
            }
            sources={[]}
            activeSource={null}
            setActiveSource={setActiveSource}
          />
        ) : businessBasicProfileCheckResult?.verified_data?.business_products_and_services_found ? (
          <FindingsSection
            title="Products and Services"
            content={
              <div>
                <div>
                  <span className="mr-2">
                    <AnimatedParagraph
                      text={
                        businessBasicProfileCheckResult.verified_data.business_products_and_services_found
                          .products_and_services
                      }
                      disableAnimation={disableAnimation}
                    />
                  </span>
                  <span className="inline-flex gap-x-1">
                    {businessBasicProfileCheckResult.verified_data.business_products_and_services_found.sources?.map(
                      (s: { source_id: string }) => (
                        <SourceTagCircle
                          key={s.source_id}
                          source={sourcesDictionary[s.source_id]}
                          isActive={s.source_id === activeSource}
                          setActiveSource={setActiveSource}
                          handleSourceClick={handleSourceClick}
                        />
                      ),
                    )}
                  </span>
                </div>
              </div>
            }
          />
        ) : ["error", "complete"].includes(webPresenceCheckResult?.status) ? null : webPresenceCheckResult ? (
          <div className="flex flex-col gap-y-4 h-full">
            <span className={`font-medium ${shimmerEffectClassNames}`}>Products and Services</span>
            <div className="animate-pulse flex flex-col gap-y-2">
              <div className="w-1/2 h-3 bg-slate-200 rounded-lg"></div>
              <div className="w-1/3 h-3 bg-slate-200 rounded-lg"></div>
              <div className="w-0 h-0"></div>
              <div className="ml-2 w-1/4 h-3 bg-slate-200 rounded-lg"></div>
              <div className="ml-2 w-1/4 h-3 bg-slate-200 rounded-lg"></div>
              <div className="ml-2 w-1/4 h-3 bg-slate-200 rounded-lg"></div>
            </div>
          </div>
        ) : null}

        {webPresenceCheckResult?.verified_data?.business_profile?.business_customers?.length > 0 ? (
          <FindingsSection
            title="Current Customers"
            content={
              <div>
                <ol className="list-decimal list-outside ml-8 mt-2 space-y-2">
                  {webPresenceCheckResult.verified_data.business_profile.business_customers.map(
                    (customer: string, index: Key | null | undefined) => (
                      <li key={index}>
                        <AnimatedParagraph text={customer} className="inline" disableAnimation={disableAnimation} />
                      </li>
                    ),
                  )}
                </ol>
              </div>
            }
            sources={[]}
            activeSource={null}
            setActiveSource={setActiveSource}
          />
        ) : null}
        {businessReviewsCheckResult && <PhysicalLocationReviews checkResult={businessReviewsCheckResult} />}
        {businessBasicProfileCheckResult && (
          <OnlinePresence checkResult={businessBasicProfileCheckResult} sourcesDictionary={sourcesDictionary} />
        )}
        {addressesCheckResult && (
          <Addresses
            checkResult={addressesCheckResult}
            inputIncorporationAddress={job.input_payload.self_attested_data.address_of_incorporation ?? null}
            inputOperatingAddress={job.input_payload.self_attested_data.address_of_operation ?? null}
            sourcesDictionary={sourcesDictionary}
            activeSource={activeSource}
            setActiveSource={setActiveSource}
            handleSourceClick={handleSourceClick}
          />
        )}
        {highRiskCountriesCheckResult || highRiskIndustriesCheckResult || highRiskIndustriesV2CheckResult ? (
          <div className="flex-1 flex flex-col lg:flex-row gap-5 justify-between last:pb-5">
            {highRiskCountriesCheckResult && (
              <div className="w-full lg:w-1/2">
                <HighRiskCountries
                  checkResult={highRiskCountriesCheckResult}
                  activeSource={activeSource}
                  sourcesDictionary={sourcesDictionary}
                  setActiveSource={setActiveSource}
                  handleSourceClick={handleSourceClick}
                />
              </div>
            )}

            {(highRiskIndustriesCheckResult || highRiskIndustriesV2CheckResult) && (
              <div className={`w-full ${highRiskCountriesCheckResult ? "lg:w-1/2" : ""}`}>
                <HighRiskIndustries
                  checkResult={highRiskIndustriesCheckResult || highRiskIndustriesV2CheckResult}
                  activeSource={activeSource}
                  sourcesDictionary={sourcesDictionary}
                  setActiveSource={setActiveSource}
                  handleSourceClick={handleSourceClick}
                />
              </div>
            )}
          </div>
        ) : null}

        {mccCheckResult && (
          <div className="flex-1 last:pb-[5rem]">
            <MCC
              checkResult={mccCheckResult}
              sourcesDictionary={sourcesDictionary}
              setActiveSource={setActiveSource}
              activeSource={activeSource}
              handleSourceClick={handleSourceClick}
            />
          </div>
        )}

        {(adverseMediaCheckResult || sanctionsWatchlistCheckResult) && (
          <div className="flex-1 last:pb-[5rem]">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              {adverseMediaCheckResult && (
                <div className="w-full">
                  <AdverseMedia checkResult={adverseMediaCheckResult} />
                </div>
              )}

              {sanctionsWatchlistCheckResult && (
                <div className="w-full">
                  <SanctionsWatchlist checkResult={sanctionsWatchlistCheckResult} />
                </div>
              )}
            </div>
          </div>
        )}

        {policiesCheckResult && (
          <div className="flex-1 last:pb-[5rem]">
            <Policies
              checkResult={policiesCheckResult}
              sourcesDictionary={sourcesDictionary}
              activeSource={activeSource}
              setActiveSource={setActiveSource}
              handleSourceClick={handleSourceClick}
            />
          </div>
        )}

        {businessOwnersCheckResult && (
          <div className="flex-1 last:pb-[5rem]">
            <BusinessOwners checkResult={businessOwnersCheckResult} sourcesDictionary={sourcesDictionary} />
          </div>
        )}

        {job && job.status === "complete" && job.agent_id === "public-bdd" && (
          <>
            <div className="flex-1 last:pb-[5rem]">
              <div className="flex flex-col gap-y-5">
                <div className="flex items-center gap-x-2">
                  <WorkspacePremiumRounded sx={{ fontSize: "1.25rem" }} className="text-slate-700" />
                  <span className="text-xl font-semibold">Registration & Licenses</span>
                </div>
                <div>
                  <UpsellSectionWrapper
                    title="Verify legal compliance"
                    subtitle="Ensure proper registration and licensing globally."
                    description="Get a comprehensive report on the legal compliance of your business. Ensure that your business is
          properly registered and licensed globally."
                    learnMoreURL="https://www.parcha.com/products/business-validation"
                    imgSrc={LegalBusinessEntitiesImg}
                  />
                </div>
              </div>
            </div>
            <div className="flex-1 last:pb-[5rem]">
              <div className="flex flex-col gap-y-5">
                <div className="flex items-center gap-x-2">
                  <GroupRounded sx={{ fontSize: "1.25rem" }} className="text-slate-700" />
                  <span className="text-xl font-semibold">Business Owners</span>
                </div>
                <UpsellSectionWrapper
                  title="Comprehensive owner vetting"
                  subtitle="Ensure regulatory compliance and mitigate risks."
                  description="Examine the personal and professional backgrounds, financial records, and legal standing to ensure they meet regulatory requirements."
                  learnMoreURL="https://www.parcha.com/products/ownership-documents"
                  imgSrc={BusinessOwnersImg}
                />
              </div>
            </div>
            <div className="flex-1 last:pb-[5rem]">
              <div className="flex flex-col gap-y-5">
                <div className="flex items-center gap-x-2">
                  <TaskRounded sx={{ fontSize: "1.25rem" }} className="text-slate-700" />
                  <span className="text-xl font-semibold">Document Verificaion</span>
                </div>
                <UpsellSectionWrapper
                  title="Multilingual document analysis"
                  subtitle="Extract crucial business data instantly, globally."
                  description="Extract information from documents in 60+ languages identifying and validating the business name, incorporation date, business owners, EIN, source of funds and more."
                  learnMoreURL="https://www.parcha.com/products/incorporation-documents"
                  imgSrc={IncorporationDocsImg}
                />
              </div>
            </div>
          </>
        )}

        <DocumentList
          input={job.input_payload}
          endpointUrl={endpointUrl}
          documentCheckResults={documentCheckResults}
          setSelectedDocumentCheck={setSelectedDocumentCheck}
        />

        {selectedDocumentCheck && (
          <Transition.Root show={Boolean(selectedDocumentCheck)} as={Fragment} appear>
            <Dialog as="div" className="relative z-20" onClose={() => setSelectedDocumentCheck(null)}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-white bg-opacity-100 transition-opacity" />
              </Transition.Child>
              <div className="fixed inset-0 z-20 w-full h-full overflow-hidden">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full h-full transition-all">
                    <div className="flex items-center justify-between py-2 px-4 border-b border-solid border-slate-200">
                      <div className="flex items-center justify-between gap-x-2 text-slate-900">
                        <PlagiarismRounded sx={{ fontSize: "1rem" }} className="text-slate-500" />
                        <h2 className="font-semibold">Document Validation</h2>
                        <div className="flex items-center gap-x-2 ml-5">
                          {agent?.agentType === "kyb" ? (
                            <BusinessRounded sx={{ fontSize: "1rem" }} className="text-slate-500" />
                          ) : (
                            <PersonRounded sx={{ fontSize: "1rem" }} className="text-slate-500" />
                          )}
                        </div>
                        <span>{getJobName()}</span>
                      </div>
                      <button
                        className="ml-auto inline-flex items-center border border-solid border-slate-200 px-2 py-1 rounded-md text-sm"
                        onClick={() => setSelectedDocumentCheck(null)}
                      >
                        <CloseRounded sx={{ fontSize: "1rem" }} className="mr-1" />
                        <span>Close</span>
                      </button>
                    </div>
                    <DocumentValidation
                      agentInstanceId={selectedDocumentCheck.agent_instance_id}
                      jobId={job.id}
                      caseId={job.input_payload.id}
                      endpointUrl={endpointUrl}
                      documentUrl={`${window.location.protocol}//${endpointUrl}/getDocument?case_id=${
                        job.input_payload.id
                      }&expired_url=${encodeURIComponent(selectedDocumentCheck.documentUrl)}`}
                      documentName={selectedDocumentCheck.documentTitle}
                      title={getJobName()}
                      agentType="kyb"
                      checkId={selectedDocumentCheck.command_id}
                      checkName={selectedDocumentCheck.command_name}
                      onCloseHandler={() => setSelectedDocumentCheck(null)}
                    />
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>
        )}

        {sourcesValues.length > 0 && sourcesValues.every((source) => !("text" in source)) && (
          <div className="flex flex-col gap-y-3 w-full">
            <div className="flex items-center gap-x-2">
              <LanguageRounded sx={{ fontSize: "1.25rem" }} className="text-slate-700" />
              <span className="text-lg font-semibold">Sources</span>
              <button
                onClick={toggleShowingSources}
                aria-label={showingSources ? "Hide Sources" : "Show Sources"}
                className="cursor-pointer w-4 h-4 flex items-center justify-center rounded-full hover:bg-slate-100 hover:text-brand-purple"
              >
                {showingSources ? (
                  <ExpandLessRounded sx={{ fontSize: "1rem" }} />
                ) : (
                  <ExpandMoreRounded sx={{ fontSize: "1rem" }} />
                )}
              </button>
            </div>
            {showingSources &&
              sourcesValues.map((source, index) => {
                return <FindingsSource source={{ ...source, id: index + 1 }} key={source.source_id} />;
              })}
          </div>
        )}
      </div>
    </div>
  );
};

export const WebsiteCardSkeleton = ({
  message,
  disableShimmer = false,
}: {
  message?: string;
  disableShimmer?: boolean;
}) => {
  return (
    <div className="flex flex-col gap-y-1 w-full md:w-[520px] bg-[#F8FAFC]">
      <div className="w-full h-[13rem] md:h-[18.4rem] flex flex-col gap-y-2 items-center justify-center rounded-lg">
        <ImageSearch sx={{ fontSize: "2rem" }} className="text-slate-300" />
        <span className={disableShimmer ? "text-slate-400 italic" : shimmerEffectClassNames}>
          {message ? message : "Website Screenshot"}
        </span>
      </div>
    </div>
  );
};

interface OnlinePresenceProps {
  checkResult: any;
  sourcesDictionary: { [key: string]: any };
}

export const OnlinePresence: React.FC<OnlinePresenceProps> = ({ checkResult, sourcesDictionary }) => {
  const Header = (
    <div className="flex items-center gap-x-2">
      <LanguageRounded sx={{ fontSize: "1.25rem" }} className="text-slate-700" />
      <span
        className={twJoin(
          "text-xl font-semibold",
          checkResult?.status === "running" || !checkResult?.verified_data ? shimmerEffectClassNames : "",
        )}
      >
        Online Presence
      </span>
      {checkResult?.passed && (
        <Tooltip arrow title={checkResult.answer} className="w-4 h-4">
          <CheckCircleRounded sx={{ fontSize: "1rem" }} className="text-green-600" />
        </Tooltip>
      )}
    </div>
  );

  const filteredStatusMessages =
    checkResult?.status_messages?.filter(
      (message) => message.event === "status" && !message.content.status?.startsWith("Loading:"),
    ) ?? [];
  const lastStatusMessage =
    filteredStatusMessages.length > 0 ? filteredStatusMessages[filteredStatusMessages.length - 1] : null;

  if (!checkResult?.verified_data || !sourcesDictionary) {
    return (
      <div className="flex flex-col gap-y-2 rounded-lg md:w-full">
        {Header}
        {lastStatusMessage && <CheckSectionStatusMessage message={lastStatusMessage.content.status} />}
        <OnlinePresenceSkeleton />
      </div>
    );
  }

  const allWebsites = Object.values(sourcesDictionary).reduce((acc, source) => {
    if (
      source?.structured_data &&
      ["instagram", "twitter", "linkedin_company", "facebook", "crunchbase"].includes(
        source.structured_data.scrape_type,
      )
    ) {
      const existingIndex = acc.findIndex((profile) => profile.url === source.url);
      if (existingIndex === -1) {
        acc.push(source);
      } else if (Object.keys(source.structured_data).length > Object.keys(acc[existingIndex].structured_data).length) {
        acc[existingIndex] = source;
      }
    }
    return acc;
  }, []);

  let finalWebsites = [];
  for (const website of allWebsites) {
    if (
      checkResult?.verified_data?.social_media_source_ids?.includes(website.source_id) ||
      checkResult?.verified_data?.business_profile_source_ids?.includes(website.source_id)
    ) {
      finalWebsites.push(website);
    }
    if (
      checkResult?.verified_data?.social_media_urls?.includes(website.url) ||
      checkResult?.verified_data?.business_profile_urls?.includes(website.url)
    ) {
      finalWebsites.push(website);
    }
  }

  // Remove duplicate websites based on URL
  finalWebsites = finalWebsites.filter(
    (website, index, self) => index === self.findIndex((t) => t.url === website.url),
  );

  if (finalWebsites.length === 0) {
    return null;
  }

  const evidenceList = finalWebsites.map((website) => {
    return {
      found: website.url,
      evidence: website.summary,
    };
  });

  return (
    <div className="w-full text-sm overflow-hidden flex flex-col gap-y-4">
      {Header}
      {!checkResult.passed && <Alert message={checkResult.answer} level="failure" />}
      {checkResult.status === "running" && evidenceList.length > 0 && (
        <div className="flex flex-col gap-y-2">
          {lastStatusMessage && <CheckSectionStatusMessage message={lastStatusMessage.content.status} />}
          <div className="h-[200px]">
            <SmartSkeleton evidenceList={evidenceList} />
          </div>
        </div>
      )}
      {checkResult.status === "complete" && (
        <div className="h-fit columns-1 md:columns-2 lg:columns-3 gap-4">
          {finalWebsites.map(
            (
              source: {
                structured_data: {
                  scrape_type: string | null;
                  name: string | null;
                  description: string | null;
                  tagline: string | null;
                  websiteUrl: string | null;
                  industry: string | null;
                  url: string | null;
                  employeeCount: string | null;
                  headquater: { city: any; country: any };
                  avatar: string | null;
                  about_me: { text: string | null };
                  followers: string | number | null;
                  facebookUrl: string | null;
                  title: string | null;
                  categories: any[];
                  profilePicture: string | null;
                  twitterUrl: string | null;
                  userName: string | null;
                  biography: string | null;
                  businessCategoryName: string | null;
                  externalUrl: string | null;
                  followersCount: number | null;
                  profilePicUrlHD: string | null;
                  fullName: string | null;
                  latestPosts: { id: string; displayUrl: string | null; url: string | null }[] | null | undefined;
                  webpage_metadata: { domain: string };
                  webpage_url: string | URL;
                  webpage_title: string;
                  screenshot_url: any;
                  short_description: string | null;
                  funding_total: { value: number; currency: string; value_usd: number } | null;
                  num_funding_rounds: number | null;
                  last_funding_type: string | null;
                  last_funding_at: string | null;
                  current_advisors_image_list: Array<{
                    person_identifier: {
                      value: string;
                      image_id: string | null;
                    };
                    job_type: string;
                  }> | null;
                  funding_rounds_list: Array<{
                    announced_on: string;
                    num_investors: number;
                    money_raised: { value: number; currency: string; value_usd: number };
                    lead_investor_identifiers: Array<{ value: string }>;
                  }> | null;
                  investors_list: Array<{
                    is_lead_investor: boolean | null;
                    investor_identifier: { value: string };
                    partner_identifiers: Array<{ value: string }>;
                  }> | null;
                  social_fields: {
                    facebook: { value: string } | null;
                    linkedin: { value: string } | null;
                    twitter: { value: string } | null;
                  } | null;
                };
                url: string | null;
              },
              index: Key | null | undefined,
            ) => {
              const presence = source.structured_data;
              switch (presence.scrape_type) {
                case "linkedin_company":
                  return (
                    <div className="mb-4" key={index}>
                      <LinkedInCompanyCard
                        name={presence.name}
                        description={presence.description}
                        overview={presence.tagline}
                        url={presence.websiteUrl}
                        industry={presence.industry}
                        linkedInUrl={presence.url}
                        employeesCount={presence.employeeCount}
                        location={
                          presence.headquater ? `${presence.headquater?.city} ${presence.headquater?.country}` : null
                        }
                        logoUrl={presence.avatar}
                      />
                    </div>
                  );
                case "facebook":
                  return presence?.title ? (
                    <div className="mb-4" key={index}>
                      <FacebookProfileCard
                        aboutMe={presence.about_me?.text}
                        followersCount={presence.followers?.toString() || null}
                        facebookUrl={presence.facebookUrl}
                        name={presence.title}
                        industry={presence.categories?.join(", ")}
                      />
                    </div>
                  ) : null;
                case "twitter":
                  return (
                    <div className="mb-4" key={index}>
                      <XTwitterProfileCard
                        description={presence.description}
                        followers={
                          typeof presence.followers === "string" ? parseInt(presence.followers) : presence.followers
                        }
                        name={presence.name}
                        profilePicture={presence.profilePicture}
                        twitterUrl={presence.twitterUrl}
                        userName={presence.userName}
                      />
                    </div>
                  );
                case "instagram":
                  return (
                    <div className="mb-4" key={index}>
                      <InstagramProfileCard
                        biography={presence.biography}
                        businessCategoryName={presence.businessCategoryName}
                        externalUrl={presence.externalUrl}
                        followers={presence.followersCount}
                        instagramUrl={presence.url}
                        logoUrl={presence.profilePicUrlHD}
                        name={presence.fullName}
                        latestPosts={presence.latestPosts}
                      />
                    </div>
                  );
                case "crunchbase":
                  return <CrunchbaseCompanyCard key={index} {...{ ...presence, url: source.url }} />;
              }

              return (
                <div className="mb-4" key={index}>
                  <WebPresenceCard
                    title={presence.webpage_title}
                    url={presence.webpage_url}
                    imageUrl={presence.screenshot_url}
                    domain={presence.webpage_metadata?.domain}
                    avatarUrl={presence.avatar}
                  />
                </div>
              );
            },
          )}
        </div>
      )}
    </div>
  );
};

const OnlinePresenceSkeleton = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-full">
      <Skeleton height="h-fit" width="w-full" shape="rounded">
        <div className="flex flex-col gap-y-2 p-4">
          <div className="flex items-start gap-x-2">
            <Skeleton height="h-10" width="w-10" shape="circular" additionalClasses="bg-slate-300 shrink-0" />
            <div className="flex flex-col gap-y-1 w-full">
              <Skeleton height="h-3" width="w-1/3" shape="rounded" additionalClasses="bg-slate-300" />
              <Skeleton height="h-3" width="w-1/2" shape="rounded" additionalClasses="bg-slate-300" />
            </div>
          </div>
          <Skeleton height="h-3" width="w-full" shape="rounded" additionalClasses="bg-slate-300" />
          <Skeleton height="h-3" width="w-1/3" shape="rounded" additionalClasses="bg-slate-300" />
          <Skeleton height="h-3" width="w-1/2" shape="rounded" additionalClasses="bg-slate-300" />
        </div>
      </Skeleton>
      <Skeleton height="h-fit" width="w-full" shape="rounded">
        <div className="flex flex-col gap-y-2 p-4">
          <div className="flex items-start gap-x-2">
            <Skeleton height="h-10" width="w-10" shape="circular" additionalClasses="bg-slate-300 shrink-0" />
            <div className="flex flex-col gap-y-1 w-full">
              <Skeleton height="h-3" width="w-1/3" shape="rounded" additionalClasses="bg-slate-300" />
              <Skeleton height="h-3" width="w-1/2" shape="rounded" additionalClasses="bg-slate-300" />
            </div>
          </div>
          <Skeleton height="h-3" width="w-full" shape="rounded" additionalClasses="bg-slate-300" />
          <Skeleton height="h-3" width="w-1/3" shape="rounded" additionalClasses="bg-slate-300" />
          <Skeleton height="h-3" width="w-1/2" shape="rounded" additionalClasses="bg-slate-300" />
        </div>
      </Skeleton>
      <Skeleton height="h-fit" width="w-full" shape="rounded">
        <div className="flex flex-col gap-y-2 p-4">
          <div className="flex items-start gap-x-2">
            <Skeleton height="h-10" width="w-10" shape="circular" additionalClasses="bg-slate-300 shrink-0" />
            <div className="flex flex-col gap-y-1 w-full">
              <Skeleton height="h-2" width="w-1/3" shape="rounded" additionalClasses="bg-slate-300" />
              <Skeleton height="h-2" width="w-1/2" shape="rounded" additionalClasses="bg-slate-300" />
            </div>
          </div>
          <Skeleton height="h-3" width="w-full" shape="rounded" additionalClasses="bg-slate-300" />
          <Skeleton height="h-3" width="w-1/3" shape="rounded" additionalClasses="bg-slate-300" />
          <Skeleton height="h-3" width="w-1/2" shape="rounded" additionalClasses="bg-slate-300" />
        </div>
      </Skeleton>
    </div>
  );
};

interface HighRiskIndustriesProps {
  checkResult: any;
  activeSource: number | null;
  sourcesDictionary: any;
  setActiveSource: (source: number) => void;
  handleSourceClick: (source: number) => void;
}

export const HighRiskIndustries: React.FC<HighRiskIndustriesProps> = ({
  checkResult,
  activeSource,
  sourcesDictionary,
  setActiveSource,
  handleSourceClick,
}) => {
  const Header = (
    <div className="flex items-center gap-x-2">
      <FactoryRounded sx={{ fontSize: "1.25rem" }} className="text-slate-700" />
      <span
        className={twJoin(
          "text-xl font-semibold",
          checkResult?.status === "running" || !checkResult?.verified_data ? shimmerEffectClassNames : "",
        )}
      >
        Industry Classifications
      </span>
      {checkResult?.passed && (
        <Tooltip arrow title={checkResult.answer} className="w-4 h-4">
          <CheckCircleRounded sx={{ fontSize: "1rem" }} className="text-green-600" />
        </Tooltip>
      )}
    </div>
  );

  const filteredStatusMessages =
    checkResult?.status_messages?.filter(
      (message) => message.event === "status" && !message.content.status?.startsWith("Loading:"),
    ) ?? [];
  const lastStatusMessage =
    filteredStatusMessages.length > 0 ? filteredStatusMessages[filteredStatusMessages.length - 1] : null;

  if (!checkResult?.verified_data?.found_industries) {
    return (
      <div className="flex flex-col gap-y-2 rounded-lg md:w-full">
        {Header}
        {lastStatusMessage && <CheckSectionStatusMessage message={lastStatusMessage.content.status} />}
        <GenericSkeleton />
      </div>
    );
  }

  const evidenceList = Object.entries(checkResult?.verified_data?.found_industries || {}).map(([_, industry]) => ({
    found: industry?.industry_code?.code + " - " + industry?.industry_code?.title,
    evidence: industry?.evidence,
  }));

  const industryMatches =
    checkResult.check_result?.industry_matches ||
    checkResult.check_result?.high_risk_industries_match?.high_risk_industries?.map((industry) => ({
      high_risk_category: industry,
      is_high_risk: true,
    })) ||
    [];

  return (
    <div className="flex flex-col gap-y-3 w-full">
      {Header}
      {!checkResult.passed && <Alert message={checkResult.answer} level="failure" />}
      {checkResult.status === "running" ? (
        evidenceList.length > 0 ? (
          <div className="h-[200px]">
            <SmartSkeleton evidenceList={evidenceList} />
          </div>
        ) : (
          <div className="flex flex-col gap-y-2 rounded-lg md:w-full">
            {lastStatusMessage && <CheckSectionStatusMessage message={lastStatusMessage.content.status} />}
            <GenericSkeleton />
          </div>
        )
      ) : industryMatches?.length > 0 ? (
        <div className="flex flex-col gap-2">
          {industryMatches.map((industry, index) => (
            <IndustryCard
              key={index}
              industry={industry}
              sourcesDictionary={sourcesDictionary}
              activeSource={activeSource}
              setActiveSource={setActiveSource}
              handleSourceClick={handleSourceClick}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
};

const IndustryCard: React.FC<IndustryCardProps> = ({
  industry,
  sourcesDictionary,
  activeSource,
  setActiveSource,
  handleSourceClick,
}) => {
  const isHighRisk = industry?.is_high_risk;
  const highRiskCategory = industry?.high_risk_category;

  return (
    <div className="rounded-lg p-3 flex flex-col gap-y-1 bg-slate-50">
      <div className="flex items-baseline gap-x-2">
        <div className="flex-shrink-0">
          <WrappedValue text={industry.naics_code?.code} level="normal" />
        </div>
        <div className="flex flex-col">
          <div className="flex items-center justify-left">
            {industry.naics_code && <h4 className="text-sm font-semibold">{industry.naics_code.title}</h4>}
            <div className="flex gap-1 ml-2">
              {industry.source_ids?.map((sourceId) => {
                const source = sourcesDictionary[sourceId];
                return (
                  <SourceTagCircle
                    key={sourceId}
                    source={source}
                    isActive={source === activeSource}
                    setActiveSource={setActiveSource}
                    handleSourceClick={handleSourceClick}
                  />
                );
              })}
            </div>
          </div>
          <p className="text-sm  text-gray-500">{industry.description}</p>
          <div className="flex items-center mt-2">
            <SubdirectoryArrowRightRounded sx={{ fontSize: "1rem" }} className="text-gray-500 mr-1 self-start" />
            <p className="text-sm italic text-gray-900">{industry.explanation}</p>
          </div>

          {isHighRisk && (
            <div className="flex items-center gap-x-2 mt-1">
              <SubdirectoryArrowRightRounded sx={{ fontSize: "1rem" }} className="text-gray-500" />
              <span className="text-sm font-semibold text-yellow-700">{highRiskCategory}</span>
              <Tooltip title={industry.high_risk_evidence} arrow placement="top">
                <div>
                  <WrappedValue
                    text={
                      <div className="flex items-center gap-x-0.5">
                        <WarningRounded sx={{ fontSize: "0.875rem" }} />
                        <span className="font-bold">High Risk</span>
                      </div>
                    }
                    level="caution"
                  />
                </div>
              </Tooltip>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const AdverseMedia = ({ checkResult }) => {
  if (!checkResult?.verified_data) {
    const filteredStatusMessages =
      checkResult?.status_messages?.filter(
        (message) => message.event === "status" && !message.content.status?.startsWith("Loading:"),
      ) ?? [];
    const lastStatusMessage =
      filteredStatusMessages.length > 0 ? filteredStatusMessages[filteredStatusMessages.length - 1] : null;

    return (
      <div className="flex flex-col gap-y-2 rounded-lg md:w-full">
        <div className="flex items-center gap-x-2">
          <NewspaperRounded sx={{ fontSize: "1.25rem" }} className="text-slate-700" />
          <span className={twJoin("text-xl font-semibold", shimmerEffectClassNames)}>Adverse Media Screening</span>
        </div>
        {lastStatusMessage && <CheckSectionStatusMessage message={lastStatusMessage.content.status} />}
        <GenericSkeleton />
      </div>
    );
  }

  const evidenceList = Object.entries(checkResult?.verified_data?.adverse_media_hits || {}).map(([_, media]) => ({
    found:
      media?.article_metadata.who?.perpetrators?.length > 0
        ? media.article_metadata.who.perpetrators[0]?.business_name
        : "",
    evidence: media?.article_metadata?.what,
  }));

  return (
    <div className="w-full text-sm overflow-hidden flex flex-col gap-y-5">
      <div className="flex items-center gap-x-2">
        <NewspaperRounded sx={{ fontSize: "1.25rem" }} className="text-slate-700" />
        <span className="text-xl font-semibold">Adverse Media Screening</span>
        {checkResult.passed && (
          <Tooltip arrow title={checkResult.answer} className="w-4 h-4">
            <CheckCircleRounded sx={{ fontSize: "1rem" }} className="text-green-600" />
          </Tooltip>
        )}
      </div>
      <div className="flex flex-col gap-y-2">
        {!checkResult.passed && <Alert message={checkResult.answer} level="failure" />}
        {checkResult.status === "running" && evidenceList.length > 0 ? (
          <div className="h-[200px]">
            <SmartSkeleton evidenceList={evidenceList} />
          </div>
        ) : checkResult.check_result ? (
          <div className="grid grid-cols-1 gap-x-3 gap-y-2">
            {checkResult.check_result.verified_adverse_media_hits?.length > 0 ? (
              checkResult.check_result.verified_adverse_media_hits?.map((hit, index) => (
                <AdverseMediaHit adverseMediaHit={{ ...hit, type: "kyb" }} level={"failure"} key={index} />
              ))
            ) : (
              <div className="p-3 bg-slate-50 text-slate-500 text-sm">No strong matches found</div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export const SanctionsWatchlist = ({ checkResult }) => {
  if (!checkResult?.verified_data) {
    const filteredStatusMessages =
      checkResult?.status_messages?.filter(
        (message) => message.event === "status" && !message.content.status?.startsWith("Loading:"),
      ) ?? [];
    const lastStatusMessage =
      filteredStatusMessages.length > 0 ? filteredStatusMessages[filteredStatusMessages.length - 1] : null;

    return (
      <div className="flex flex-col gap-y-2 rounded-lg md:w-full">
        <div className="flex items-center gap-x-2">
          <VisibilityRounded sx={{ fontSize: "1.25rem" }} className="text-slate-700" />
          <span className={twJoin("text-xl font-semibold", shimmerEffectClassNames)}>
            Sanctions Watchlist Screening
          </span>
        </div>
        {lastStatusMessage && <CheckSectionStatusMessage message={lastStatusMessage.content.status} />}
        <GenericSkeleton />
      </div>
    );
  }

  const evidenceList = Object.entries(checkResult?.verified_data?.sanctions_data || {}).map(([_, hit]) => ({
    found: hit?.names[0],
    evidence: hit?.sanction_details,
  }));

  return (
    <div className="w-full text-sm overflow-hidden flex flex-col gap-y-5">
      <div className="flex items-center gap-x-2">
        <VisibilityRounded sx={{ fontSize: "1.25rem" }} className="text-slate-700" />
        <span className="text-xl font-semibold">Sanctions Watchlist Screening</span>
        {checkResult?.passed && (
          <Tooltip arrow title={checkResult.answer} className="w-4 h-4">
            <CheckCircleRounded sx={{ fontSize: "1rem" }} className="text-green-600" />
          </Tooltip>
        )}
      </div>
      <div className="flex flex-col gap-y-2">
        {!checkResult.passed && <Alert message={checkResult.answer} level="failure" />}

        {checkResult.status === "running" && evidenceList.length > 0 ? (
          <div className="h-[200px]">
            <SmartSkeleton evidenceList={evidenceList} />
          </div>
        ) : checkResult.check_result ? (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-3 gap-y-2">
            {checkResult.check_result?.verified_sanctions_hits?.length > 0 ? (
              checkResult.check_result?.verified_sanctions_hits?.map(
                (
                  sanctionHit: {
                    matched_lists: string | any[];
                    source_url: string;
                    matched_names: string[] | null | undefined;
                    description: string | null | undefined;
                  },
                  index: Key | null | undefined,
                ) => (
                  <SanctionsWatchlistCard
                    key={index}
                    countryCode={
                      sanctionHit.matched_lists?.length > 0 ? sanctionHit.matched_lists[0].split("_")[0] : null
                    }
                    url={sanctionHit.source_url}
                    sanctionListName={sanctionHit.matched_lists?.length > 0 ? sanctionHit.matched_lists[0] : null}
                    matchedNames={sanctionHit.matched_names}
                    level="failure"
                    description={sanctionHit.description}
                  />
                ),
              )
            ) : (
              <div className=" col-span-2 p-3 bg-slate-50 text-slate-500 text-sm">No strong matches found</div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

interface PoliciesProps {
  checkResult: any;
  activeSource: any;
  sourcesDictionary: any;
  setActiveSource: any;
  handleSourceClick: any;
}

export const Policies: React.FC<PoliciesProps> = ({
  checkResult,
  activeSource,
  sourcesDictionary,
  setActiveSource,
  handleSourceClick,
}) => {
  const Header = (
    <div className="flex items-center gap-x-2">
      <PolicyRounded sx={{ fontSize: "1.25rem" }} className="text-slate-700" />
      <span
        className={twJoin(
          "text-xl font-semibold",
          checkResult?.status === "running" || !checkResult?.verified_data ? shimmerEffectClassNames : "",
        )}
      >
        Policies
      </span>
      {checkResult?.passed && (
        <Tooltip arrow title={checkResult.answer} className="w-4 h-4">
          <CheckCircleRounded sx={{ fontSize: "1rem" }} className="text-green-600" />
        </Tooltip>
      )}
    </div>
  );

  if (!checkResult?.verified_data) {
    const filteredStatusMessages =
      checkResult?.status_messages?.filter(
        (message) => message.event === "status" && !message.content.status?.startsWith("Loading:"),
      ) ?? [];
    const lastStatusMessage =
      filteredStatusMessages.length > 0 ? filteredStatusMessages[filteredStatusMessages.length - 1] : null;

    return (
      <div className="flex flex-col gap-y-2 rounded-lg md:w-full">
        {Header}
        {lastStatusMessage && <CheckSectionStatusMessage message={lastStatusMessage.content.status} />}
        <GenericSkeleton />
      </div>
    );
  }

  const evidenceList = Object.entries(checkResult?.verified_data?.found_policies || {}).map(([_, policy]) => ({
    found: policy?.policy_name,
    evidence: policy?.evidence,
  }));

  return (
    <div className="w-full text-sm overflow-hidden flex flex-col gap-y-5">
      {Header}
      {!checkResult.passed && <Alert message={checkResult.answer} level="failure" />}
      {checkResult.status === "running" && evidenceList.length > 0 ? (
        <div className="h-[200px]">
          <SmartSkeleton evidenceList={evidenceList} />
        </div>
      ) : checkResult.check_result ? (
        <div className="flex flex-col gap-y-2">
          {checkResult.check_result.policies_found?.map((policy, index) => {
            const source = sourcesDictionary[policy.source_ids[0]];

            return (
              <WebsitePolicyCard
                key={index}
                match_type={policy.policy_type}
                explanation={policy.explanation}
                url={source?.url}
                sourceComponent={
                  <SourceTagCircle
                    source={source}
                    isActive={source === activeSource}
                    setActiveSource={setActiveSource}
                    handleSourceClick={handleSourceClick}
                  />
                }
              />
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

interface MCCProps {
  checkResult: {
    verified_data?: any;
    passed?: boolean;
    answer?: string;
    check_result?: {
      mcc_code?: string;
      mcc_code_description?: string;
      products_sold?: Array<any>;
    };
  };
}

export const MCC: React.FC<MCCProps> = ({ checkResult }) => {
  const Header = (
    <div className="flex items-center gap-x-2">
      <Pin sx={{ fontSize: "1.25rem" }} className="text-slate-700" />
      <span
        className={twJoin(
          "text-xl font-semibold",
          checkResult?.status === "running" || !checkResult?.verified_data || !checkResult?.verified_data
            ? shimmerEffectClassNames
            : "",
        )}
      >
        Merchant Categorization (MCC)
      </span>
      {checkResult?.passed && (
        <Tooltip arrow title={checkResult.answer} className="w-4 h-4">
          <CheckCircleRounded sx={{ fontSize: "1rem" }} className="text-green-600" />
        </Tooltip>
      )}
    </div>
  );
  const MCCSkeleton = (
    <div className="animate-pulse flex flex-col gap-4">
      <div className="flex flex-row items-center gap-2">
        <div className="w-[5rem] px-3 py-1.5 h-[1.5rem] bg-slate-200 rounded-lg">
          <div className="w-full h-full bg-slate-300 rounded-lg"></div>
        </div>
        <div className="flex flex-col gap-y-2 w-full">
          <div className="w-3/4 h-3 bg-slate-200 rounded-lg"></div>
          <div className="w-1/2 h-3 bg-slate-200 rounded-lg"></div>
          <div className="w-1/4 h-3 bg-slate-200 rounded-lg"></div>
          <div className="w-1/2 h-3 bg-slate-200 rounded-lg"></div>
        </div>
      </div>
      <div className="w-1/5 h-3 bg-slate-300 rounded-lg"></div>
      <div className="flex flex-col gap-y-2 w-full">
        <div className="w-3/4 h-3 bg-slate-200 rounded-lg"></div>
        <div className="w-1/2 h-3 bg-slate-200 rounded-lg"></div>
      </div>
      <div className="w-1/6 h-3 bg-slate-300 rounded-lg"></div>
      <table className="w-full border-collapse animate-pulse">
        <thead>
          <tr className="bg-gray-100">
            <th className="border border-gray-300 px-4 py-2 text-left">
              <div className="w-1/5 h-3 bg-slate-300 rounded-lg"></div>
            </th>
            <th className="border border-gray-300 px-4 py-2 text-left">
              <div className="w-1/4 h-3 bg-slate-300 rounded-lg"></div>
            </th>
          </tr>
        </thead>
        <tbody>
          {[...Array(3)].map((_, index) => (
            <tr key={index} className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}>
              <td className="border border-gray-300 px-4 py-2">
                <div className="h-4 bg-slate-200 rounded"></div>
              </td>
              <td className="border border-gray-300 px-4 py-2">
                <div className="h-4 bg-slate-200 rounded"></div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  if (!checkResult?.verified_data) {
    const filteredStatusMessages =
      checkResult?.status_messages?.filter(
        (message) => message.event === "status" && !message.content.status?.startsWith("Loading:"),
      ) ?? [];

    const lastStatusMessage =
      filteredStatusMessages.length > 0 ? filteredStatusMessages[filteredStatusMessages.length - 1] : null;

    return (
      <div className="flex flex-col gap-y-2 rounded-lg md:w-full">
        {Header}
        {lastStatusMessage && <CheckSectionStatusMessage message={lastStatusMessage.content.status} />}
        {MCCSkeleton}
      </div>
    );
  }

  const level = checkResult.passed ? "success" : "failure";

  const evidenceList = [];
  checkResult.verified_data.candidate_mcc_codes?.forEach(
    (
      mccCode:
        | { mcc_code: string; mcc_code_description: string }
        | {
            industry_code: { code: number; title: string };
            reason_for_industry_classification: string;
            reason_against_industry_classification: string;
          },
    ) => {
      if ("mcc_code" in mccCode) {
        evidenceList.push({
          found: mccCode.mcc_code,
          evidence: mccCode.mcc_code_description,
        });
      } else {
        evidenceList.push({
          found: mccCode.industry_code.code,
          evidence: mccCode.reason_for_industry_classification,
        });
      }
    },
  );

  return (
    <div className="w-full text-sm overflow-hidden flex flex-col gap-y-5">
      <div className="flex items-center gap-x-2">
        <Pin sx={{ fontSize: "1.25rem" }} className="text-slate-700" />
        <span className="text-xl font-semibold">Merchant Categorization (MCC)</span>
      </div>
      {checkResult.passed !== null && !checkResult.passed && <Alert message={checkResult.answer} level="failure" />}
      {checkResult.status === "running" ? (
        <div className="h-[200px]">
          {evidenceList.length > 0 ? <SmartSkeleton evidenceList={evidenceList} /> : MCCSkeleton}
        </div>
      ) : (
        <div className="grid  gap-x-3 gap-y-2">
          <div className="flex flex-col gap-4 py-5 px-4">
            <div className="flex flex-row items-center gap-2">
              {checkResult.check_result?.mcc_code && (
                <div className="flex-shrink-0">
                  <WrappedValue
                    text={
                      <div className="flex gap-x-1 items-center">
                        {level === "success" ? (
                          <CheckRounded sx={{ fontSize: "1rem" }} />
                        ) : level === "failure" ? (
                          <CancelRounded sx={{ fontSize: "1rem" }} />
                        ) : null}
                        <span>{checkResult.check_result?.mcc_code}</span>
                      </div>
                    }
                    level={level}
                  />
                </div>
              )}
              {checkResult.check_result?.mcc_title && (
                <div className="font-medium">{checkResult.check_result.mcc_title}</div>
              )}
            </div>

            {checkResult.check_result?.mcc_code_description && (
              <div className="flex flex-col gap-y-2">
                <div>{checkResult.check_result.mcc_code_description}</div>
              </div>
            )}

            {((checkResult.check_result?.candidate_mcc_codes &&
              checkResult.check_result.candidate_mcc_codes.length > 0 &&
              checkResult.check_result.candidate_mcc_codes[0].short_reason) ||
              (checkResult.check_result?.mcc_codes &&
                checkResult.check_result.mcc_codes.length > 0 &&
                checkResult.check_result.mcc_codes[0].short_reason)) && (
              <div className="flex flex-col gap-y-2">
                <div className="font-medium">Alternate Classifications:</div>
                <div className="flex flex-col gap-y-4">
                  {(checkResult.check_result?.candidate_mcc_codes || checkResult.check_result?.mcc_codes)
                    ?.filter((mcc) => {
                      const mccCode = mcc.code || mcc.mcc_code;
                      return String(mccCode) !== String(checkResult.check_result?.mcc_code);
                    })
                    .slice(0, 4)
                    .map((mcc, index) => (
                      <div key={index} className="flex flex-col gap-y-1">
                        <div className="flex items-center gap-x-2">
                          <WrappedValue text={mcc.code || mcc.mcc_code} level="light" />
                          {mcc.title ? (
                            <div className="font-medium">{mcc.title}</div>
                          ) : mcc.mcc_code_description ? (
                            <div className="text-sm text-gray-500 pl-14 italic">
                              <div className="font-medium">{mcc.mcc_code_description}</div>
                            </div>
                          ) : null}
                        </div>
                        <div className="text-sm text-gray-500 pl-14 italic">{mcc.short_reason}</div>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export const BusinessOwners = ({ checkResult, sourcesDictionary }) => {
  const Header = (
    <div className="flex items-center gap-x-2">
      <PeopleRounded sx={{ fontSize: "1.25rem" }} className="text-slate-700" />
      <span
        className={twJoin(
          "text-xl font-semibold",
          checkResult?.status === "running" || !checkResult?.verified_data ? shimmerEffectClassNames : "",
        )}
      >
        Business Owners
      </span>
    </div>
  );

  if (!checkResult?.check_result && ["running", "pending"].includes(checkResult?.status)) {
    const filteredStatusMessages =
      checkResult?.status_messages?.filter(
        (message: { event: string; content: { status: string } }) =>
          message.event === "status" && !message.content.status?.startsWith("Loading:"),
      ) ?? [];
    const lastStatusMessage =
      filteredStatusMessages.length > 0 ? filteredStatusMessages[filteredStatusMessages.length - 1] : null;

    return (
      <div className="flex flex-col gap-y-2 rounded-lg md:w-full">
        {Header}
        {lastStatusMessage && <CheckSectionStatusMessage message={lastStatusMessage.content.status} />}
        <GenericSkeleton />
      </div>
    );
  }
  const businessOwnerMatches = checkResult.check_result?.business_owner_matches || [];

  return checkResult.check_result ? (
    <div className="w-full text-sm overflow-hidden flex flex-col gap-y-5">
      {Header}
      {checkResult.answer && <Alert message={checkResult.answer} level={checkResult.passed ? "success" : "failure"} />}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-3 gap-y-2">
        {businessOwnerMatches.map((owner, index) => (
          <BusinessOwnerEntry
            key={index}
            didCheckPassed={checkResult.passed}
            data={owner}
            sources={
              owner.source_ids
                ? owner.source_ids.map((sourceId) => sourcesDictionary[sourceId])
                : owner.source_id
                  ? [sourcesDictionary[owner.source_id]]
                  : []
            }
          />
        ))}
      </div>
    </div>
  ) : null;
};

const BusinessOwnerEntry = ({
  didCheckPassed,
  data,
  sources,
}: {
  didCheckPassed: boolean;
  data: any;
  sources: any[];
}) => {
  const linkedInSource = sources.find((source) => source?.structured_data?.scrape_type === "linkedin_profile");
  const xTwitterSource = sources.find((source) => source?.structured_data?.scrape_type === "twitter");
  const facebookSource = sources.find((source) => source?.structured_data?.scrape_type === "facebook");
  const instragramSource = sources.find((source) => source?.structured_data?.scrape_type === "instagram");
  const opencorporatesSource = sources.find((source) => source?.structured_data?.type === "OpenCorporatesSearchResult");

  const sourceToUse =
    (linkedInSource || opencorporatesSource || xTwitterSource || facebookSource || instragramSource) ?? null;
  const domain = sourceToUse?.url ? new URL(sourceToUse.url).hostname : "";

  const foundInBusinessOwnersContentExists = "found_in_business_owners_content" in data;
  const selfAttestedAndFoundInBusinessOwnersContent =
    data.found_in_business_owners_content && data.source_ids?.length > 0;
  const selfAttestedAndNotFound =
    data.found_in_business_owners_content === false ||
    (data.found_in_business_owners_content && !data.source_ids?.length);
  const notSelfAttestedButFound = data.found_in_business_owners_content === null;

  return (
    <div
      className={`h-fit w-full p-5 truncate text-slate-700 flex flex-col gap-y-5 rounded-lg ${
        foundInBusinessOwnersContentExists
          ? selfAttestedAndFoundInBusinessOwnersContent
            ? "bg-slate-50"
            : selfAttestedAndNotFound
              ? "border border-red-500 bg-white"
              : notSelfAttestedButFound
                ? "border border-slate-300 bg-white"
                : "bg-slate-50"
          : "bg-slate-50"
      }`}
    >
      <div className="flex flex-col gap-y-4 w-full">
        <div className="flex flex-col w-full">
          <div className="flex w-full items-center justify-between gap-x-2">
            <span className="capitalize text-base font-semibold">
              {data.full_name_as_spelled_in_input || data.name}
            </span>

            {foundInBusinessOwnersContentExists ? (
              selfAttestedAndFoundInBusinessOwnersContent && didCheckPassed ? (
                <CheckCircleRounded sx={{ fontSize: "1rem" }} className="text-green-600" />
              ) : selfAttestedAndNotFound ? (
                <CancelRounded sx={{ fontSize: "1rem" }} className="text-red-600" />
              ) : notSelfAttestedButFound ? (
                <AutoAwesomeRounded sx={{ fontSize: "1rem" }} className="text-slate-500" />
              ) : null
            ) : null}
          </div>

          {"full_name_as_spelled_in_input" in data &&
            data.name !== data.full_name_as_spelled_in_input &&
            data.found_in_business_owners_content && <span className="text-sm italic">found as '{data.name}'</span>}
          {(data.title || data.business_name) && (
            <span className="text-sm capitalize">
              {data.title}
              {data.business_name ? ` at ${data.business_name}` : null}
            </span>
          )}
        </div>
        {linkedInSource ||
        opencorporatesSource ||
        xTwitterSource ||
        facebookSource ||
        instragramSource ||
        sources.filter(Boolean).length > 0 ? (
          <div>
            {linkedInSource ? (
              <div className="flex flex-col gap-y-3">
                <h3 className="flex items-center gap-1 font-semibold text-sm mb-1 break-words overflow-hidden">
                  {domain && (
                    <img
                      src={`https://www.google.com/s2/favicons?domain=${domain}`}
                      alt={`${domain} favicon`}
                      className="w-4 h-4 rounded-full"
                    />
                  )}
                  <a
                    href={
                      (linkedInSource.url || linkedInSource.structured_data?.url)?.startsWith("http")
                        ? linkedInSource.url || linkedInSource.structured_data?.url
                        : `https://${linkedInSource.url || linkedInSource.structured_data?.url}`
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-brand-purple hover:underline truncate"
                  >
                    LinkedIn
                  </a>
                </h3>
                <div className="flex flex-col gap-y-3 text-slate-700">
                  {linkedInSource.structured_data?.EXPERIENCE.map(
                    (experience: { title: string | null; subtitle: string | null }, index: number) => (
                      <div key={index} className="flex flex-col">
                        {experience.title && <span className="font-semibold">{experience.title}</span>}
                        {experience.subtitle && <span>{experience.subtitle}</span>}
                      </div>
                    ),
                  )}
                </div>
              </div>
            ) : opencorporatesSource ? (
              <div className="flex flex-col gap-y-3">
                <h3 className="flex items-center gap-1 font-semibold text-sm mb-1 break-words overflow-hidden">
                  {domain && (
                    <img
                      src={`https://www.google.com/s2/favicons?domain=${domain}`}
                      alt={`${domain} favicon`}
                      className="w-4 h-4 rounded-full"
                    />
                  )}
                  <a
                    href={
                      (opencorporatesSource.url || opencorporatesSource.structured_data?.url)?.startsWith("http")
                        ? opencorporatesSource.url || opencorporatesSource.structured_data?.url
                        : `https://${opencorporatesSource.url || opencorporatesSource.structured_data?.url}`
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-brand-purple hover:underline truncate"
                  >
                    Opencorporates
                  </a>
                </h3>
                <div>
                  <dl className="flex flex-col gap-y-1">
                    {opencorporatesSource.structured_data.registry_url && (
                      <div className="flex gap-x-2">
                        <dt className="font-semibold">Registry URL:</dt>
                        <dd className="truncate">
                          <a
                            href={opencorporatesSource.structured_data.registry_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-brand-purple hover:underline truncate"
                          >
                            {opencorporatesSource.structured_data.registry_url}
                          </a>
                        </dd>
                      </div>
                    )}
                    {opencorporatesSource.structured_data.source && (
                      <div className="flex gap-x-2">
                        <dt className="font-semibold">Source:</dt>
                        <dd>{opencorporatesSource.structured_data.source}</dd>
                      </div>
                    )}
                    {opencorporatesSource.structured_data.officers?.length > 0 && (
                      <div className="flex flex-col gap-y-1">
                        <dt className="font-semibold">Officers:</dt>
                        <dd>
                          {opencorporatesSource.structured_data.officers.map(
                            (officer: {
                              officer: { id: string; name: string; position: string; opencorporates_url: string };
                            }) => (
                              <div key={officer.officer.id} className="flex gap-x-2 ml-2">
                                <dt className="font-semibold">
                                  <a
                                    href={officer.officer.opencorporates_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-brand-purple hover:underline truncate"
                                  >
                                    {officer.officer.name}
                                  </a>
                                </dt>
                                <dd className="capitalize">{officer.officer.position}</dd>
                              </div>
                            ),
                          )}
                        </dd>
                      </div>
                    )}
                  </dl>
                </div>
              </div>
            ) : xTwitterSource ? (
              <div className="flex flex-col gap-y-3">
                <h3 className="flex items-center gap-1 font-semibold text-sm mb-1 break-words overflow-hidden">
                  {domain && (
                    <img
                      src={`https://www.google.com/s2/favicons?domain=${domain}`}
                      alt={`${domain} favicon`}
                      className="w-4 h-4 rounded-full"
                    />
                  )}
                  <a
                    href={xTwitterSource.url || xTwitterSource.structured_data?.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-brand-purple hover:underline truncate"
                  >
                    {xTwitterSource.structured_data?.userName
                      ? `@${xTwitterSource.structured_data.userName}`
                      : xTwitterSource.url || xTwitterSource.structured_data?.url}
                  </a>
                </h3>
                <div className="flex gap-x-3">
                  {xTwitterSource.structured_data?.profilePicture && (
                    <img
                      src={xTwitterSource.structured_data?.profilePicture}
                      alt="Company Logo"
                      className="w-12 h-12 rounded-full object-cover"
                    />
                  )}
                  <div className="flex flex-col gap-y-0.5">
                    <div className="flex flex-wrap items-baseline gap-x-1">
                      <span className="text-base text-bold">{xTwitterSource.structured_data.name}</span>
                    </div>
                    {xTwitterSource.structured_data.followers && (
                      <div className="text-slate-500">
                        {xTwitterSource.structured_data.followers > 1000
                          ? numeral(xTwitterSource.structured_data.followers).format("0.0a")
                          : xTwitterSource.structured_data.followers}{" "}
                        Followers
                      </div>
                    )}
                  </div>
                </div>
                {xTwitterSource.structured_data.description && (
                  <p className="text-slate-700 text-wrap">{xTwitterSource.structured_data.description}</p>
                )}
              </div>
            ) : facebookSource ? (
              <div className="flex flex-col gap-y-3">
                <h3 className="flex items-center gap-1 font-semibold text-sm mb-1 break-words overflow-hidden">
                  {domain && (
                    <img
                      src={`https://www.google.com/s2/favicons?domain=${domain}`}
                      alt={`${domain} favicon`}
                      className="w-4 h-4 rounded-full"
                    />
                  )}
                  <a
                    href={
                      (facebookSource?.url || facebookSource?.structured_data?.url)?.startsWith("http")
                        ? facebookSource?.url || facebookSource?.structured_data?.url
                        : `https://${facebookSource?.url || facebookSource?.structured_data?.url}`
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-brand-purple hover:underline truncate"
                  >
                    {facebookSource?.structured_data?.pageName ||
                      facebookSource?.url ||
                      facebookSource?.structured_data?.url}
                  </a>
                </h3>
                {facebookSource.structured_data?.followers && (
                  <p className="mt-2 text-slate-700 text-wrap">{`${
                    facebookSource.structured_data.followers > 1000
                      ? numeral(facebookSource.structured_data.followers).format("0.0a")
                      : facebookSource.structured_data.followers
                  } followers`}</p>
                )}
                {facebookSource.structured_data?.about_me && (
                  <p className="mt-2 text-slate-700 text-wrap">{facebookSource.structured_data.about_me.text}</p>
                )}
              </div>
            ) : instragramSource ? (
              <div className="flex flex-col gap-y-3">
                <h3 className="flex items-center gap-1 font-semibold text-sm mb-1 break-words overflow-hidden">
                  {domain && (
                    <img
                      src={`https://www.google.com/s2/favicons?domain=${domain}`}
                      alt={`${domain} favicon`}
                      className="w-4 h-4 rounded-full"
                    />
                  )}
                  {instragramSource.structured_data?.username && (
                    <>
                      <a
                        href={
                          (instragramSource.url || instragramSource.structured_data?.url)?.startsWith("http")
                            ? instragramSource.url || instragramSource.structured_data?.url
                            : `https://${instragramSource.url || instragramSource.structured_data?.url}`
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-brand-purple hover:underline truncate"
                      >
                        {`@${instragramSource.structured_data?.username}`}
                      </a>
                      {instragramSource.structured_data?.verified && (
                        <svg className="w-[20px] h-[20px] text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                          <path d="M10 2C5.59 2 2 5.59 2 10s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zm-0.83 12l-3.17-3.17 1.13-1.13 2.04 2.04 4.46-4.46 1.13 1.13L9.17 14z" />
                        </svg>
                      )}
                    </>
                  )}
                </h3>
                {instragramSource.structured_data.biography && (
                  <p className="text-slate-700 text-wrap">{instragramSource.structured_data.biography}</p>
                )}
                <div className="flex flex-col gap-y-4">
                  <div className="flex flex-wrap items-baseline gap-x-1">
                    {instragramSource.structured_data.externalUrl && (
                      <div className="flex items-center gap-x-1">
                        <LinkRounded sx={{ fontSize: "1rem" }} className="text-slate-500" />
                        <a
                          href={instragramSource.structured_data.externalUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="truncate text-slate-500"
                        >
                          {instragramSource.structured_data.externalUrl}
                        </a>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-wrap justify-between items-center font-semibold text-sm gap-y-1">
                    <div className="md:text-center">
                      {instragramSource.structured_data.postsCount !== null
                        ? `${numeral(instragramSource.structured_data.postsCount).format("0,0")} posts`
                        : "-"}
                    </div>
                    <div className="md:text-center">
                      {instragramSource.structured_data.followersCount !== null
                        ? `${
                            instragramSource.structured_data.followersCount > 1000
                              ? numeral(instragramSource.structured_data.followersCount).format("0.0a")
                              : instragramSource.structured_data.followersCount
                          } followers`
                        : "-"}
                    </div>
                    <div className="md:text-center">
                      {instragramSource.structured_data.followsCount !== null
                        ? `${
                            instragramSource.structured_data.followsCount > 1000
                              ? numeral(instragramSource.structured_data.followsCount).format("0.0a")
                              : instragramSource.structured_data.followsCount
                          } following`
                        : "-"}
                    </div>
                  </div>
                  {instragramSource.structured_data.latestPosts && (
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-0.5">
                      {instragramSource.structured_data.latestPosts
                        .filter((post: { displayUrl: string }) => {
                          return !post.displayUrl?.includes("instagram");
                        })
                        .slice(0, 3)
                        .map((post: { displayUrl: string; url: string; id: string }) => (
                          <a href={post.url} key={post.id} target="_blank" rel="noreferrer">
                            <img src={post.displayUrl} className="object-cover aspect-square" />
                          </a>
                        ))}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              sources.filter(Boolean).length > 0 &&
              sources.filter(Boolean).map((source, index) => {
                const domain = source?.url ? new URL(source.url).hostname : "";
                return (
                  <div key={`business-owner-source-${index}`}>
                    <div className="mt-3">
                      <p className="text-sm mb-2 break-words whitespace-normal text-slate-500">
                        <h3 className="flex items-center gap-1 font-semibold text-sm mb-1 break-words overflow-hidden">
                          {domain && (
                            <img
                              src={`https://www.google.com/s2/favicons?domain=${domain}`}
                              alt={`${domain} favicon`}
                              className="w-4 h-4 rounded-full"
                            />
                          )}
                          <a
                            href={
                              (source?.url || source?.structured_data?.url)?.startsWith("http")
                                ? source?.url || source?.structured_data?.url
                                : `https://${source?.url || source?.structured_data?.url}`
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-brand-purple hover:underline truncate"
                          >
                            {source.url}
                          </a>
                        </h3>
                        {source.summary
                          ? source.summary?.length > 150
                            ? `${source.summary.slice(0, 150)}...`
                            : source.summary
                          : null}
                      </p>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        ) : (
          <span className="text-slate-500 italic">No sources found</span>
        )}
      </div>
      {data.explanation ? (
        <p
          className={twJoin(
            "text-wrap px-4 py-2 rounded-xl",
            selfAttestedAndFoundInBusinessOwnersContent
              ? "text-slate-500"
              : selfAttestedAndNotFound
                ? "text-red-600 bg-red-50"
                : notSelfAttestedButFound
                  ? "text-slate-700 bg-slate-100"
                  : "",
          )}
        >
          {data.explanation}
        </p>
      ) : null}
    </div>
  );
};

export const LowProfileConfidenceBanner = () => {
  return (
    <div className="flex items-center gap-4 p-4 bg-red-100 rounded-xl">
      <div className="shrink-0">
        <svg width="60" height="32" viewBox="0 0 60 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            opacity="0.2"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M47.2819 8.52628C48.1656 7.62483 49.6128 7.61044 50.5142 8.49416C56.0127 13.8844 59.4284 21.4025 59.4284 29.7143C59.4284 30.9767 58.4051 32 57.1427 32C55.8803 32 54.857 30.9767 54.857 29.7143C54.857 22.6804 51.9716 16.3245 47.314 11.7586C46.4126 10.8749 46.3982 9.42774 47.2819 8.52628Z"
            fill="#B91C1C"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.1465 8.52628C13.0302 9.42774 13.0158 10.8749 12.1144 11.7586C7.45682 16.3245 4.57143 22.6804 4.57143 29.7143C4.57143 30.9767 3.54808 32 2.28571 32C1.02335 32 0 30.9767 0 29.7143C0 21.4025 3.41571 13.8844 8.91418 8.49416C9.81564 7.61044 11.2628 7.62483 12.1465 8.52628Z"
            fill="#B91C1C"
          />
          <path
            opacity="0.2"
            d="M29.7143 4.57143C24.9913 4.57143 20.579 5.87147 16.8079 8.13192C15.7251 8.78093 14.3213 8.42932 13.6722 7.34657C13.0232 6.26382 13.3748 4.85995 14.4576 4.21094C18.919 1.53674 24.1406 0 29.7143 0C35.2879 0 40.5095 1.53674 44.9709 4.21094C46.0537 4.85995 46.4053 6.26382 45.7563 7.34657C45.1073 8.42932 43.7034 8.78094 42.6206 8.13192C38.8495 5.87147 34.4372 4.57143 29.7143 4.57143Z"
            fill="#B91C1C"
          />
        </svg>
      </div>
      <div className="text-red-700">
        <h3 className="font-semibold">Limited confidence profile</h3>
        <p className="text-sm">
          This profile is generated from external sources and may not be fully accurate. Please review the listed
          sources for verification.
        </p>
      </div>
    </div>
  );
};

export default ParchaReport;
