// @ts-nocheck
import { ChangeEvent, useEffect, useRef, useState } from "react";
import Alert from "@/components/TailwindComponents/Alert";
import { CheckCircleRounded, CancelRounded, UploadFileRounded, OpenInNew } from "@mui/icons-material";
import InputWithOverlappingLabel from "./TailwindComponents/InputWithOverlappingLabel";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useParchaApi } from "@/hooks/useParchaApi";
import FakeProgress from "fake-progress";
import Progress from "@/components/ui/progress";
import DocumentValue from "./TailwindComponents/DocumentValue";
import { Icon } from "@mui/material";
import { twJoin } from "tailwind-merge";
import CheckStatusMessage from "./TailwindComponents/CheckStatusMessage";
import { Endpoint } from "@/types";
import fastBankLogo from "@/assets/fastbank_logo.png";
import valleyLogo from "@/assets/valley-logo.png";
import brexLogo from "@/assets/brex_logo.png";
import airwallexLogo from "@/assets/airwallex_logo.png";
import pipeLogo from "@/assets/pipe_logo.png";
/**
 *
 * TODO:
 * 1. Get endpoint from props
 * 2. Use checkId to match results with document component
 * 3. Add back interval to check job status
 *
 */
const IncorporationDocRemediationPage = () => {
  const [formData, setFormData] = useState({
    businessName: "Parcha Labs, Inc.",
  });

  const [checkJobIds, setCheckJobIds] = useState({});
  const [latestApplicationState, setLatestApplicationState] = useState({});
  const [intervalId, setIntervalId] = useState(null);
  const [isUploadingDocument, setIsUploadingDocument] = useState(false);
  const [isApplicationSubmitted, setIsApplicationSubmitted] = useState(false);

  const [endpoints, setEndpoints] = useState<Endpoint[]>([]);
  const [endpoint, setEndpoint] = useState<Endpoint | null>(null);

  const [searchParams] = useSearchParams();
  const applicationId = searchParams.get("application_id");
  const navigate = useNavigate();

  const parchaApi = useParchaApi();

  const fetchEndpoints = async () => {
    const customerAgents = await parchaApi.getCustomerAgents();
    const { endpoints } = customerAgents as EndpointsAndTenant;
    setEndpoints(endpoints);
  };

  const fetchAgent = async () => {
    if (endpoint) {
      const agent = await parchaApi.getSimpleAgent(endpoint.endpointUrl, endpoint.agent_key);
    }
  };
  /** FETCHERS END */

  // Step 1: Fetch all endpoints to populate the dropdown
  useEffect(() => {
    fetchEndpoints();
  }, []);

  // Step 2: When we have the endpoints and/or agentKey, we select the current endpoint
  useEffect(() => {
    if (endpoints.length > 0) {
      const remediationEndpoint = endpoints.find((endpoint) => endpoint.agentKey === "remediation-v1");

      if (!remediationEndpoint) {
        navigate("/jobs");
      }

      setEndpoint(remediationEndpoint);
    }
    fetchAgent();
  }, [endpoints]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const runFileCheck = async (checkId: string, document) => {
    if (!endpoint || !applicationId) return;

    const checkRunResponse = await parchaApi.runCheck(endpoint.endpointUrl, checkId, {
      agent_key: "remediation-v1",
      kyb_schema: {
        id: applicationId,
        self_attested_data: {
          business_name: formData.businessName,
          registered_business_name: "Parcha Labs Incorporated",
          proof_of_address_documents: checkId === "kyb.proof_of_address_verification" ? [document] : undefined,
          incorporation_documents: checkId === "kyb.incorporation_document_verification" ? [document] : undefined,
          business_ownership_documents: checkId === "kyb.business_ownership_verification_tool" ? [document] : undefined,
          address_of_operation: {
            street_1: "755 Sansome Street",
            street_2: "Ste 350",
            city: "San Francisco",
            state: "CA",
            country_code: "US",
            postal_code: "94111",
          },
          address_of_incorporation: {
            street_1: "251 Little Falls Drive",
            street_2: null,
            city: "Wilmington",
            state: "DE",
            country_code: "US",
            postal_code: "19808",
          },
          incorporation_date: "2023-03-29",
          website: "https://parcha.ai",
          business_purpose: "B2B Software as a Service",
          description: "AI agents for ops and compliance workflow automation.",
          industry: "Technology",
          tin_number: "92-3265708",
          partners: [],
          customers: [],
          source_of_funds: ["Investment", "Revenue"],
          promo_marketing_documents: [],
        },
        associated_individuals: [
          {
            id: "parcha-kyc-test",
            self_attested_data: {
              first_name: "Ajmal",
              middle_name: "Arshan",
              last_name: "Asver",
              date_of_birth: "1985-12-24",
              address: {
                street_1: "746 4th Ave",
                street_2: null,
                city: "San Francisco",
                state: "CA",
                country_code: "US",
                postal_code: "94118",
              },
              country_of_nationality: "US",
              country_of_residence: "US",
              place_of_birth: "Colombo, Sri Lanka",
              sex: "Male",
              email: "aj@parcha.ai",
              phone: "4152902190",
              title: "CEO",
              is_applicant: true,
              is_business_owner: true,
              business_ownership_percentage: 0.45,
              proof_of_address_documents: [
                {
                  url: "https://parcha-ai-backtest-data.s3.amazonaws.com/parcha/AJ_proof_of_address.pdf",
                  file_name: "AJ_proof_of_address.pdf",
                  source_type: "file_url",
                },
              ],
            },
            government_id_check: {
              data: {
                id_first_name: "Ajmal",
                id_last_name: "Asver",
                id_middle_names: "Arshan",
                id_date_of_birth: "1985-12-24",
                id_type: "Driver's License",
                id_number: "987654321",
                id_address: {
                  street_1: "746 4th Ave",
                  street_2: null,
                  city: "San Francisco",
                  state: "CA",
                  country_code: "US",
                  postal_code: "94118",
                },
                id_country: "US",
                id_phone: "4152902190",
                id_front_image_url: "https://parcha-ai-backtest-data.s3.amazonaws.com/parcha/IMG_0627.jpg",
                id_back_image_url: null,
                id_face_match_image_url: null,
                id_face_match_video_url: null,
                id_verification_vendor: "Veriff",
                vendor_verification_url: null,
                vendor_validated_document: true,
                vendor_id_verification_data: {
                  veriff_id: "123456789",
                  veriff_status: "approved",
                  veriff_result: "clear",
                  veriff_code: "1002",
                },
              },
            },
          },
          {
            id: "parcha-kyc-test-2",
            self_attested_data: {
              first_name: "Miguel",
              middle_name: "Angel",
              last_name: "Rios Berrios",
              date_of_birth: "1986-04-25",
              address: {
                street_1: "216 Encounter Bay",
                street_2: null,
                city: "Alameda",
                state: "CA",
                country_code: "US",
                postal_code: "94502",
              },
              country_of_nationality: "US",
              country_of_residence: "US",
              place_of_birth: "Aibonito, Puerto Rico",
              sex: "Male",
              email: "miguel@miguelrios.org",
              phone: "5103457204",
              title: "Chief Technology Officer",
              is_applicant: false,
              is_business_owner: true,
              business_ownership_percentage: 0.45,
              proof_of_address_documents: [
                {
                  url: "https://parcha-ai-backtest-data.s3.amazonaws.com/parcha/bill-miguel.pdf",
                  file_name: "bill-miguel.pdf",
                  source_type: "file_url",
                },
              ],
            },
            government_id_check: {
              data: {
                id_first_name: "Miguel",
                id_last_name: "Rios Berrios",
                id_middle_names: "Angel",
                id_date_of_birth: "1986-04-25",
                id_type: "Driver's License",
                id_number: "Y4655272",
                id_address: {
                  street_1: "216 Encounter Bay",
                  street_2: null,
                  city: "Alameda",
                  state: "CA",
                  country_code: "US",
                  postal_code: "94502",
                },
                id_country: "US",
                id_phone: "4153457204",
                id_front_image_url: "https://parcha-ai-backtest-data.s3.amazonaws.com/parcha/IMG_4837_Original.JPG",
                id_back_image_url: null,
                id_face_match_image_url: null,
                id_face_match_video_url: null,
                id_verification_vendor: "Veriff",
                vendor_verification_url: null,
                vendor_validated_document: true,
                vendor_id_verification_data: {
                  veriff_id: "123456789",
                  veriff_status: "approved",
                  veriff_result: "clear",
                  veriff_code: "1002",
                },
              },
            },
          },
        ],
      },
    });
    setCheckJobIds({ ...checkJobIds, [checkId]: checkRunResponse.id });
    return checkRunResponse;
  };

  useEffect(() => {
    if (!applicationId || !endpoint) return;

    const getApplicationState = async () => {
      const latestApplicationState = await parchaApi
        .getLatestApplicationState(endpoint.endpointUrl, applicationId, "remediation-v1")
        .catch((err) => ({ current_check_results: [], past_check_results: [] }));
      setLatestApplicationState(latestApplicationState);
    };

    getApplicationState();
  }, [applicationId, endpoint]);

  useEffect(() => {
    if (!checkJobIds || Object.keys(checkJobIds).length === 0 || !applicationId) return;

    const intervalId = setInterval(async () => {
      if (isUploadingDocument) return;

      const latestApplicationState = await parchaApi.getLatestApplicationState(
        endpoint.endpointUrl,
        applicationId,
        "remediation-v1",
      );

      setLatestApplicationState(latestApplicationState);
    }, 2000);

    setIntervalId(intervalId);

    return () => {
      clearInterval(intervalId);
    };
  }, [checkJobIds, isUploadingDocument]);

  useEffect(() => {
    if (latestApplicationState?.current_check_results?.length > 0) {
      const allCompleted = latestApplicationState?.current_check_results?.every(
        (checkResult) =>
          checkResult.answer !== null && checkResult.passed !== null && (checkResult.follow_up || checkResult.passed),
      );

      if (allCompleted) {
        clearInterval(intervalId);
      }
    }
  }, [latestApplicationState]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    await parchaApi.updateApplicationStatus(endpoint.endpointUrl, applicationId, "submitted");
    setIsApplicationSubmitted(true);
  };

  const incorporationResult = latestApplicationState?.current_check_results?.find(
    (result) => result.command_id === "kyb.incorporation_document_verification",
  );

  const pastIncorporationResults = latestApplicationState?.past_check_results?.filter(
    (result) => result.command_id === "kyb.incorporation_document_verification",
  );

  return isApplicationSubmitted ? (
    <div className="w-full h-full flex justify-center">
      <div className="mx-auto flex flex-col gap-y-3 mt-10 text-slate-900 text-xl">
        <h3 className="text-green-600 font-semibold">Success!</h3>
        <p className="font-semibold">Your documents have been validated and submitted</p>
        <p className="font-normal text-sm">We will be in touch after we complete reviewing your application</p>
        <div className="flex gap-x-5 mt-7">
          <button
            type="button"
            className="text-xs relative flex justify-center items-center rounded-md p-2 h-4 border border-solid border-slate-300 text-slate-900 focus:z-10 "
            onClick={() => (window.location.href = `/remediation?application_id=parcha-${Date.now()}`)}
          >
            <UploadFileRounded sx={{ fontSize: "1rem" }} className="mr-1 h-4 w-4" aria-hidden="true" />
            <span className="line-clamp-1">Submit more documents</span>
          </button>
          <button
            type="button"
            className="text-xs relative flex justify-center items-center rounded-md p-2 h-4 border border-solid border-brand-purple bg-brand-purple text-white focus:z-10 "
            onClick={() => window.open(`/applications/${applicationId}`)}
          >
            <span className="line-clamp-1">View in Parcha</span>
            <OpenInNew sx={{ fontSize: "1rem" }} className="ml-1 h-4 w-4" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  ) : (
    <div className="grid grid-rows-[60px_1fr] h-screen auto-rows-auto">
      <div className="flex items-center gap-x-3 px-6 py-3 border-b border-b-slate-200">
        <img src={pipeLogo} alt="Valley logo" width={100} height={60} />
      </div>
      <div className="w-full h-full p-0 lg:px-6 flex items-center justify-center text-xs">
        <div className="flex flex-col mx-auto p-8 w-0 min-w-full lg:min-w-[50rem] bg-white gap-10 h-full justify-center">
          <div className="grow-0">
            <h1 className="font-semibold text-lg">Provide Business & Identification Documents Securely</h1>
            <span>Validate and submit your encypted files</span>
          </div>
          <form className="h-full" onSubmit={handleSubmit}>
            <div className="h-0 min-h-full flex flex-col gap-10 grow overflow-y-auto">
              <InputWithOverlappingLabel
                label="Business Name"
                value={formData.businessName}
                name="businessName"
                id="businessName"
                fieldName="businessName"
                onChangeHandler={handleChange}
                className="w-1/2 mt-2"
              />
              <DocumentField
                iconKey="description"
                title="Business Registration"
                description={
                  <div className="flex flex-col gap-y-2">
                    <p>
                      Document(s) to verify the registered business name, address of incorporation, date of
                      incorporation, and business number.
                    </p>
                    <p>Must be signed by an agent</p>
                  </div>
                }
                fieldKey="incorporationDocs"
                endpoint={endpoint}
                setIsUploadingDocument={setIsUploadingDocument}
                afterFileUploadedCallback={(uploadedFile) => {
                  const filteredCurrentCheckResults = latestApplicationState?.current_check_results?.filter(
                    (result) => result.command_id !== "kyb.incorporation_document_verification",
                  );
                  const filteredPastCheckResults = latestApplicationState?.past_check_results?.filter(
                    (result) => result.command_id !== "kyb.incorporation_document_verification",
                  );
                  if (filteredCurrentCheckResults?.length === 0) {
                    setLatestApplicationState({
                      ...latestApplicationState,
                      current_check_results: [...filteredCurrentCheckResults],
                      past_check_results: [...filteredPastCheckResults],
                    });
                  }
                  return runFileCheck("kyb.incorporation_document_verification", uploadedFile);
                }}
                afterFileDeletedCallback={() => {}}
                result={incorporationResult}
                pastResults={pastIncorporationResults}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const DocumentFieldLabel = ({ iconKey, title, description }) => {
  return (
    <div className="flex gap-2.5">
      <Icon sx={{ fontSize: "1.25rem" }} className="material-icons-round text-slate-400">
        {iconKey}
      </Icon>
      <div className="flex flex-col gap-y-2.5">
        {title && <span className="font-semibold text-sm text-slate-700">{title}</span>}
        {description && <span className="text-xs text-slate-600">{description}</span>}
      </div>
    </div>
  );
};

const DocumentUploader = ({
  fieldKey,
  endpoint,
  afterFileUploadedCallback,
  setIsUploadingDocument,
  result,
}: {
  fieldKey: string;
  endpoint: { endpointUrl: string };
  afterFileUploadedCallback: (file: any) => Promise<any>;
  setIsUploadingDocument: (isUploading: boolean) => void;
  result: any;
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isCheckRunning, setIsCheckRunning] = useState(false);
  const [isUploadingStatus, setIsUploadingStatus] = useState(false);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const parchaApi = useParchaApi();
  const inputRef = useRef<HTMLInputElement>(null);

  const [progress, setProgress] = useState(0);

  // const commandResultMessage = statusMessages && statusMessages.find((msg) => msg.event === "command_results");
  const didPass = ["true", "pass", "passed", "yes"].includes(result?.passed?.toString().toLowerCase());

  useEffect(() => {
    if (!isUploadingStatus && !isCheckRunning) return;

    const p = new FakeProgress({
      timeConstant: 45000,
      autoStart: true,
    });

    const interval = setInterval(() => {
      setProgress(p.progress);
    }, 500);

    return () => clearInterval(interval);
  }, [isCheckRunning, isUploadingStatus]);

  useEffect(() => {
    if (result && result.answer && result.passed !== null && (result.follow_up || result.passed)) {
      setIsCheckRunning(false);
    } else if (result) {
      setIsCheckRunning(true);
    } else {
      setIsCheckRunning(false);
    }
  }, [result]);

  const handleDocumentInputClick = () => {
    inputRef.current?.click();
  };
  const uploadFiles = async (files) => {
    if (!files) return;

    setIsUploadingDocument(true);
    setIsUploadingStatus(true);

    const filePromises = Array.from(files).map((file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (loadEvent: Event) => {
          const base64String = loadEvent?.target?.result?.split(",")[1];
          parchaApi.uploadBase64Document(endpoint.endpointUrl, base64String, file.name).then((result) => {
            const newFile = { ...result, file_name: file.name, source_type: "file_url" };
            delete newFile.expiration;
            resolve(newFile);
          });
        };
        reader.readAsDataURL(file);
      });
    });
    const allFilesUploaded = await Promise.all(filePromises);

    // For now, it is only one file being uploaded
    allFilesUploaded.forEach(async (file) => {
      afterFileUploadedCallback(file).then(() => {
        setSelectedFile(file);
        setIsCheckRunning(true);
        setIsUploadingStatus(false);
        setIsUploadingDocument(false);
      });
    });
  };

  const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (!files || !files.length) return;

    setSelectedFile(null);
    return uploadFiles(files);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDraggingOver(false);
    const files = e.dataTransfer.files;
    if (!files) return;

    setSelectedFile(null);

    return uploadFiles(files);
  };

  const statusEvents = result?.status_messages.filter((message) => message.event === "status");
  const lastStatusMessage = statusEvents && statusEvents[statusEvents.length - 1];

  return (
    <div className="flex flex-col gap-2">
      {selectedFile && isCheckRunning ? (
        <>
          <div className="flex items-center gap-4">
            <div className="flex flex-col p-5 border border-slate-300 rounded-lg grow">
              <div className="flex items-center justify-between">
                <DocumentValue fileName={selectedFile.file_name} url={selectedFile.url} maxWidth={1000} />

                {(isUploadingStatus || isCheckRunning) && (
                  <Progress className="w-[5rem] h-[0.5rem]" value={progress * 100} />
                )}
                {!isCheckRunning ? (
                  didPass ? (
                    <CheckCircleRounded sx={{ fontSize: "1.25rem" }} className="text-green-600" />
                  ) : (
                    <CancelRounded sx={{ fontSize: "1.25rem" }} className="text-red-500" />
                  )
                ) : null}
              </div>
              {isCheckRunning && lastStatusMessage && lastStatusMessage.content && (
                <CheckStatusMessage
                  className="px-0 mx-0 mt-2"
                  messageContents={{ status: lastStatusMessage.content.status }}
                />
              )}
              {!isUploadingStatus && !isCheckRunning && result && result?.passed !== null ? (
                <Alert
                  level={!didPass ? "failure" : "success"}
                  message={
                    <div className="flex flex-col gap-y-2">
                      <span>{result.answer}</span>
                      <span className="font-bold">{result.follow_up}</span>
                    </div>
                  }
                />
              ) : null}
            </div>
            {/* <button
              onClick={() => {
                setSelectedFile(null);
                afterFileDeletedCallback(selectedFile);
              }}
            >
              <DeleteForeverRounded sx={{ fontSize: "1rem" }} className="text-red-600" />
            </button> */}
          </div>
        </>
      ) : null}
      <div
        className={twJoin(
          "flex flex-col items-center gap-y-1 justify-center h-full text-xs border-2 border-dashed py-6 px-3 rounded-lg",
          isDraggingOver ? "border-blue-500 bg-blue-50" : "border-slate-300",
        )}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onDragExit={() => setIsDraggingOver(false)}
        onDragLeave={() => setIsDraggingOver(false)}
      >
        <UploadFileRounded sx={{ fontSize: "1.25rem" }} className="text-slate-400" />
        <p className="text-slate-700">
          Drag & Drop or{" "}
          <span className="underline text-brand-purple" role="button" onClick={handleDocumentInputClick}>
            Choose a document
          </span>{" "}
          to upload
        </p>
        <input
          type="file"
          name={fieldKey}
          accept="application/pdf"
          onChange={handleFileSelect}
          onBlur={(e) => e.preventDefault()}
          ref={inputRef}
          className="hidden"
        />
      </div>
    </div>
  );
};

const DocumentField = ({
  iconKey,
  title,
  description,
  fieldKey,
  endpoint,
  setIsUploadingDocument,
  afterFileUploadedCallback,
  afterFileDeletedCallback,
  result,
  pastResults,
}) => {
  const currentResultDocument =
    result?.command_id === "kyb.proof_of_address_verification"
      ? result?.verification_data?.proof_of_address_documents[0]?.document
      : result?.command_id === "kyb.incorporation_document_verification"
        ? result?.passed
          ? result?.verification_data?.documented_incorporation_information[0]?.document
          : result?.verification_data?.invalid_documents[0]?.document
        : result?.command_id === "kyb.business_ownership_verification_tool"
          ? result?.verification_data?.documented_ownership[0]?.document
          : null;

  const didCurrentResultPass = ["true", "pass", "passed", "yes"].includes(result?.passed?.toString().toLowerCase());

  return (
    <div className="flex flex-col gap-2.5">
      <DocumentFieldLabel iconKey={iconKey} title={title} description={description} />
      {pastResults && pastResults.length > 0 && (
        <div className="flex flex-col gap-y-2">
          {pastResults.map((result) => {
            if (!result.answer) return null;

            const didPass = ["true", "pass", "passed", "yes"].includes(result?.passed?.toString().toLowerCase());

            const document = result?.passed
              ? result?.verification_data?.documented_incorporation_information[0]?.document
              : result?.verification_data?.invalid_documents[0]?.document;

            return (
              <div className="border border-slate-300 rounded-lg p-5 flex flex-col gap-y-3" key={result.id}>
                <div className="flex justify-between">
                  <DocumentValue fileName={document?.file_name ?? ""} url={document?.url} maxWidth={1000} />
                  {result.passed ? (
                    <CheckCircleRounded sx={{ fontSize: "1.25rem" }} className="text-green-600" />
                  ) : (
                    <CancelRounded sx={{ fontSize: "1.25rem" }} className="text-red-500" />
                  )}
                </div>
                {result.passed !== null && (
                  <Alert
                    level={didPass ? "success" : "failure"}
                    message={
                      <div className="flex flex-col gap-y-2">
                        <span>{result.answer}</span>
                        <span className="font-bold">{result.follow_up}</span>
                      </div>
                    }
                  />
                )}
              </div>
            );
          })}
        </div>
      )}
      {result?.answer && (
        <div className="border border-slate-300 rounded-lg p-5 flex flex-col gap-y-3">
          {currentResultDocument && (
            <div className="flex justify-between">
              <DocumentValue
                fileName={currentResultDocument?.file_name}
                url={currentResultDocument?.url}
                maxWidth={1000}
              />
              {result.passed ? (
                <CheckCircleRounded sx={{ fontSize: "1.25rem" }} className="text-green-600" />
              ) : (
                <CancelRounded sx={{ fontSize: "1.25rem" }} className="text-red-500" />
              )}
            </div>
          )}

          {result.passed !== "null" && (
            <Alert
              level={didCurrentResultPass ? "success" : "failure"}
              message={
                <div className="flex flex-col gap-y-2">
                  <span>{result.answer}</span>
                  <span className="font-bold">{result.follow_up}</span>
                </div>
              }
            />
          )}
        </div>
      )}
      <DocumentUploader
        endpoint={endpoint}
        setIsUploadingDocument={setIsUploadingDocument}
        afterFileUploadedCallback={afterFileUploadedCallback}
        afterFileDeletedCallback={afterFileDeletedCallback}
        result={result}
      />
    </div>
  );
};

export default IncorporationDocRemediationPage;
