import React, { useEffect, useState, useRef } from "react";

const WebsiteView = ({ urls }: { urls: string[] }) => {
  if (!urls.length) return null;

  return (
    <ul className="w-full text-sm text-brand-purple px-8 py-2.5 list-disc list-brand-purple">
      {urls?.map((url) => {
        return (
          <li key={url}>
            <a
              href={url.startsWith("http") ? url : `http://${url}`}
              target="_blank"
              rel="noreferrer"
              className="hover:underline"
            >
              <TruncatedURL text={url} maxWidth={300} />
            </a>
          </li>
        );
      })}
    </ul>
  );
};

export const TruncatedURL = ({ text, maxWidth = 200 }: { text: string; maxWidth: number }) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const textRef = useRef<HTMLDivElement | null>(null);

  const checkTruncation = () => {
    if (textRef.current) {
      setIsTruncated(textRef?.current?.scrollWidth > maxWidth);
    }
  };

  useEffect(() => {
    checkTruncation(); // Initial check
    window.addEventListener("resize", checkTruncation); // Add resize listener

    return () => {
      window.removeEventListener("resize", checkTruncation); // Clean up listener
    };
  }, [text, maxWidth]);

  const start = text.substring(0, (2 * maxWidth) / 16);

  const end = text.substring(text.length - (2 * maxWidth) / 16);

  return (
    <div className={`flex items-center max-w-[${maxWidth}px] overflow-hidden whitespace-nowrap`} ref={textRef}>
      {isTruncated ? (
        <>
          <span className="whitespace-nowrap line-clamp-1">{start}</span>
          <span className="flex-shrink-0">...</span>
          {end !== start ? <span className="whitespace-nowrap line-clamp-1">{end}</span> : null}
        </>
      ) : (
        <span>{text}</span>
      )}
    </div>
  );
};

export default WebsiteView;
