const BusinessHeader = ({ name, website }: { name: string | string[] | null; website: string | null }) => {
  const websiteValue = Array.isArray(website) ? (website.length > 0 ? website[0] : null) : website;

  return (
    <div className="flex flex-col gap-y-2 min-w-full w-0 overflow-hidden">
      {name && <span className="text-xl font-semibold leading-tight">{name}</span>}
      {websiteValue && (
        <a
          href={websiteValue.startsWith("http") ? websiteValue : `https://${websiteValue}`}
          target="_blank"
          rel="noreferrer"
          className="text-xs text-brand-purple truncate hover:underline"
        >
          {website}
        </a>
      )}
    </div>
  );
};

export default BusinessHeader;
