// @ts-nocheck
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useParchaApi } from "@parcha/hooks/useParchaApi";
import { Popover, PopoverButton, PopoverGroup, PopoverPanel } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import _ from "lodash";
import { twJoin } from "tailwind-merge";
import {
  ErrorRounded,
  RefreshRounded,
  CloseRounded,
  EmailRounded,
  HourglassTopRounded,
  FilterAltRounded,
  ViewAgendaOutlined,
  ViewHeadlineOutlined,
} from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircleRounded";
import WarningIcon from "@mui/icons-material/WarningRounded";
import SmartTabs from "./TailwindComponents/SmartTabs";
import CheckContainer from "./V2_Home_Page/CheckContainer";
import Alert from "./TailwindComponents/Alert";

const passingValues = ["pass", "approve"];

const Embbed = () => {
  const parchaApi = useParchaApi();
  const [searchParams] = useSearchParams();
  const caseId = searchParams.get("case_id");
  const endpointUrl = searchParams.get("endpoint_url");
  const apiKey = searchParams.get("api_key");
  const agentKey = searchParams.get("agent_key");
  const [error, setError] = useState<string | null>(null);
  const [checks, setChecks] = useState<string[] | null>(null);
  const [displayedCheck, setDisplayedCheck] = useState<any | null>(null);
  const [showTabular, setShowTabular] = useState<boolean>(true);
  const [availableLocations, setAvailableLocations] = useState([]);
  const [availableGenders, setAvailableGenders] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedGenders, setSelectedGenders] = useState([]);
  const [jobMetadata, setJobMetadata] = useState<any | null>(null);

  const tabOrder = ["kyc.adverse_media_screening_check_v2", "kyc.pep_screening_check_v2"];

  const onContactSupportClickHandler = () => {
    let mailtoLink;
    if (error) {
      const subject = `Error report in ${agentKey}`;
      const body = `The following error occured while loading the case data.\n\n${error}`;
      mailtoLink = `mailto:support@parcha.ai?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
        body || "",
      )}`;
    } else {
      const errorMessage = displayedCheck?.status_messages?.find((message) => message.event === "error")?.content
        .error_display;
      const subject = `Error report in ${agentKey} for job ${jobMetadata?.job?.id}`;
      const body = `There was an error job with ID ${jobMetadata?.job?.id}.\n\n${errorMessage ? errorMessage : ""}`;
      mailtoLink = `mailto:support@parcha.ai?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
        body || "",
      )}`;
    }
    window.open(mailtoLink, "_blank");
  };

  const intervalRef = useRef(null);

  useEffect(() => {
    let intervalId;

    const fetchJobData = () => {
      parchaApi.getJobMetadataByCaseId(endpointUrl, caseId, agentKey, apiKey).then((data) => {
        setJobMetadata(data);
        const checks = data.kyc
          ? Object.values(data.kyc)[0]?.check_results?.filter((check: any) => check.command_id !== "finish_plan")
          : [];
        setChecks(checks);
      });
    };

    if (caseId && endpointUrl && apiKey) {
      fetchJobData();
      intervalRef.current = setInterval(fetchJobData, 5000);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [caseId, endpointUrl, apiKey]);

  useEffect(() => {
    if (checks && checks.length > 0 && !displayedCheck) {
      setDisplayedCheck(checks.find((check: { command_id: string }) => check.command_id === tabOrder[0]) || checks[0]);
    } else if (checks && checks.length > 0 && displayedCheck !== null) {
      setDisplayedCheck(
        checks?.find(
          (check: { command_instance_id: string }) => check.command_instance_id === displayedCheck?.command_instance_id,
        ),
      );
    }
  }, [checks]);

  useEffect(() => {
    if (jobMetadata?.job?.status === "complete" || jobMetadata?.job?.status === "error") {
      clearInterval(intervalRef.current);
    }
  }, [jobMetadata?.job?.status]);

  const tabs =
    checks && checks.length > 0
      ? [
          ...tabOrder
            .map((commandId) => {
              const check = checks.find((c) => c.command_id === commandId);
              if (!check) return null;
              return {
                id: check?.command_id,
                label: check?.command_name.replace(" Check", "") || check?.command_id,
                icon:
                  check.recommendation === "Approve" ? (
                    <CheckCircleIcon fontSize="small" className="text-green-600" />
                  ) : check.recommendation === "Review" || check.recommendation === "Deny" ? (
                    <WarningIcon fontSize="small" className="text-yellow-600" />
                  ) : null,
              };
            })
            .filter(Boolean),
          ...checks
            .filter((check) => !tabOrder.includes(check.command_id))
            .map((check) => ({
              id: check.command_id,
              label: check.command_name.replace(" Check", "") || check.command_id,
              icon:
                check.recommendation === "Approve" ? (
                  <CheckCircleIcon fontSize="small" className="text-green-600" />
                ) : check.recommendation === "Review" || check.recommendation === "Deny" ? (
                  <WarningIcon fontSize="small" className="text-yellow-600" />
                ) : null,
            })),
        ]
      : [];

  const onTabSelectHandler = (tabId) => {
    setDisplayedCheck(checks?.find((check) => check?.command_id === tabId));
  };

  useEffect(() => {
    if (!displayedCheck) return;

    const adverseMediaCommandResult = displayedCheck.status_messages.find(
      (message) =>
        message.event === "command_results" && message.content.command_id === "kyc.adverse_media_screening_check_v2",
    );
    const pepCommandResult = displayedCheck.status_messages.find(
      (message) => message.event === "command_results" && message.content.command_id === "kyc.pep_screening_check_v2",
    );

    const adverseMediaLocations =
      _.flatten(
        adverseMediaCommandResult?.content?.output?.payload.perpetrator_profiles?.map((profile) =>
          profile.associated_countries.map((country) => country.country_name),
        ) ||
          adverseMediaCommandResult?.content?.output?.payload?.verified_adverse_media_hits?.map((hit) =>
            hit.associated_countries.map((country) => country.country_name),
          ) ||
          [],
      ) || [];

    const pepLocations =
      _.flatten(
        pepCommandResult?.content?.output?.payload?.verified_pep_hits?.map((pepProfile) => {
          if (pepProfile.associated_countries) {
            return pepProfile.associated_countries.map((country) => country.country_name);
          } else if (pepProfile.pep_profile.associated_countries) {
            return pepProfile.pep_profile.associated_countries.map((country) => country.country_name);
          }
        }),
      ) || [];

    const genders =
      Array.from(
        new Set(
          pepCommandResult?.content?.output?.payload?.verified_pep_hits?.map(
            (pepProfile) => pepProfile.gender || pepProfile.pep_profile.gender,
          ),
        ),
      ) || [];

    const locations = Array.from(new Set([...adverseMediaLocations, ...pepLocations]));

    setAvailableGenders(genders);
    setAvailableLocations(locations);
  }, [displayedCheck]);

  return (
    <div className="flex flex-col gap-y-4 h-full overflow-auto">
      {jobMetadata?.job?.status === "queued" ? (
        <div className="flex flex-col justify-center items-center w-full h-full text-brand-purple text-sm gap-y-2">
          <HourglassTopRounded sx={{ fontSize: "2rem" }} className="text-slate-500" />
          <div className="text-center">
            <p className="font-bold">This job is queued up</p>
            <p>It will start executing once the current in progress jobs are completed.</p>
          </div>
        </div>
      ) : jobMetadata?.job?.status === "error" || error ? (
        <div className="flex items-center h-full mx-auto w-3/4">
          <div className="flex flex-col justify-center items-center w-full py-10 px-5 lg:p-10 text-sm gap-y-2 bg-slate-50 rounded-[1.25rem]">
            <ErrorRounded sx={{ fontSize: "2rem" }} className="text-red-600" />
            <div className="text-center">
              <p className="text-xs lg:text-sm font-semibold lg:font-bold text-slate-700">An error has occurred</p>
              {error && <p className="text-slate-500 text-xs">There was an issue loading the case data.</p>}
              {jobMetadata?.job && (
                <>
                  <p className="text-slate-500 text-xs">There was an issue while loading information for</p>
                  <p className="text-slate-500 font-bold text-xs">
                    {jobMetadata?.job?.input_payload?.self_attested_data?.name ||
                      jobMetadata?.job?.input_payload?.self_attested_data?.business_name ||
                      jobMetadata?.job?.input_payload?.self_attested_data?.registered_business_name ||
                      `${jobMetadata?.job?.input_payload?.self_attested_data?.first_name || ""}${
                        jobMetadata?.job?.input_payload?.self_attested_data?.middle_name
                          ? ` ${jobMetadata?.job?.input_payload.self_attested_data.middle_name}`
                          : ""
                      }${
                        jobMetadata?.job?.input_payload?.self_attested_data?.last_name
                          ? ` ${jobMetadata?.job?.input_payload.self_attested_data.last_name}`
                          : ""
                      }`}
                  </p>
                </>
              )}
              <button
                type="button"
                className="mt-2 text-sm relative inline-flex justify-center items-center rounded-md bg-white border border-solid border-slate-300 p-2 h-4 text-slate-900 focus:z-10 "
                onClick={() => window.location.reload()}
              >
                <RefreshRounded sx={{ fontSize: "1rem" }} className="mr-1 h-4 w-4" aria-hidden="true" />
                <span className="line-clamp-1 text-xs">Refresh This Page</span>
              </button>
              <p className="mt-4 text-xs text-slate-500">
                If the problem persists please contact support and we will look into the issue as soon as possible.
              </p>
              <button
                type="button"
                className="mt-2 text-sm relative inline-flex justify-center items-center rounded-md bg-white border border-solid border-slate-300 p-2 h-4 text-slate-900 focus:z-10 "
                onClick={onContactSupportClickHandler}
              >
                <EmailRounded sx={{ fontSize: "1rem" }} className="mr-1 h-4 w-4" aria-hidden="true" />
                <span className="line-clamp-1 text-xs">Contact Support</span>
              </button>
            </div>
          </div>
        </div>
      ) : null}

      <div className="flex flex-col gap-y-2">
        {tabs && tabs.length > 0 && (
          <SmartTabs tabs={tabs} selectedTab={displayedCheck?.command_id} onSelect={onTabSelectHandler} />
        )}

        {displayedCheck && displayedCheck.recommendation && (
          <Alert
            level={
              displayedCheck.recommendation === "Approve"
                ? "success"
                : displayedCheck.recommendation === "Review"
                  ? "partial"
                  : displayedCheck.recommendation === "Deny"
                    ? "failure"
                    : "normal"
            }
            message={
              <div className="flex flex-col gap-y-2">
                <div className="flex items-center gap-x-2">
                  {displayedCheck.recommendation && displayedCheck.answer && (
                    <span>
                      <span className="capitalize font-semibold">
                        {displayedCheck.recommendation === "Approve"
                          ? "Low Risk"
                          : displayedCheck.recommendation === "Review"
                            ? "Medium Risk"
                            : displayedCheck.recommendation === "Deny"
                              ? "High Risk"
                              : displayedCheck.recommendation}
                        :
                      </span>{" "}
                      {displayedCheck.answer}
                    </span>
                  )}
                </div>
              </div>
            }
          />
        )}

        {displayedCheck && (
          <div key={displayedCheck.commandInstanceId} className="flex flex-col gap-y-5">
            {["kyc.adverse_media_screening_check_v2", "kyc.pep_screening_check_v2"].includes(
              displayedCheck.command_id,
            ) && (
              <>
                <div className="px-5 text-xs flex justify-end items-center gap-x-1">
                  <span className="w-[5rem] font-semibold">View Format:</span>
                  <span className="isolate inline-flex rounded-md shadow-sm">
                    <button
                      onClick={() => setShowTabular(false)}
                      type="button"
                      className={twJoin(
                        "relative inline-flex items-center rounded-l-md p-1 ring-1 ring-inset ring-slate-300 focus:z-10",
                        !showTabular ? "text-white bg-slate-900" : "text-slate-900 bg-white",
                      )}
                    >
                      <span className="sr-only">Card View</span>
                      <ViewAgendaOutlined sx={{ fontSize: "1rem" }} aria-hidden="true" className="h-4 w-4" />
                    </button>
                    <button
                      onClick={() => setShowTabular(true)}
                      type="button"
                      className={twJoin(
                        "relative -ml-px inline-flex items-center rounded-r-md p-1 ring-1 ring-inset ring-slate-300 focus:z-10",
                        showTabular ? "text-white bg-slate-900" : "text-slate-900 bg-white",
                      )}
                    >
                      <span className="sr-only">Tabular View</span>
                      <ViewHeadlineOutlined sx={{ fontSize: "1rem" }} aria-hidden="true" />
                    </button>
                  </span>
                </div>
                {(availableGenders.length > 0 || availableLocations.length > 0) && (
                  <div className="flex flex-col gap-y-2 px-8 text-xs">
                    <div className="flex items-center gap-x-4 gap-y-1 flex-wrap">
                      <div className="flex items-center gap-x-2">
                        <FilterAltRounded sx={{ fontSize: "1rem" }} className="text-slate-500" />
                        <span>Filters:</span>
                      </div>
                      <PopoverGroup className="flex items-center gap-x-4 divide-x divide-slate-200">
                        {availableGenders.length > 0 && (
                          <Popover className="relative inline-block text-left">
                            <PopoverButton className="px-2 py-1 border border-solid border-slate-200 group inline-flex items-center justify-center font-medium text-slate-700">
                              <span>Gender</span>
                              {selectedGenders.length > 0 && (
                                <span className="ml-1.5 rounded bg-slate-50 px-1.5 py-0.5 font-semibold tabular-nums text-slate-700">
                                  {selectedGenders.length}
                                </span>
                              )}
                              <ChevronDownIcon
                                aria-hidden="true"
                                className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-slate-400 group-hover:text-slate-500"
                              />
                            </PopoverButton>

                            <PopoverPanel
                              transition
                              className="absolute left-0 z-40 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                            >
                              <div className="space-y-4">
                                {availableGenders.map((gender, optionIdx) => (
                                  <div key={gender} className="flex items-center">
                                    <input
                                      onChangeCapture={(e) => {
                                        if (e.target.checked) {
                                          setSelectedGenders([...selectedGenders, e.target.value]);
                                        } else {
                                          setSelectedGenders(
                                            selectedGenders.filter((gender) => gender !== e.target.value),
                                          );
                                        }
                                      }}
                                      id={`filter-${gender}-${optionIdx}`}
                                      checked={selectedGenders.includes(gender)}
                                      name={gender}
                                      value={gender}
                                      type="checkbox"
                                      className="h-4 w-4 rounded border border-solid border-slate-300 checked:text-brand-purple checked:border-none focus:ring-brand-purple"
                                    />
                                    <label
                                      htmlFor={`filter-gender-${optionIdx}`}
                                      className="ml-3 whitespace-nowrap pr-6 text-slate-900"
                                    >
                                      {gender}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </PopoverPanel>
                          </Popover>
                        )}
                        {availableLocations.length > 0 && (
                          <Popover className="relative inline-block text-left">
                            <PopoverButton className="px-2 py-1 border border-solid border-slate-200 group inline-flex items-center justify-center font-medium text-slate-700">
                              <span>Locations</span>
                              {selectedLocations.length > 0 && (
                                <span className="ml-1.5 rounded bg-slate-50 px-1.5 py-0.5 font-semibold tabular-nums text-slate-700">
                                  {selectedLocations.length}
                                </span>
                              )}
                              <ChevronDownIcon
                                aria-hidden="true"
                                className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-slate-400 group-hover:text-slate-500"
                              />
                            </PopoverButton>

                            <PopoverPanel
                              transition
                              className="absolute left-0 z-40 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                            >
                              <div className="space-y-4">
                                {availableLocations.map((location, optionIdx) => (
                                  <div key={location} className="flex items-center">
                                    <input
                                      id={`filter-${location}-${optionIdx}`}
                                      onChangeCapture={(e) => {
                                        if (e.target.checked) {
                                          setSelectedLocations(
                                            Array.from(new Set([...selectedLocations, e.target.value])),
                                          );
                                        } else {
                                          setSelectedLocations(
                                            selectedLocations.filter((location) => location !== e.target.value),
                                          );
                                        }
                                      }}
                                      checked={selectedLocations.includes(location)}
                                      name={location}
                                      value={location}
                                      type="checkbox"
                                      className="h-4 w-4 rounded border border-solid border-slate-300 checked:text-brand-purple checked:border-none focus:ring-brand-purple"
                                    />
                                    <label
                                      htmlFor={`filter-location-${optionIdx}`}
                                      className="ml-3 whitespace-nowrap pr-6 text-slate-900"
                                    >
                                      {location}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </PopoverPanel>
                          </Popover>
                        )}
                      </PopoverGroup>
                      {(selectedGenders.length > 0 || selectedLocations.length > 0) && (
                        <button
                          onClick={() => {
                            setSelectedGenders([]);
                            setSelectedLocations([]);
                          }}
                          className="text-brand-purple hover:underline"
                        >
                          Clear All Filters
                        </button>
                      )}
                    </div>
                    {(selectedGenders.length > 0 || selectedLocations.length > 0) && (
                      <div className="flex items-center gap-x-2 gap-y-1 flex-wrap">
                        {selectedGenders.map((gender) => {
                          return (
                            <span className="inline-flex items-center gap-x-1.5 px-2 py-0.5 rounded-full bg-slate-50">
                              {gender}
                              <button
                                onClick={() =>
                                  setSelectedGenders(
                                    selectedGenders.filter((selectedGender) => selectedGender !== gender),
                                  )
                                }
                              >
                                <CloseRounded sx={{ fontSize: "1rem" }} />
                              </button>
                            </span>
                          );
                        })}
                        {selectedLocations.map((location) => {
                          return (
                            <span className="inline-flex items-center gap-x-1.5 px-2 py-0.5 rounded-full bg-slate-50">
                              {location}
                              <button
                                onClick={() =>
                                  setSelectedLocations(
                                    selectedLocations.filter((selectedLocation) => selectedLocation !== location),
                                  )
                                }
                              >
                                <CloseRounded sx={{ fontSize: "1rem" }} />
                              </button>
                            </span>
                          );
                        })}
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
            <CheckContainer
              title={displayedCheck.command_name}
              agentRun={jobMetadata?.job}
              apiKey={apiKey}
              endpointUrl={endpointUrl}
              commandResult={displayedCheck}
              key={displayedCheck.commandInstanceId}
              messages={displayedCheck.status_messages || []}
              auditLogMessages={
                displayedCheck.status_messages?.filter((message: any) =>
                  ["api_results", "command_results", "thought"].includes(message.event),
                ) || []
              }
              compact={true}
              showTabular={showTabular}
              selectedGenders={selectedGenders}
              selectedLocations={selectedLocations}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Embbed;
