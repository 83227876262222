import { ReactNode } from "react";

const DetailValue = ({ value, icon }: { value: ReactNode; icon: ReactNode }) => {
  return (
    <div className="flex gap-x-2">
      <div className="text-slate-500">{icon}</div>
      <div className={`${value === "Unknown" ? "text-slate-400" : "text-slate-700"}`}>{value}</div>
    </div>
  );
};

export default DetailValue;
